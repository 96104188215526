/* header menu */

.header-vehicles-item .content h4 {
    text-transform: none;
}

/* double name model auto to tablet and mobile */

.main-menu-container .main-menu-level2-mobile .header .title {
    font-size: 14px;
		padding-top: 18px;
}

.main-menu-container .main-menu-level2-mobile .header .subtitle {
    display: none;
}

/* footer menu desktop */

@media only screen and (min-width: 740px) {
.footer-col-title {
    min-height: 28px;
    height: auto;
}
}
