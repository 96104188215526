.used-card {
	font-family: "robotoregular", sans-serif;
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title {
		font-size: 34px;
		font-weight: 600;
		text-align: center;
		margin-top: 54px;
		margin-bottom: 49px;
		line-height: 1.3;
		@media (max-width: 767px) {
			margin-top: 38px;
			margin-bottom: 35px;
			text-align: left;
		}

	}
	.instock_car_actions_block {
		.lear_more_btn {
			cursor: pointer;
			border-radius: 2px;
			font-size: 14px;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			padding: 12px 0;
			min-width: 360px;
			min-height: 34px;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			margin-bottom: 50px;
			@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
			&:hover {
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #edad01;
			}
			@media (max-width: 767px) {
				margin-bottom: 30px;
				width: 100%;
				min-width: 100%;
				font-size: 12px;
			}
			i {
				margin-left: 7px;
				@media (max-width: 767px) {
					margin-left: 3px;
				}
			}
		}
	}
	.information-block {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		@media (max-width: 1023px) {
			margin-bottom: 35px;
		}
		@media (max-width: 767px) {
			display: block;
			margin-bottom: 5px;
		}
		.gallery-block {
			width: 73%;
			height: 730px;
			margin-right: 40px;
			@media (max-width: 1439px) {
				width: 75%;
			}
			@media (max-width: 1279px){
				height: 610px;
			}
			@media (max-width: 1023px) {
				width: 67%;
				height: auto;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
			.real-photos {
				width: 870px;
				height: 550px;
				@media (max-width: 1439px) {
					width: 870px;
					height: 550px;
				}
				@media (max-width: 1279px){
					width: 100%;
					height: 480px;
				}
				@media (max-width: 1023px) {
					width: 100%;
					height: 317px;
				}
				@media (max-width: 767px) {
					height: 193px;
				}
			}
			.standart-image {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				.photo {
					@media (max-width: 1023px) {
						width: 100%;
					}
				}
			}
			.fotorama:not(.fotorama--fullscreen) {
				.fotorama__nav--thumbs {
					max-width: 100%;
					margin-top: 0;
					display: block !important;
					@media (max-width: 1023px) {
						margin-top: -5px;
						display: block !important;
					}
					@media (max-width: 767px) {
						display: none !important;
					}
				}
			}
			.fotorama__nav:before,
			.fotorama__stage:before,
			.fotorama__nav:after,
			.fotorama__stage:after {
				background: none !important;
			}
			.fotorama {
				.fotorama__wrap--slide .fotorama__stage__frame {
					width: 870px;
					height: 580px;
					@media (max-width: 1279px){
						width: 100%;
						height: 480px;
					}
					@media (max-width: 1023px) {
						height: 317px;
					}
					@media (max-width: 767px) {
						height: 200px;
					}
				}
				.fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__stage .fotorama__img {
					width: 100% !important;
					height: 100% !important;
					object-fit: cover;
				}
				.fotorama__nav--thumbs .fotorama__nav__frame {
					width: 206px !important;
					height: 135px !important;
					@media (max-width: 1279px){
						width: 168px !important;
						height: 112px !important;
					}
					@media (max-width: 1023px) {
						width: 110px !important;
						height: 75px !important;
						margin-right: -4px;
					}
					&.fotorama__active {
						border: 1px solid #000;
						padding: 0;
						margin-right: 15px !important;
					}
				}
				.fotorama__error .fotorama__img, .fotorama__loaded .fotorama__img {
					width: 100% !important;
					height: 100% !important;
					left: 0 !important;
					top: 0 !important;
					object-fit: cover;
				}
				.fotorama__thumb-border {
					display: none;
				}
				.fotorama__fullscreen-icon {
					background-size: 18px;
					top: 0;
					right: 0;
					background: #FEB733 url('/files/images/007-zoom.png') no-repeat center !important;
				}
				.fotorama__arr {
					background-color: #FEB733;
					background-position: center;
					position: absolute;
					width: 30px;
					height: 45px;
					top: 52%;
				}
				.fotorama__arr--prev {
					left: 0;
					&::before {
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #000;
						padding-left: 5px;
						font-size: 24px;
						position: relative;
						display: block;
						top: 25%;
						@include transformCss(scaleX(-1));
					}
				}
				.fotorama__arr--next {
					right: 0;
					&::after {
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #000;
						padding-left: 5px;
						font-size: 24px;
						position: relative;
						top: 25%;
					}
				}
			}
		}
		.info-block {
			width: 30%;
			@media (max-width: 767px) {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
			.instock-price {
				font-size: 34px;
				margin-bottom: 31px;
				@media (max-width: 1023px){
					margin-bottom: 17px;
				}
				@media (max-width: 767px) {
					margin-bottom: 27px;
					font-size: 29px;
				}
				.total-price {
					font-weight: 600;
					@media (max-width: 767px) {
						margin-top: 24px;
					}
				}
				.old-price {
					width: fit-content;
					position: relative;
					color: #868686;
					margin-top: -6px;
					&::after {
						content: "";
						width: 100%;
						height: 1px;
						background-color: #868686;
						position: absolute;
						left: 0;
						top: 50%;
						@include transformCss(translate(0, -50%));
					}
				}
			}
			.cars-prepay {
				margin-bottom: 40px;
				@media (max-width: 1023px) {
					margin-bottom: 25px;
				}
				@media (max-width: 767px) {
					margin-bottom: -10px;
				}
				.link {
					cursor: pointer;
					border-radius: 2px;
					font-size: 14px;
					display: inline-block;
					text-transform: uppercase;
					font-weight: 600;
					padding: 12px 0;
					min-width: 194px;
					min-height: 34px;
					width: 100%;
					text-align: center;
					color: #000;
					background-color: #ffba00;
					margin-bottom: 40px;
					@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
					&:hover {
						@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
						background-color: #edad01;
					}
					@media (max-width: 1023px) {
						margin-bottom: 25px;
					}
					@media (max-width: 767px) {
						width: 100%;
					}
					i {
						margin-left: 5px;
					}
				}
				.description {
					font-size: 17px;
					line-height: 1.4;
					@media (max-width: 767px) {
						font-size: 16px;
					}
				}
			}
			.order-wrapper {
				font-size: 18px;
				font-weight: 600;
				margin: 25px 0;
				padding: 15px 0;
				border-top: 1px solid #cccccc;
				border-bottom: 1px solid #cccccc;
				@media (max-width: 767px) {
					display: block;
					font-size: 20px;
					margin-bottom: 19px;
				}
				.order-number {
					font-weight: normal;
					@media (max-width: 767px) {
						display: flex;
					}
					.value {
						font-weight: 600;
					}
				}
			}
			.characteristic {
				font-size: 17px;
				margin-bottom: 29px;
				@media (max-width: 1439px) {
					margin-bottom: 0;
				}
				@media (max-width: 767px) {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 23px;
					.volume, .engine {
						display: flex;
					}
				}
				.separator {
					display: none;
					@media (max-width: 767px) {
						display: block;
					}
				}
			}
			.button-wrapper {
				@media (max-width: 1439px) {
					&.xl-visible {
						display: none;
						@media (max-width: 767px) {
							display: block;
						}
					}
				}
				.link {
					&:last-child {
						margin-bottom: 0;
						@media (max-width: 767px) {
							margin-bottom: 0;
						}
					}
					margin-bottom: 19px;
					@media (max-width: 767px) {
						margin-bottom: 26px;
					}
					.item {
						font-size: 15px;
						display: block;
						padding: 6.5px 15px;
						min-width: 194px;
						width: fit-content;
						text-align: center;
						color: #000;
						background-color: #EDAD01;
						@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
						&:hover {
							@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
							background-color: #FEB733;
						}
						@media (max-width: 767px) {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.characteristic-inside {
		margin-bottom: 52px;
		&.-md-visible {
			display: none;
			@media (max-width: 1023px) {
				display: block;
			}
			@media (max-width: 767px) {
				display: none;
			}
		}
		.order-wrapper {
			.order-number {
				font-size: 19px;
				font-weight: 600;
				margin-bottom: 5px;
			}
		}
		.characteristic {
			font-size: 18px;
		}
	}

	.instock-features {
		.title {
			font-size: 25px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 36px;
			@media (max-width: 767px) {
				font-size: 30px;
				margin-bottom: 14px;
			}
		}
		.features-list {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ccc;
			margin-bottom: 44px;
			@media (max-width: 1023px) {
				margin-bottom: 90px;
			}
			@media (max-width: 767px) {
				display: block;
				border: none;
				margin-bottom: 45px;
			}
			.custom-col {
				width: 45%;
				display: inline-block;
				@media (max-width: 767px) {
					width: 100%;
				}
				.list {
					.item {
						border-bottom: 1px solid #ccc;
						padding-top: 18px;
						padding-bottom: 18px;
						&:last-child {
							border: none;
						}
						@media (max-width: 767px) {
							border: none;
							padding-bottom: 6px;
						}
						.features-label {
							font-size: 18px;
							font-weight: 600;
							margin-bottom: 2px;
							@media (max-width: 767px) {
								font-size: 21px;
								border-bottom: 1px solid #ccc;
								padding-bottom: 4px;
								margin-bottom: 8px;
							}
						}
						.value {
							font-size: 16px;
							@media (max-width: 767px) {
								font-size: 19px;
							}
						}
					}
				}
			}
		}
	}
	.expert-block {
		margin: 195px 0 195px;
		display: block;
		@include transitionCss(.2s);
		@media (max-width: 1439px) {
			margin: 125px 0 125px;
		}
		@media (max-width: 1023px) {
			margin: 130px 0 142px;
		}
		@media (max-width: 767px) {
			margin: 140px 0 112px;
		}
		.left {
			width: 335px;
			height: 170px;
			float: left;
			background: rgba(241, 241, 241, .05);
			@media (max-width: 1260px) {
				width: 275px;
			}
			@media (max-width: 1023px) {
				width: 100%;
				float: none;
				height: auto;
			}
			.img {
				width: 236px;
				display: block;
				margin: -61px auto -12px;
			}
			.logo {
				width: 100%;
				height: 65px;
				background: #ec7616 url(/files/images/me_logo.svg) no-repeat center center;
				background-size: 187px;
			}
		}
		.right {
			float: left;
			height: 170px;
			width: calc(100% - 335px);
			background: #f1f1f1;
			border-left: 1px solid #fff;
			color: #000;
			position: relative;
			@media (max-width: 1260px) {
				width: calc(100% - 275px);
			}
			@media (max-width: 1023px) {
				margin-bottom: 0;
				width: 100%;
				float: none;
				height: auto;
			}
			.absolute {
				position: absolute;
				top: 50%;
				padding: 0 50px;
				@include transformCss(translate(0, -50%));
				@media (max-width: 1260px) {
					padding: 0 25px;
				}
				@media (max-width: 1023px) {
					padding: 20px 25px;
					position: static;
					@include transformCss(translate(0, 0));
				}
				.title {
					font-size: 36px;
					text-transform: uppercase;
					margin-bottom: 5px;
					line-height: 40px;
					@media (max-width: 1260px) {
						font-size: 28px;
						line-height: 32px;
					}
					@media (max-width: 767px) {
						font-size: 23px;
						margin-bottom: 10px;
					}
				}
				.description {
					font-size: 22px;
					line-height: 24px;
					font-weight: 300;
					@media (max-width: 1260px) {
						font-size: 18px;
						line-height: 22px;
					}
					@media (max-width: 767px) {
						font-size: 18px;
						line-height: 1.4;
					}
				}
			}
		}
	}
	.equipment-layout {
		margin-top: 112px;
		margin-bottom: 150px;
		@media (max-width: 1023px) {
			margin-top: 79px;
			margin-bottom: 120px;
		}
		@media (max-width: 767px) {
			margin-top: 72px;
			margin-bottom: 100px;
		}
		> .title {
			font-size: 25px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 36px;
			@media (max-width: 767px) {
				margin-bottom: 31px;
			}
		}
		.equipment-list {
			.equipment-item {
				margin-bottom: 37px;
				@media (max-width: 767px) {
					margin-bottom: 85px;
				}
				> .title {
					font-size: 20px;
					font-weight: 600;
					margin-bottom: 37px;
					@media (max-width: 767px) {
						margin-bottom: 22px;
					}
				}
				.list {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					.item {
						font-size: 16px;
						flex-basis: 25%;
						margin-bottom: 14px;
						display: flex;
						align-items: center;
						@media (max-width: 1023px) {
							flex-basis: 33.3333%;
						}
						@media (max-width: 767px) {
							flex-basis: 100%;
						}
						.tick {
							height: 20px;
							width: 20px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
	.model-more {
		margin-bottom: 128px;
		@media (max-width: 1023px) {
			margin-bottom: 110px;
		}
		@media (max-width: 767px) {
			margin-bottom: 87px;
		}
		.link {
			cursor: pointer;
			border-radius: 2px;
			font-size: 14px;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			padding: 12px 0;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			min-width: 297px;
			min-height: 34px;
			@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
			&:hover {
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #edad01;
			}
			@media (max-width: 767px) {
				min-width: 212px;
				width: 100%;
			}
			i {
				margin-left: 10px;
			}
		}
	}
	.similar-cars {
		> .title {
			font-size: 25px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 60px;
			@media (max-width: 1023px) {
				margin-bottom: 48px;
			}
		}
	}
	.button-wrapper {
		display: flex;
		text-align: center;
		justify-content: center;
		@media (max-width: 1023px) {
			margin-top: 72px;
		}
		@media (max-width: 767px) {
			display: none;
		}

		.link {
			cursor: pointer;
			border-radius: 2px;
			font-size: 14px;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			padding: 12px 0;
			min-width: 194px;
			min-height: 34px;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
			&:hover {
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #edad01;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
			i {
				margin-left: 10px;
			}
		}

		.link {
			cursor: pointer;
			border-radius: 2px;
			font-size: 14px;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 600;
			padding: 12px 0;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			margin-bottom: 29px;
			min-width: 297px;
			min-height: 34px;
			&:first-child {
				margin-right: 21px;
			}
			@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
			&:hover {
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #edad01;
			}
			i {
				margin-left: 7px;
			}
			@media (max-width: 1023px) {
				min-width: 212px;
				width: 100%;
				font-size: 14px;
			}
		}
	}
}
