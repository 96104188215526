.site-map{
	font-family: "Roboto", sans-serif;
	.main-title{
		text-align: center;
		font-size: 35px;
		font-weight: 600;
		margin-top: 65px;
		margin-bottom: 80px;
		@media (max-width: 767px) {
			text-align: left;
			margin-top: 45px;
			margin-bottom: 45px;
		}
	}
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.fa{
		font-size: 13px;
	}
	.sitemap_wrapper{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px 125px -15px;
		@media (max-width: 767px){
			margin-bottom: 75px;
		}
		.sitempap_col{
			flex-basis: 25%;
			margin-bottom: 20px;
			padding: 0 15px;
			@media (max-width: 1023px) {
				flex-basis: 33.3333%;
			}
			@media (max-width: 767px) {
				flex-basis: 100%;
			}
			.sitemap_header{
				.sitemap_header_link{
					font-size: 15px;
					font-weight: 600;
					color: #000;
					text-transform: uppercase;
				}
			}
			.sitemap{
				.sitemap_lev1{
					li{
						a{
							font-size: 12px;
							color: #000;
							&:hover{
								color: #FEB733;
							}
						}

					}
				}
				.sitemap_lev2{
					margin-left: 30px;
					@for $i from 3 through 8{
						.sitemap_lev#{$i}{
							$num: 5px*$i;
							margin-left: $num;
						}
					}
					li{
						.fa{
							margin-right: 3px;
						}
						a{
							font-size: 12px;
							color: #000;
							position: relative;
							&:not(.toogle-link)::before {
								content: "";
								width: 4px;
								height: 4px;
								background-color: #000;
								position: absolute;
								border-radius: 100%;
								left: -9px;
								top: 4px;
							}
							&.pointer{
								&::before{
									content: "";
									width: 4px;
									height: 4px;
									background-color: #000;
									position: absolute;
									border-radius: 100%;
									left: -9px;
									top: 4px;
								}
							}
							&:hover{
								color: #FEB733;
								&::before{
									background-color: #FEB733;
								}
							}
						}
					}
				}
			}
		}
	}
}