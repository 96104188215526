.contacts-list{
	font-family: "Roboto", sans-serif;
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title{
		font-size: 35px;
		font-weight: 600;
		margin: 40px 0;
		line-height: 1.3;
		@media (max-width: 767px) {
			font-size: 30px;
			margin: 35px 0;
		}
	}
	.routing_list{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 50px;
		.standart_item{
			max-width: 234px;
			margin-right: 35px;
			&:first-child{
				@media (max-width: 767px) {
					margin-bottom: 50px;
				}
			}
			@media (max-width: 767px) {
				margin-right: 0;
			}
			.standart_item_header{
				margin-top: 18px;
				a{
					font-size: 18px;
					line-height: 22px;
					color: #000;
					font-family: "proxima_nova_rgbold","robotobold",sans-serif;
					font-weight: 600;
					&:hover{
						color: #ffb302;
					}
				}
			}
			.standart_item_description{
				margin-top: 10px;
				color: #666;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}