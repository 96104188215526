.card-contact{
	font-family: "Roboto", sans-serif;
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title{
		text-align: center;
		font-size: 35px;
		margin-top: 40px;
		margin-bottom: 40px;
		line-height: 1.3;
		font-family: "Roboto", sans-serif;
		@media (max-width: 767px){
			text-align: left;
			font-size: 30px;
			margin-top: 35px;
			margin-bottom: 35px;
		}
	}
	.card-wrapper{
		margin-bottom: 65px;
		.contacts_box{
			@media (max-width: 1023px){
				display: flex;
				flex-flow: column-reverse;
			}
			.contacts_list{
				width: 32.9%;
				min-height: 457px;
				position: relative;
				@media (max-width: 1279px){
					margin-right: 40px;
				}
				@media (max-width: 1023px){
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					margin-top: 50px;
					min-height: 100%;
					margin-right: 0;
				}
				.contacts_box_item{
					margin-bottom: 45px;
					display: flex;
					padding-left: 0;
					@media (max-width: 1279px){
						margin-bottom: 40px;
					}
					@media (max-width: 1023px){
						flex-basis: 50%;
						margin-bottom: 50px;
					}
					@media (max-width: 767px){
						flex-basis: 100%;
					}
					&::before{
						content: none;
					}
					.image-wrapper{
						width: 45px;
						margin-right: 25px;
						@media (max-width: 1023px){
							margin-right: 15px;
						}
						@media (max-width: 767px){
							width: 50px;
						}
					}
					.contacts_box_header{
						color: #000;
						font-size: 20px;
						margin-bottom: 25px;
						@media (max-width: 1279px){
							margin-bottom: 22px;
						}
						@media (max-width: 767px){
							font-size: 23px;
						}
					}
					.contacts_box_description{
						font-size: 19px;
						line-height: 1.3;
						max-width: 300px;
					}
				}
				.btn{
					font-size: 14px;
					font-weight: 600;
					text-transform: uppercase;
					min-width: 263px;
					text-align: center;
					padding: 9px 0!important;
					background-color: #ffba00;
					margin-top: 27px;
					@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
					i{
						margin-left: 7px;
					}
					&.md-hidden{
						display: inline-block;
						position: absolute;
						bottom: 0;
						@media (max-width: 1023px){
							display: none;
						}
					}
					&:hover{
						@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
						background-color: #edad01;
					}
				}
			}
			.contacts_image{
				width: 67%;
				height: 457px;
				@media (max-width: 1439px){
					width: 584px;
				}
				@media (max-width: 1023px){
					width: 100%;
					height: 425px;
				}
				@media (max-width: 767px){
					height: 176px;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		.btn{
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			min-width: 263px;
			text-align: center;
			padding: 9px 0!important;
			background-color: #ffba00;
			margin-top: 27px;
			@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
			&:hover{
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #edad01;
			}
			i{
				margin-left: 7px;
			}
			&.md-vision{
				display: none;
				@media (max-width: 1023px){
					display: inline-block;
					margin-bottom: 50px;
				}
				@media (max-width: 767px){
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
		.road-title{
			font-size: 25px;
			font-family: "Roboto", sans-serif;
			font-weight: normal;
			margin-top: 70px;
			margin-bottom: 30px;
		}
		.ymaps-2-1-69-controls-pane{
			display: none;
		}
	}
}