.page-404{
	font-family: "Roboto", sans-serif;
	.num-title{
		font-size: 209px;
		text-align: center;
		font-weight: 600;
		margin-top: 55px;
		margin-bottom: 40px;
		@media (max-width: 1439px){
			margin-top: 25px;
			margin-bottom: 15px;
		}
		@media (max-width: 767px){
			font-size: 168px;
			margin-bottom: 65px;
		}
	}
	.title{
		font-size: 35px;
		font-weight: 600;
		color: #000;
		text-align: center;
		margin-bottom: 10px;
		@media (max-width: 767px){
			text-align: left;
			line-height: 1.2;
			margin-bottom: 15px;
		}
	}
	.description-title{
		font-size: 16px;
		text-align: center;
		margin-bottom: 35px;
		@media (max-width: 1439px){
			margin-bottom: 85px;
		}
		@media (max-width: 767px){
			text-align: left;
			margin-bottom: 50px;
		}
	}
	.content-tiles-row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.content-tiles-box{
			background: #e8e8e7;
			border-bottom: 4px solid #c6c6c6;
			position: relative;
			margin: 0 15px 30px 15px;
			@media (max-width: 767px){
				margin-left: 0;
				margin-right: 0;
			}
			&.page-not-found{
				width: calc(50% - 31px);
				@media (max-width: 767px){
					width: 100%;
					margin-bottom: 45px;
				}
			}
			.content-tiles-wrapper{
				padding: 20px 15px;
				@media (max-width: 767px){
					padding-top: 25px;
				}
				.subtitle{
					font-family: "proxima_nova_rgbold","robotobold",sans-serif;
					font-size: 20px;
					margin-bottom: 20px;
					text-transform: uppercase;
					@media (max-width: 767px){
						margin-bottom: 10px;
					}
				}
				.description{
					min-height: 100px;
					margin: 0;
					font-size: 14px;
					line-height: 20px;
					@media (max-width: 767px){
						font-size: 12px;
					}
					p{
						margin-bottom: 50px;
						@media (max-width: 767px){
							margin-bottom: 35px;
						}
					}
				}
				.cta-wrapper{
					bottom: 20px;
					right: 15px;
					left: inherit;
					position: absolute;
					text-align: left;
					@media (max-width: 767px){
						bottom: 13px;
					}
					.link{
						text-transform: uppercase;
						color: #000;
						min-width: 193px;
						display: block;
						font-size: 14px;
						font-weight: 600;
						text-align: center;
						padding: 8px 0;
						background-color: #ffba00;
						@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
						&:hover{
							background-color: #edad01;
							@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
						}
						i{
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
	.contact-title{
		font-size: 35px;
		margin: 55px auto 45px auto;
		font-weight: 600;
		@media (max-width: 767px){
			margin-top: 100px;
		}
	}
	.contact {
		font-family: "robotoregular", sans-serif;
		display: flex;
		margin: 0 auto 85px auto;
		@media (max-width: 767px) {
			display: block;
			margin-bottom: 105px;
		}
		.card {
			display: flex;
			&:first-child {
				margin-right: 55px;
			}
			@media (max-width: 767px) {
				&:first-child {
					margin-bottom: 38px;
					margin-right: 0;
				}
			}
			.image {
				margin-right: 19px;
				margin-top: 10px;
				@media (max-width: 767px) {
					margin-right: 14px;
					margin-top: 2px;
				}
				.item {
					@media (max-width: 767px) {
						width: 40px;
						height: 55px;
					}
				}
			}
			.header {
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 5px;
				@media (max-width: 767px) {
					font-size: 19px;
				}
				.link {
					color: #000;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.description {
				font-size: 15px;
			}
			.point {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
				margin-top: 45px;
				color: #000;
				display: block;
				@media (max-width: 767px) {
					margin-top: 15px;
				}
				&::after {
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #FEB733;
					padding-left: 4px;
					font-size: 12px;
				}
			}
		}
	}
}