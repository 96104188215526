.contacts {
	.logo {
		display: block;
		margin: 45px 0 20px;
		width: 161px;
		height: 23px;
	}
}

.salons_header_contacts {
	top: 36px;
}

.footer-bottom-links {
	padding-top: 30px !important;
}

.header-contacts {
	width: 215px;
	position: absolute;
	right: 0;
	top: 8px;
	padding: 0 38px;
}

.header_foris_icon {
	height: 36px;
	position: relative;
	display: block;
}

.header_foris_icon:after {
	position: absolute;
	content: ' ';
	top: 5px;
	left: 50%;
	margin-left: 0;
	width: 161px;
	height: 23px;
	transform: translateX(-50%);
	background: url(/files/images/major_logo.png) no-repeat center;
}

.flyout-menu-hamb-item {
	.header {
		height: 52px !important;
		overflow: hidden !important;
	}
	.item-content {
		height: auto !important;
		overflow: hidden !important;
	}
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .item-content.active {
	border-bottom: 1px solid #bdbdbd;
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .item-content.active .flyout {
	display: block;
	position: static;
	background: inherit;
}

.header_foris_contact {
	color: #ffba02;
	font-size: 16px;
	font-weight: bold;
	font-family: "PT Sans", sans-serif;
	text-align: center;
	position: relative;
	height: 38px;
	margin-bottom: -9px;
	.salons_header_contacts {
		display: none;
		position: absolute;
		background: #151515;
		z-index: 150;
		top: 36px;
		right: -58px;

	}
	&:hover .salons_header_contacts {
		display: block;
	}
}

.header_contacts_link {
	text-decoration: none;
	display: block;
	padding: 20px 17px 0 17px;
	width: 200px;
	&:hover {
		text-decoration: none;
	}
	&:hover .header_cont_salon_name {
		color: #ffba02;
	}
	&.last {
		padding-bottom: 20px;
	}
	.header_cont_salon_name {
		font-family: "robotoregular", sans-serif;
		font-size: 14px;
		color: #fff;
		text-decoration: none;
		font-weight: normal;
		text-align: left;
		margin: 0 0 5px 0;
	}
	.haeder_cont_salon_phone {
		font-family: "robotoregular", sans-serif;
		font-size: 14px;
		font-weight: bold;
		color: #ffba02;
		text-decoration: none;
		text-align: left;
	}
}

.header_foris_logo {
	float: right;
	.contacts_salons {
		.salon_header_ico {
			color: #ffba02;
			padding-left: 17px;
			position: relative;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 5px;
				left: 0;
				width: 10px;
				height: 16px;
				background: url('/files/resources/style/contact_label_small_icon.png');
			}
		}
		.salons_header {
			color: #ffba02;
			text-decoration: none;
			position: relative;
			cursor: pointer;
			.salon_header_underline {
				color: #ffba02;
				border-bottom: 1px dashed #ffba02;
			}
		}
	}
}

.salon {
	font-size: 15px;
	padding: 12px 0;

	.name {
		color: white;
		display: block;
		white-space: nowrap;
		padding-bottom: 3px;
	}
	.phone-link {
		color: $main-orange;
		font-weight: 600;
		white-space: nowrap;
	}
}

.salons-xs {
	background: #151515;
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .header.active {
	background: white !important;
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .header {
	border-top: none !important;
	border-bottom: 1px solid #bdbdbd !important;
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .item-content {
	background: white !important;
}

.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .item-content.active .flyout {
	overflow: hidden !important;
}

.-nowrap {
	white-space: nowrap;
}

@media only screen and (min-width: 1271px) {
	.footer-flex {
		.footer-col {
			//flex-basis: 14.2% !important;
			//max-width: 14.2% !important;
			//a:not(.footer-social-icon) {
			//	width: 75%;
			//}
		}
	}
}

@media only screen and (max-width: 1270px) and (min-width: 769px) {
	.footer-flex {
		//.footer-col {
		//	&, &:nth-child(1), &:nth-child(2) {
		//		flex-basis: 25% !important;
		//		max-width: 25% !important;
		//		a:not(.footer-social-icon) {
		//			width: 75%;
		//		}
		//	}
		//}
	}
}

@media only screen and (max-width: 768px) and (min-width: 740px) {
	.footer-flex {
		//.footer-col {
		//	&, &:nth-child(1), &:nth-child(2) {
		//		flex-basis: 33% !important;
		//		max-width: 33% !important;
		//	}
		//}
	}
}

@media only screen and (min-width: 740px) {
	.footer-flex .footer-col {
		padding-bottom: 30px;
	}
	.footer-flex .footer-col:nth-child(1), .footer-flex .footer-col:nth-child(2) {

	}
}

.footer-col-content a {
	padding-right: 10px;
}

.main-menu-container .flyout-container .flyout.flyout-vehicles .flyout-row1 {
	text-align: left;
}

@media only screen and (min-width: 1600px) {
	.header-vehicles-item .content .model-thumb {
		width: 85% !important;
	}
}

@media only screen and (max-width: 999px) {
	.main-menu-container .main-menu-header,
	.main-menu-container ul.main-nav li {
		height: 45px !important;
	}

	.header-box-footer-container.large {
		border-right: none !important;
	}

	.header-vehicles-item .content .arrow {
		padding-right: 10px;
	}

	.main-menu-container .logo-container {
		width: 65px !important;
	}
	.main-menu-container .flyout-container .flyout {
		top: 45px !important;
	}

	.main-menu-container .flyout-container .flyout.flyout-menu-hamb .flyout-menu-hamb-item .item-content {
		padding-top: 0 !important;

	}

	.main-menu-container .flyout-container .flyout .flyout-row1 .flyout-row-container {
		padding-top: 0 !important;
		padding-bottom: 0!important;
	}

	.header-contacts {
		right: 50%;
		transform: translateX(0);
		margin-right: -85px;
		padding: 0;
		width: 161px;


		.salons_header_contacts, .header_foris_contact {
			display: none;
		}

		.header_foris_icon:after {
			display: block;
			left: 0;
			top: 0;
			margin: 0;
			position: relative;
			transform: translateX(0);
			height: 37px;
		}
	}
}

@media only screen and (max-width: 739px) {
	.contacts {
		.logo {
			margin-top: 0;
		}
	}
}
@media only screen and (max-width: 399px){
	.header-contacts{
		margin-right: -63px;
		padding: 0;
		width: 135px;
		transform: translateX(0);


		.header_foris_icon:after{
			width: 135px;
			background-size: contain;
			left: 0;
			top: 0;
			margin: 0;
			position: relative;
			transform: translateX(0);
		}
	}

	.header_foris_logo {
		float: none;
	}
}

.main-menu-container ul.main-nav li a.main-nav-item > .menu-item-text{
	display: none;
}
.callback_button_header{
	background: url('/files/resources/img/phone.png');
	width: 30px;
	height: 30px;
	display: flex;
	float: left;
	margin: 7px 10px 0 0;
}
.menu-item.active .callback_button_header{
	display: none;
}
.callback_button_header_big{
	position: absolute;
	right: 190px;
	@media only screen and (max-width: 1315px){
      	display: none!important;
    }
}