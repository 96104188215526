.fwin_body_scroll_fix{
	width: fit-content!important;
	margin: 0 auto;
}
.fw_win_wrapper .fw_win_content_container {
	&.wns {
		&.adm-s {
			overflow-y: scroll;
			.fw_win_close_button {
				font-size: 20px;
				line-height: 50px;
			}
		}
		input, label, .input-wrapper {
			font-size: 12px;
		}
		border-radius: 3px;
		font-family: "robotoregular", sans-serif;
		padding: 0 70px;
		max-width: 843px;
		display: inline-block!important;
		@media (max-width: 1023px) {
			max-width: 697px;
			padding: 0 20px;
		}
		@media (max-width: 767px) {
			max-width: 291px;
			padding: 0 5px;
		}
		.fw_win_title {
			margin: 0 -70px;
			height: 83px;
			text-align: center;
			background-color: #343434;
			font-size: 25px;
			color: #fff;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			padding-left: 85px;
			@media (max-width: 1023px) {
				margin: 0 -20px;
				padding-left: 33px;
			}
			@media (max-width: 767px) {
				height: 182px;
				margin: 0 -5px;
				padding-left: 15px;
				text-align: left;
			}
			.fw_win_close_button {
				background: none;
				width: auto;
				height: auto;
				right: 33px;
				top: 50%;
				transform: translateY(-50%);
				@media (max-width: 767px) {
					right: 10px;
					top: 12%;
				}
				&:before {
					content: "\f10b";
					display: inline-block;
					font-family: "Flaticon";
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					line-height: 1;
					text-decoration: inherit;
					text-rendering: optimizeLegibility;
					text-transform: none;
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-smoothing: antialiased;
				}
			}
		}
		.fw_win_common_offset {
			padding: 70px 15px 15px 15px;
			@media (max-width: 767px) {
				padding: 55px 15px 15px 15px;
			}
		}
		.fw_win_content {
			.success_wrapper {
				.success_subheader {
					margin-bottom: 15px;
				}
				.style_p {
					font-size: 16px;
					margin-bottom: 15px;
				}
				.btn {
					margin-top: 15px;
					margin-bottom: 15px;
					font-size: 14px;
					font-weight: 600;
					background-color: #FEB733;
					text-transform: uppercase;
					width: 100%;
					text-align: center;
					transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
					outline: none;
					&::after {
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #000;
						padding-left: 6px;
						font-size: 13px;
						position: relative;
					}
					&:hover {
						transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
						background-color: #EDAD01;
					}
				}
			}
			.form_custom_info_wrapper {
				.used_list {
					.standart_item {
						display: flex;
						align-items: center;
						margin-bottom: 53px;
						@media (max-width: 767px) {
							display: block;
							margin-bottom: 30px;
						}
						.used_list_item_left {
							.standart_item_img {
								width: 241px !important;
								height: 181px !important;
							}
						}
						.used_list_item_right {
							margin-left: 25px;
							@media (max-width: 767px) {
								margin-left: 0;
							}
							.standart_item_header {
								font-size: 22px;
								font-weight: 600;
								margin-bottom: 14px;
								@media (max-width: 767px) {
									margin-top: 15px;
								}
							}
						}
					}
				}
			}
			.form_def {
				.form_field_item.type_select_test_drive.cf, .form_field_item.type_select.cf {
					.form_label_layout {
						font-size: 12px;
						top: 48%;
					}
				}
				.ik_select {
					.ik_select_link {
						background: none !important;
						font-size: 16px;
						display: flex;
						align-items: center;
						height: 50px;
						padding: 25px 10px 2px 0;
						border: none;
						border-bottom: 1px solid #BEBEBE;
						border-radius: 0;
						z-index: 9;
						&::before {
							content: "\f108";
							font-family: "Flaticon";
							display: block;
							font-size: 17px;
							color: #000;
							position: absolute;
							right: 15px;
							top: 17px;
							transition: .2s;
							transform: rotate(180deg);
						}
						&.ik_select_link_focus {
							box-shadow: none;
						}
					}
				}
				.group_name {
					margin-bottom: 15px;
					margin-top: 25px;
					font-size: 20px;
				}
				.form_fields_group_wrapper {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					.type_textarea{
						.form_label_layout{
							top: 30px!important;
						}
						textarea{
							width: 100%;
							height: 92px;
							box-sizing: border-box;
							border-bottom: 1px solid #AFAFAF;
							border-radius: 0;
							box-shadow: none;
							font-size: 16px;
							padding: 10px;
							margin-top: 30px;
						}
					}
					.type_date, .type_time {
						.form_label_layout {
							font-size: 12px !important;
							top: 48% !important;
						}
					}
					.type_checkbox {
						margin-top: 42px;
						@media (max-width: 767px) {
							margin-top: 30px;
						}
					}
					.form_field_item {
						position: relative;
						.form_label_layout {
							color: #BEBEBE;
							font-size: 14px;
							position: absolute;
							top: 100%;
							transform: translateY(-100%);
							left: 0;
							padding: 0 10px 0 0;
							width: 100%;
							z-index: 1;
							transition: all .15s ease-out;
							&.td-form__input_not-empty {
								transition: all .15s ease-out;
								top: 50%;
								font-size: 12px;
							}
						}
					}
					input {
						width: 100%;
						border: none;
						font-size: 16px;
						border-bottom: 1px solid #BEBEBE;
						padding: 23px 10px 2px 0;
						&.formjor-err {
							border-color: red;
						}
					}
					.type_checkbox {
						.form_field_layout {
							//display: flex;
							.flex {
								align-items: center;
							}
							.def_inp {
								font-size: 1px;
								height: 0;
								width: auto;
								position: relative;
								&.formjor-err {
									&::before {
										border-color: #ff1a4c;
									}
								}
								&:checked {
									&::after {
										transform: scale(1);
										transition: transform .15s ease;
									}
									&::before {
										border: 1px solid #FEB733;
										transition: border-color .15s ease;
									}
								}
								&::after {
									content: '';
									display: block;
									position: absolute;
									left: 3px;
									top: -7px;
									font-size: 23px;
									width: 10px;
									border-radius: 100%;
									height: 10px;
									background-color: #FEB733;
									transform: scale(0);
									transition: transform .15s ease;
									@media (max-width: 767px) {
										top: -29px;
									}
								}
								&::before {
									content: '';
									display: block;
									position: absolute;
									left: 0;
									top: -10px;
									font-size: 23px;
									width: 16px;
									border-radius: 100%;
									height: 16px;
									border: 1px solid #828282;
									transition: border-color .15s ease;
									@media (max-width: 767px) {
										top: -32px;
									}
								}
							}
							.form_label {
								margin-bottom: 0;
								font-size: 16px;
								font-weight: normal;
								margin-left: 25px;
								@media (max-width: 767px) {
									margin-left: 20px;
								}
								.i_agree_personal_data {
									color: #FEB733;
									text-decoration: underline;
									cursor: pointer;
								}
							}
							.error_notice {
								margin-bottom: 0;
								font-size: 16px;
								font-weight: normal;
								margin-left: 39px;
								@media (max-width: 767px) {
									margin-left: 33px;
								}
							}
						}
					}
				}
				.more_about_box {
					font-size: 13px;
					line-height: 1.6;
					margin-top: 20px;
					@media (max-width: 767px) {
						margin-top: 50px;
					}
					> p {
						font-size: 13px;
					}
					.more_about_security {
						color: #FEB733;
						text-decoration: underline;
						cursor: pointer;
					}
				}
				.submit_button_wrapper {
					margin-top: 30px;
					margin-bottom: 74px;
					@media (max-width: 767px) {
						margin-top: 62px;
						margin-bottom: 56px;
					}
					button {
						font-size: 14px;
						font-weight: 600;
						background-color: #FEB733;
						text-transform: uppercase;
						width: 100%;
						text-align: center;
						transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
						outline: none;
						&::after {
							content: "\f109";
							font-family: "Flaticon";
							font-style: normal;
							font-weight: bold;
							font-variant: normal;
							line-height: 1;
							color: #000;
							padding-left: 6px;
							font-size: 13px;
							position: relative;
						}
						&:hover {
							transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
							background-color: #EDAD01;
						}
					}
				}
			}
			.custom_info {
				font-size: 16px;
			}
			.all-field {
				color: #AFAFAF;
				margin: 15px 0;
			}
			.input-wrapper {
				width: 100%;
				position: relative;
				margin-bottom: 20px;
				label {
					font-weight: bold;
					color: #4e678f;
				}
				&.col-50 {
					width: 50% !important;
					float: left;
				}
				&.link {
					> input {
						padding-right: 60px;
					}
					.-link {
						position: absolute;
						right: 0;
						top: 0;
						background: #4e678f;
						color: #fff;
						border-top-right-radius: 19px;
						border-bottom-right-radius: 19px;
						padding: 2px 18px 6px 12px;
						font-size: 18px;
						&:hover {
							background: #3f6197;
						}
					}
				}
				input + label, textarea + label {
					position: absolute;
					top: 7px;
					font-weight: normal;
					left: 15px;
					color: #999;
					transition: 0.2s;
				}
				input, textarea {
					&:focus + label, &:valid + label {
						top: -18px;
						left: 0;
						font-weight: bold;
						color: #4e678f;
						&:after {
							content: ':';
						}
					}
				}
				& select, & input, & textarea {
					box-sizing: border-box;
					border-radius: 20px;
					border: 1px solid #e6ebf1;
					outline: 0;
					background: #fff;
					display: block;
					padding: 0 15px;
					width: 100%;
					height: 32px;
					resize: none;
				}
				textarea {
					height: 75px;
					padding-top: 10px;
				}
				.image_extended_wrapper {
					height: 60px;
					.ext_images_preview_block {
						border: 1px solid #e6ebf1;
						padding: 0;
						height: 60px;
						border-radius: 20px;
						width: 85px;
						background-size: cover;
					}
					.ext_images_description_block.with_image {
						height: 60px;
						padding: 0 0 0 10px;
						> .multi_group {
							padding: 0;
							color: #4e678f;
						}
						&:hover {
							background-color: transparent !important;
						}
					}
					.ext_images_clear_block {
						height: auto;
						width: auto;
						position: absolute;
						right: 10px;
						top: 15px;
						background: 0 0;
						text-decoration: none;
						font-size: 20px;
						color: #4e678f;
						transition: color 0.5s;
						&:before {
							content: "\f10c";
							display: inline-block;
							font-family: Flaticon;
							font-weight: 400;
							line-height: 1;
							text-decoration: inherit;
							-webkit-font-smoothing: antialiased;
							font-smoothing: antialiased;
						}
						&:hover {
							background-color: transparent !important;
							color: #e6ebf1;
						}
					}
					.ext_images_description_block.no_image {
						width: 100%;
						padding: 0;
						height: 60px;
						border: 2px dashed #e6ebf1;
						border-radius: 5px;
						text-align: center;
						.ext_img_description_single_line {
							line-height: 60px;
						}
					}
				}
			}
			.check-wrapper {
				*[type="checkbox"] {
					display: none;
				}
				*[type="checkbox"] + label {
					padding-left: 24px;
					padding-top: 2px;
					font-size: 13px;
					pointer-events: auto;
					cursor: default;
					color: #4e678f;
					position: relative;
					display: block;
					line-height: 18px;
					user-select: none;
				}
				*[type="checkbox"] + label:before {
					display: inline-block;
					content: '';
					width: 13px;
					height: 13px;
					background-color: #fff;
					border: 1px solid #e6ebf1;
					position: absolute;
					left: 0;
					top: 4px;
					border-radius: 4px;
				}
				*[type="checkbox"]:checked + label:before {
					background: #4e678f url('/images/admin/admin_icons/dashboard/cheked_white.png') no-repeat center;
					border-color: #4e678f;
				}
			}
			.submit_block {
				text-align: center;
				.submit_button {
					display: inline-block;
					padding: 10px 35px;
					background: #4e678f !important;
					color: #fff;
					height: auto;
					border: none;
					font-size: 12px;
					border-radius: 20px;
					margin-top: 17px;
					text-decoration: none;
				}
			}
		}
	}
	.credit_form_car_info.clearfix.row{
		margin-top: 15px;
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		@media (max-width: 767px){
			display: block;
		}
		.car_info{
			margin-left: 25px;
			@media (max-width: 767px){
				margin-left: 0;
				margin-top: 10px;
			}
			.car_name{
				font-size: 22px;
				font-weight: 600;
			}
			.car_info_credit_price{
				margin-top: 12px;
			}
		}
	}
	.-credit {
		.credit_calculation_block {
			.row {
				margin: 0 -40px;
				.col-md-6 {
					padding-left: 40px;
					padding-right: 40px;
					.field_wrapper {
						span.field_wrapper.block_button.field.slider_content {
							top: -8px;
							position: relative;
						}
						.label_wrapper {
							margin-bottom: 3px;
							label {
								font-size: 16px;
								font-weight: normal;
							}
						}
						.input_wrapper {
							.form-control {
								border-radius: 0;
								height: 39px;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
		div#calc_credit_button {
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			text-align: center;
			background-color: #FEB733;
			display: block;
			padding: 11px 0;
			top: -15px;
			position: relative;
			@media (max-width: 1023px){
				margin-top: 40px;
			}
			@media (max-width: 767px){
				margin-top: 40px;
			}
			&::after {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #000;
				padding-left: 6px;
				font-size: 13px;
				position: relative;
			}
		}
	}
	div#calc_results {
		.more_about_wrapper{
			font-size: 13px;
			line-height: 1.6;
			margin-top: 44px;
			margin-bottom: 60px;
			display: inline-block;
			@media (max-width: 1023px){
				margin-top: 30px;
				margin-bottom: 30px;
			}
			@media (max-width: 767px){
				margin-top: 25px;
			}
			p{
				font-size: 13px;
			}
			.more_about_security{
				color: #FEB733;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.button-submit-wrapper{
			.form_submit_button{
				font-size: 14px;
				font-weight: 600;
				text-transform: uppercase;
				text-align: center;
				background-color: #FEB733;
				display: block;
				padding: 11px 0;
				margin-top: 55px;
				width: 100%;
				outline: none;
				box-shadow: none;
				border: none;
				&::after{
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #000;
					padding-left: 6px;
					font-size: 13px;
					position: relative;
				}
				@media (max-width: 1023px){
					margin-top: 30px;
				}
				@media (max-width: 767px){
					margin-top: 25px;
				}
			}
		}
		.disclaimer{
			font-size: 13px;
			line-height: 1.6;
			margin-top: 25px;
		}
		.fields_group {
			hr {
				display: none;
			}
			h3 {
				font-size: 25px;
				margin: 55px 0 30px 0;
				.fa-asterisk {
					&::before {
						content: none;
					}
				}
			}
			.form_result_list {
				.result_block_col {
					margin-bottom: 22px;
					.title {
						font-weight: 600;
					}
				}
			}
			.disclaimer {
				font-size: 13px;
				max-width: 425px;
				margin-top: 15px;
				.fa-asterisk {
					&::before {
						content: none;
					}
				}
			}
		}
	}
	.field_wrapper.ftype_text{
		position: relative;
		top: -20px;
		.label_wrapper{
			color: #BEBEBE;
			font-size: 14px;
			position: absolute;
			top: 100%;
			transform: translateY(-100%);
			left: 0;
			padding: 0 10px 0 0;
			width: 100%;
			z-index: 1;
			transition: all .15s ease-out;
			&.td-form__input_not-empty{
				transition: all .15s ease-out;
				top: 50%;
				font-size: 12px;
			}
		}
	}
	.field_wrapper.ftype_checkbox{
		position: relative;
		.input_wrapper.ftype_checkbox{
			display: flex;
			align-items: center;
			margin-top: 17px;
		}
		.input-form-wrapper{
			.label_wrapper{
				label{
					margin-bottom: 0;
					font-size: 16px;
					font-weight: normal;
					margin-left: 25px;
					.i_agree_personal_data{
						color: #FEB733;
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
			input{
				font-size: 1px;
				height: 0;
				width: auto;
				position: relative;
				&.formjor-err {
					&::before {
						border-color: #ff1a4c;
					}
				}
				&:checked {
					&::after {
						transform: scale(1);
						transition: transform .15s ease;
					}
					&::before {
						border: 1px solid #FEB733;
						transition: border-color .15s ease;
					}
				}
				&::before{
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: -10px;
					font-size: 23px;
					width: 16px;
					border-radius: 100%;
					height: 16px;
					border: 1px solid #828282;
					transition: border-color .15s ease;
				}
				&::after{
					content: '';
					display: block;
					position: absolute;
					left: 3px;
					top: -7px;
					font-size: 23px;
					width: 10px;
					border-radius: 100%;
					height: 10px;
					background-color: #FEB733;
					transform: scale(0);
					transition: transform .15s ease;
				}
			}
		}
	}
	.input-form-wrapper{
		.error_wrapper{
			display: none;
		}
		input{
			width: 100%;
			border: none;
			font-size: 16px;
			border-bottom: 1px solid #BEBEBE;
			padding: 37px 10px 15px 0;
			box-shadow: none;
			border-radius: 0;
		}
	}
}

.ik_select_dropdown .ik_select_list li.ik_select_hover {
	background: linear-gradient(#FEB733, #fe8e49) !important;
}

.ik_select_link_text {
	font-weight: 600;
	font-family: "robotoregular", sans-serif;
	@media (max-width: 767px) {
		max-width: 250px;
	}
}

.fw_win_content_container.wns.form_window {
	.fw_win_title {
		h1 {
			font-size: 25px;
		}
	}
	.fw_win_content.fw_win_common_offset.fw_win_form_content {
		padding: 30px 15px 30px 15px;
	}
}

.form-default.-credit .noUi-target.noUi-connect {
	box-shadow: none;
	border: 0;
	background: 0 0;
	height: 4px;
	margin: 47px 0 37px 0;
}

.form-default.-credit .noUi-target {
	margin-left: 10px !important;
	margin-right: 10px !important;
}

.form-default.-credit .noUi-base {
	background: #000;
	margin: 0 -9px;
	width: auto;
	transform: translate3d(0, 0, 0);
	height: 65%;
	position: relative;
	z-index: 1;
}

.form-default.-credit .noUi-horizontal .noUi-handle {
	width: 22px;
	height: 22px;
	background: #FEB733;
	border-radius: 90px;
	left: 0;
	margin-top: -3px;
	border: 1px solid #000;
	box-shadow: none;
	&::after, &::before {
		content: none;
	}
}

.form-default.-credit .noUi-pips-horizontal {
	height: 10px;
	width: 97%;
}

.form-default.-credit .noUi-marker-horizontal.noUi-marker-large {
	display: none;
}

.form-default.-credit .noUi-value-horizontal {
	color: #333;
	padding: 0;
	margin-top: -44px;
	font-size: 16px;
	margin-left: -14px;
}

.form-default.-credit .noUi-background {
	background: #000;
	box-shadow: none;
}


