.multi-level-breadcrumbs {
	height: 100%!important;
	@media (max-width: 767px){
		height: 100%!important;
	}
	.bread_crumbs_list ul li {
		&.active {
			a {
				color: #000 !important;
				font-size: 12px;
				font-weight: 400;
				font-family: "robotoregular", sans-serif;
			}
		}
		a {
			padding: 0 15px;
		}
	}
	.main-menu-container {
		.main-menu-level2-container {
			.main-menu-level2-mobile {
				.title {
					text-align: left;
					padding-top: 18px;
					font-size: 14px;
					margin-top: 0!important;
				}
				.content {
					border-bottom: 1px solid #ccc;
					> ul {
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						margin-bottom: 0;
						> li {
							border-bottom: none;
							border-bottom: 1px solid #bdbdbd;
							margin-right: 0;
							&:last-child {
								border: none;
							}
							&:hover {
								background: #fff;
								&::after {
									content: none;
								}
							}
							&.active {
								background: #fff;
								&::after {
									content: none;
								}
								li {
									&:hover {
										background: #fff;
										border-bottom: none;
									}
									&::after {
										content: none;
									}
								}
							}
							a {
								white-space: nowrap;
								color: #000 !important;
								text-transform: capitalize;
								font-size: 14px;
								width: 100%;
								&.active {
									border-left: 4px solid #ffba00;
									margin-left: 4px;
									padding-left: 8px;
								}
							}
							> ul.active {
								display: flex;
								flex-direction: column;
								flex-wrap: wrap;
								margin-left: 10px;
							}
						}
					}
				}
			}
			.main-menu-level2-inner > ul{
				margin-bottom: 0;
				> li {
					&::after {
						content: none;
					}
					&.active {
						border-bottom: none;
						&::after {
							content: none;
						}
					}
					a{
						text-transform: capitalize;
					}
				}
			}
		}
		.main-menu-level3-container .flyout > ul {
			height: auto;
			white-space: normal;
			margin-bottom: 0;
			> li {
				position: relative;
				padding-right: 0;
				&:hover {
					background: #fff;
					border: none;
					&::after {
						content: none;
					}
				}
			}
			a {
				color: #000 !important;
				position: relative;
				text-transform: none;
				&.active {
					border-bottom: 4px solid #ffba00;
					&::after {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 2px 4px 2px;
						border-color: transparent transparent #ffba00 transparent;
						content: " ";
						position: absolute;
						bottom: 0;
						left: 47%;
					}
				}
			}
		}
	}
}
.cars-bred-crumbs{
	.main-menu-level2-container .main-menu-level2-inner{
		@media (max-width: 767px){
			height: 100%!important;
			display: block!important;
		}
		.servizi-navigation{
			padding-left: 15px;
			padding-right: 15px;
			.breadcrumb{
				.first-level{
					&:hover{
						text-decoration: underline;
					}
				}
				span{
					text-transform: initial;
					@media only screen and (max-width: 999px){
						padding-left: 0;
					}
					@media (max-width: 767px){
						padding-right: 5px;
						display: inline-block;
					}
					.separator{
						&::after{
							content: "\f109";
							font-family: "Flaticon";
							font-style: normal;
							font-weight: bold;
							font-variant: normal;
							line-height: 1;
							color: #FFFFFF;
							padding-left: 5px;
							position: relative;
							font-size: 9px;
							top: -1px;
						}
					}
				}
			}
		}
	}
}