.cars-list {
	.card {
		border-bottom: 1px solid #ccc;
		padding-left: 30px;
		position: relative;
		&.d-none {
			display: none;
		}
		&:hover {
			background-color: #F6F6F6;
		}
		.full-link {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
		}
		.flex-center {
			justify-content: center;
			align-items: center;
			display: flex;
			.image-wrapper {
				min-height: 306px;
				align-items: center;
				display: flex;
				.image {
					.item {
						min-height: 210px;
						min-width: 270px;
					}
				}
			}
			.information {
				font-family: 'robotoregular', sans-serif;
				padding-left: 38px;
				.title {
					font-size: 23px;
					font-weight: 600;
					font-family: "Roboto", sans-serif;
					margin-bottom: 7px;
				}
				.description {
					font-size: 16px;
					margin-bottom: 14px;
				}
				.color {
					.item {
						height: 27px;
						width: 27px;
						display: inline-block;
						margin-right: 10px;
						z-index: 2;
						position: relative;
						&.-white-color {
							height: 26px;
							width: 26px;
							border: 1px solid #000;
						}
					}
				}
			}
			.price-wrapper {
				justify-content: center;
				flex-wrap: wrap;
				display: flex;
				.price {
					white-space: nowrap;
					.total {
						font-size: 30px;
						font-weight: 600;
					}
					.olden {
						font-size: 30px;
						color: #868686;
						position: relative;
						width: fit-content;
						margin-top: -5px;
						&::after {
							content: "";
							width: 100%;
							height: 1px;
							background-color: #868686;
							position: absolute;
							left: 0;
							top: 50%;
							@include transformCss(translate(0, -50%));
						}
					}
				}
				.discounts {
					background-color: #FEB733;
					width: fit-content;
					min-width: 169px;
					text-align: center;
					padding: 1px 0;
					margin-top: 35px;
					.text {
						font-family: "Roboto", sans-serif;
						font-size: 15.5px;
					}
					.value {
						font-size: 25px;
						font-weight: 600;
					}
				}
			}
		}
	}
	.more-box {
		text-align: center;
		margin-top: 40px;
		.more {
			text-transform: uppercase;
			color: #000;
			font-size: 12px;
			font-weight: 600;
			position: relative;
			cursor: pointer;
			margin-left: 19px;
			&::after {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #FEB733;
				padding-left: 7px;
				font-size: 12px;
			}
		}
	}
	@media (max-width: 1439px) {
		.full-lg-container {
			width: 100% !important;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		.card {
			padding: 0 30px;
			.flex-center {
				.information {
					.title {
					}
				}
				.image-wrapper {
					.image {
						.item {
							min-height: 190px;
							min-width: 254px;
						}
					}
				}
				.price-wrapper {
					max-width: 170px;
					margin-left: 30px
				}
			}
		}
	}
	@media (max-width: 1023px) {
		.card {
			.right-aside {
				.flex-center {
					display: block;
					.price-wrapper {
						justify-content: stretch;
						align-items: center;
						max-width: initial;
						margin-left: 36px;
						margin-top: 16px;
						.discounts {
							margin-top: 0;
							max-height: 64px;
							padding-top: 5px;
							padding-bottom: 3px;
							margin-left: 60px;
						}
					}
					.information {
						.title {
							font-size: 22px;
						}
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		.card {
			padding: 0 15px 53px 15px;
			.flex-center {
				flex-wrap: wrap;
				max-width: 560px;
				margin: 0 auto;
				.image-wrapper {
					min-height: 265px;
				}
				.information {
					padding-left: 0;
				}
				.right-aside .flex-center {
					.information {
						margin-bottom: 17px;
						.title {
							line-height: 1.1;
							margin-bottom: 17px;
						}
						.description {
							margin-bottom: 25px;
						}
					}
					.price-wrapper {
						margin-left: 0;
						margin-top: 0;
						justify-content: space-between;
						.total {
							font-size: 24px;
						}
						.olden {
							font-size: 24px;
						}
						.discounts {
							margin-left: 0;
							min-width: 133px;
							min-height: 48px;
							line-height: 1.1;
							padding-top: 7px;
							.text {
								font-size: 12px;
							}
							.value {
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}

.model-stock-page {
	font-family: "Roboto", sans-serif;
	.fotorama__fullscreen-icon{
		display: none;
	}
	.container {
		@media (max-width: 1023px) {
			width: 750px;
			margin-right: auto;
			margin-left: auto;
			padding-left: 5px;
			padding-right: 5px;
		}
		@media (max-width: 767px) {
			width: 320px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.main-title {
		font-size: 35px;
		font-weight: 600;
		padding-top: 45px;
		padding-bottom: 25px;
		text-align: center;
		@media (max-width: 767px){
			text-align: left;
		}
	}
	.banner-description {
		.flex {
			display: flex;
			@media (max-width: 1023px) {
				display: block;
			}
		}
		.gallery {
			height: 426px;
			margin-right: 40px;
			width: calc(75% - 40px);
			@media (min-width: 1279px) {
				margin-right: 40px;
				width: calc(100% - 40px);
			}
			@media (max-width: 1023px) {
				margin-right: 0;
				width: 100%;
				height: 304px;
				margin-top: 10px;
				margin-bottom: 15px;
			}
			@media (max-width: 767px) {
				min-width: 290px;
				height: 126px;
				margin-bottom: 10px;
			}
			.photo-stub {
				height: 100%;
				width: 100%;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
		}
		.block-gallery-cars {
			height: 100%;
			.list-slider {
				.photo {
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
				}
			}
		}
		.information {
			@media (max-width: 1023px) {
				width: 100%;
			}
			.min-price {
				float: right;
				@media (max-width: 1023px) {
					float: initial;
				}
				.price {
					font-size: 34px;
					font-weight: 600;
					margin-bottom: 7px;
				}
				.button-wrapper {
					margin-bottom: 40px;
					@media (max-width: 1023px) {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 34px;
					}
					@media (max-width: 767px) {
						margin-bottom: 22px;
					}
					.link {
						border-radius: 2px;
						padding: 12px 0;
						font-size: 14px;
						font-weight: 600;
						text-transform: uppercase;
						display: block;
						color: #000;
						max-width: 241px;
						text-align: center;
						margin-bottom: 11px;
						&.-orange {
							background-color: #ffba00;
							@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
							&:hover {
								@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
								background-color: #edad01;
							}
						}
						@media (max-width: 1023px) {
							&:first-child {
								width: 100%;
								flex-basis: auto;
							}
							&:last-child {
								margin-left: 9px;
							}
							flex-basis: 49.393%;
							max-width: initial;
						}
						@media (max-width: 767px) {
							&:last-child {
								margin-left: 0;
							}
							flex-basis: 100%;
						}
					}
				}
				.interested-car {
					.title {
						font-size: 17px;
						font-weight: 800;
						margin-bottom: 7px;
						@media (max-width: 1023px) {
							margin-bottom: 2px;
						}
					}
					.text {
						font-size: 17px;
						max-width: 230px;
						line-height: 1.3;
						margin-bottom: 35px;
						@media (max-width: 1023px) {
							max-width: initial;
							margin-bottom: 22px;
						}
						@media (max-width: 767px) {
							margin-bottom: 14px;
						}
					}
				}
				.phone {
					.item {
						margin-bottom: 10px;
						.link {
							color: #000;
							font-size: 21px;
							font-weight: 600;
						}
					}
				}
			}
		}
		.short-description-title {
			text-align: center;
			font-size: 19px;
		}
		.short-description {
			font-size: 12px;
			line-height: 1.8;
			margin-top: 35px;
			@media (max-width: 1023px) {
				font-size: 13px;
				margin-top: 10px;
				margin-bottom: 53px;
			}
			@media (max-width: 767px) {
				margin-bottom: 25px;
			}
		}
	}

	.options-wrapper {
		margin-top: 55px;
		margin-bottom: 20px;
		@media (max-width: 767px) {
			display: none;
		}
		.title {
			font-size: 22px;
			font-weight: 600;
			padding-bottom: 6px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 36px;
		}
		.mod-options {
			flex-flow: wrap;
			display: flex;
			margin: 0 -35px;
			@media (max-width: 1023px) {
				margin: 0 -5px;
			}
			.column {
				margin-bottom: 30px;
				padding-left: 35px;
				padding-right: 35px;
				flex-basis: 20%;
				@media (max-width: 1023px) {
					padding-left: 5px;
					padding-right: 5px;
					flex-basis: 25%;
				}
				.button {
					color: #000;
					font-size: 15px;
					border: 1px solid #000;
					min-width: 169px;
					display: block;
					text-align: center;
					padding: 6px 0;
					@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
					&.-active {
						border-color: #FEB733;
						background-color: #FEB733;
					}
					&:hover {
						@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
						border-color: #FEB733;
						background-color: #FEB733;
					}
				}
			}
		}
	}
	.complectation-wrapper {
		display: none;
		margin: 25px 0;
		@media (max-width: 767px) {
			display: block;
		}
		.complectation-open {
			border: 1px solid #000;
			font-size: 17px;
			font-weight: 600;
			padding: 7px 13px 7px;
			position: relative;
			&::after {
				content: "\f108";
				font-family: "Flaticon";
				font-size: 1rem;
				position: absolute;
				top: 55%;
				@include transformCss(translateY(-50%) rotate(180deg));
				right: 15px;
			}
		}
		.list-wrapper {
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.32), 0 0 0 1px rgba(0, 0, 0, 0.08);
			display: none;
			.list {
				.ul {
					list-style-type: none;
					padding: 0;
					.item {
						text-align: center;
						font-weight: 600;
						font-size: 17px;
						padding: 10px 0;
						@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
						&:hover {
							@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
							background-color: #EDAD01;
						}
						.link {
							color: #000;
						}
					}
					.list-active {
						background-color: #FEB733;
						color: #fff;
						.link {
							color: #fff;
						}
					}
				}
			}
		}
		.tern::after {
			@include transformCss(translateY(-50%) rotate(0deg));
		}
		.open {
			display: block !important;
		}
	}
	.link-buttons {
		text-align: center;
		margin-top: 35px;
		@media (max-width: 1023px) {
			display: flex;
		}
		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
		.link {
			border-radius: 2px;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			color: #000;
			min-width: 234px;
			text-align: center;
			display: inline-block;
			padding: 12px 20px;
			&:first-child {
				margin-right: 16px;
			}
			&.-orange {
				background-color: #ffba00;
				@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
				&:hover {
					@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
					background-color: #edad01;
				}
			}
			@media (max-width: 1023px) {
				flex-basis: 50%;
			}
			@media (max-width: 767px) {
				&:first-child {
					margin-right: 0;
					margin-bottom: 16px;
				}
				flex-basis: 100%;
			}
			i{
				margin-left: 7px;
			}
		}
	}
	.actions {
		margin-top: 181px;
		@media (max-width: 1439px) {
			margin-top: 125px;
		}
		@media (max-width: 767px) {
			margin-top: 109px;
		}
		.title {
			font-size: 29px;
			text-align: center;
			font-weight: 600;
			margin-bottom: 55px;
			@media (max-width: 767px) {
				margin-bottom: 42px;
			}
		}
		.card-wrapper {
			width: 80.7%;
			margin: 0 auto;
			margin-bottom: 170px;
			@media (max-width: 1439px) {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}
			@media (max-width: 1023px) {
				justify-content: center;
				margin-bottom: 160px;
			}
			@media (max-width: 767px) {
				margin-bottom: 60px;
			}
			.card {
				max-width: 256px;
				display: inline-block;
				margin: 0 32px 32px 32px;
				.photo {
					width: 256px;
					height: 188px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
				}
				.name {
					color: #000;
					font-size: 12px;
					margin-top: 16px;
					margin-bottom: 26px;
				}
				.link {
					text-transform: uppercase;
					color: #000;
					font-size: 12px;
					font-weight: 700;
					&::after {
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #FEB733;
						padding-left: 4px;
						font-size: 12px;
					}
				}
			}
		}
	}
	.availability {
		font-weight: 600;
		text-align: center;
		margin-top: 60px;
		@media (max-width: 1023px) {
			margin-top: 30px;
			margin-bottom: 10px;
		}
		.title {
			font-size: 30px;
			@media (max-width: 1023px) {
				font-size: 25px;
			}
			@media (max-width: 767px) {
				font-size: 20px;
			}
		}
		.phone {
			font-size: 35px;
			.link {
				color: #000;
			}
			@media (max-width: 1023px) {
				font-size: 30px;
			}
			@media (max-width: 767px) {
				font-size: 25px;
			}
		}
	}
}


