.action-list {
	font-family: "robotoregular", sans-serif;
	.title {
		font-size: 35px;
		text-align: center;
		font-weight: 600;
		margin-top: 40px;
		margin-bottom: 40px;
		@media (max-width: 727px) {
			font-size: 30px;
		}
	}
	.list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.card {
			width: 358px;
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 85px;
			padding-bottom: 45px;
			position: relative;
			@media (max-width: 1439px) {
				width: 297px;
			}
			@media (max-width: 1023px) {
				width: 334px;
			}
			@media (max-width: 767px) {
				width: 290px;
				margin-bottom: 55px;
			}
			.image {
				width: 358px;
				height: 262px;
				@media (max-width: 1439px) {
					width: 297px;
					height: 231px;
				}
				@media (max-width: 1023px) {
					width: 334px;
					height: 231px;
				}
				@media (max-width: 767px) {
					width: 290px;
					height: 212px;
				}
				.link {
					.item {
						width: 100%;
						height: 100%;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
					}
				}
			}
			.information {
				.time {
					font-size: 12px;
					font-weight: 400;
					width: fit-content;
					border-top: 2px solid #FEB733;
					margin-top: 23px;
				}
				.header {
					font-size: 20px;
					font-weight: 600;
					margin-top: 16px;
					margin-bottom: 5px;
					.item {
						color: #000;
						line-height: 1.2;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				.description {
					font-size: 15px;
				}
			}
			.more {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
				margin-top: 30px;
				position: absolute;
				bottom: 0;
				.link {
					color: #000;
					&::after {
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #FEB733;
						padding-left: 4px;
						font-size: 12px;
					}
				}
			}
		}
	}
}