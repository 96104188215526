 @font-face {
  font-family:'proxima_nova_rgbold';
  src: url('/files/resources/font/proximanova-bold-webfont.eot');
  src: url('/files/resources/font/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-bold-webfont.woff') format('woff'), url('/files/resources/font/proximanova-bold-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proxima_novabold_italic';
  src: url('/files/resources/font/proximanova-boldit-webfont.eot');
  src: url('/files/resources/font/proximanova-boldit-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-boldit-webfont.woff') format('woff'), url('/files/resources/font/proximanova-boldit-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-boldit-webfont.svg#proxima_novabold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proxima_nova_ltlight';
  src: url('/files/resources/font/proximanova-light-webfont.eot');
  src: url('/files/resources/font/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-light-webfont.woff') format('woff'), url('/files/resources/font/proximanova-light-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-light-webfont.svg#proxima_nova_ltlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proxima_novalight_italic';
  src: url('/files/resources/font/proximanova-lightit-webfont.eot');
  src: url('/files/resources/font/proximanova-lightit-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-lightit-webfont.woff') format('woff'), url('/files/resources/font/proximanova-lightit-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-lightit-webfont.svg#proxima_novalight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proxima_nova_rgregular';
  src: url('/files/resources/font/proximanova-reg-webfont.eot');
  src: url('/files/resources/font/proximanova-reg-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-reg-webfont.woff') format('woff'), url('/files/resources/font/proximanova-reg-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-reg-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'proxima_novaregular_italic';
  src: url('/files/resources/font/proximanova-regit-webfont.eot');
  src: url('/files/resources/font/proximanova-regit-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/proximanova-regit-webfont.woff') format('woff'), url('/files/resources/font/proximanova-regit-webfont.ttf') format('truetype'), url('/files/resources/font/proximanova-regit-webfont.svg#proxima_novaregular_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'novecento_widedemibold';
  src: url('/files/resources/font/novecentowide-demibold-webfont.eot');
  src: url('/files/resources/font/novecentowide-demibold-webfont.eot?#iefix') format('embedded-opentype'), url('/files/resources/font/novecentowide-demibold-webfont.woff') format('woff'), url('/files/resources/font/novecentowide-demibold-webfont.ttf') format('truetype'), url('/files/resources/font/novecentowide-demibold-webfont.svg#novecento_widedemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/**
 * For IE 6/7 only
 */
.clearfix {
    *zoom: 1;
}
html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {height: 1%; }

.spacer_5{
	clear: both;
	font-size: 1px;
	height: 5px;
	position: relative;
}
.spacer_10{
	clear: both;
	font-size: 1px;
	height: 10px;
	position: relative;
}
.spacer_15{
	clear: both;
	height: 15px;
	position: relative;
}
.spacer_20{
	clear: both;
	height: 20px;
	position: relative;
}
.spacer_25{
	clear: both;
	height: 25px;
	position: relative;
}
.spacer_30{
	clear: both;
	height: 30px;
	position: relative;
}
.spacer_40{
	clear: both;
	height: 40px;
	position: relative;
}
.spacer_50{
	clear: both;
	height: 50px;
	position: relative;
}
.spacer_60{
	clear: both;
	height: 60px;
	position: relative;
}
.spacer_70{
	clear: both;
	height: 70px;
	position: relative;
}
.spacer_80{
	clear: both;
	height: 80px;
	position: relative;
}
.spacer_90{
	clear: both;
	height: 90px;
	position: relative;
}
.spacer_100{
	clear: both;
	height: 100px;
	position: relative;
}
.clear {
	clear: both;
}
.right {
	float: right;
}
.left {
	float: left;
}
.model_content{
	position: relative;
}
.model_content .back {
	top: 13px;
	position: absolute;
	right: 13px;
	z-index: 1;
}
.model_content .template_text,
.model_content .template_text_text,
.model_content .template_title,
.model_content .template_img_full,
.model_content .template_img,
.model_content .template_text_img_right,
.model_content .template_text_bigimg,
.model_content .template_items{
	margin: 0 auto;
	width: 986px;
	font-size: 13px;
	color: #D3D3D3;
}
.model_content .scroll_top{
	display: inline-block;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 25px 0 0;
	padding: 0 15px 0 0;
	text-decoration: none;
	text-transform: uppercase;
	background: url('/files/resources/style/img_arrow_top.png') center right no-repeat;
	color: #FFBA02;
}
.model_content .template_title{
	background: url('/files/resources/style/bg_product_page_section_title.png') bottom left repeat-x;
	color: #FFF;
	display: block;
	font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
	font-size: 35px;
	height: 42px;
	line-height: 45px;
	margin: 0 auto;
	padding: 5px 0 12px 0;
}
.model_content .template_title span{
	color: #FFBA02;
}
.model_content .title{
	color: #FFBA02;
	display: block;
	font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding-bottom: 13px;
	text-transform: uppercase;
}
.model_content .template_text strong,
.model_content .template_text_img_right strong{
	color: #FFBA02;
	font-weight: normal;
}
.model_content .template_text_img_right .text{
	float: left;
	width: 425px;

}
.model_content .template_text_img_right .img{
	float: right;
	text-align: right;
	position: relative;
	width: 520px;
}
.model_content .template_text_text .left{
	float: left;
	width: 415px;
}
.model_content .template_text_text .right{
	float: right;
	width: 415px;
}

.model_content .template_items .item{
	float: left;
	margin-left: 15px;
	padding: 0;
	width: 312px;
}
.model_content .template_items .item.large{
	width: 638px;
}
.model_content .template_360{
	height: 600px;
	position: relative;
}



.model_content .template_360.wrangler_unlimited {
	background: url('/files/resources/models/wrangler_unlimited/stage_360.png') top center no-repeat;
}
.model_content  .template_360 .stage {
	background: url('/files/resources/img/bg_product_page_item_360_stage.png') bottom center no-repeat transparent;
	color: #222;
	cursor: crosshair;
	height: 550px;
	position: relative;
}
.model_content  .template_360 .stage .slide {
	display: none;
	height: 520px;
	left: 0;
	position: absolute;
	text-align: center;
	top: 25px;
	width: 990px;
}
.model_content .template_360 .prev {
	background: url('/files/resources/img/bg_product_page_item_360_slide_prev.png') top left no-repeat;
	bottom: 40px;
	height: 22px;
	left: 440px;
	position: absolute;
	width: 47px;
}
.model_content .template_360 .next {
	background: url('/files/resources/img/bg_product_page_item_360_slide_next.png') top left no-repeat;
	bottom: 40px;
	height: 22px;
	position: absolute;
	right: 440px;
	width: 47px;
}
.model_content .template_switch {
	height: 350px;
	margin: 0 auto;
	position: relative;
	width: 966px;
}
.model_content .template_switch .switch_buttons {
	padding-top: 20px;
}
.model_content  .template_switch .switch_images {
	position: absolute;
	right: 0;
	top: 0;
}
.model_content  .template_switch .switch_images .img {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
}


/*cherokee 2014*/
.model_white_style.model_content  {
width: 990px;
margin: 0 auto;
background: #fff;
border-radius: 6px;
}
.cherokee .model_precontent {
	min-height: 575px;
}
.model_white_style.model_content  .sections {
	background: transparent;
	margin: 0 auto;
	position: relative;
	width: 990px;
}
.model_white_style.model_content .template_display{
	color: #000;
	position: relative;
}
.model_white_style.model_content  .template_display .scrollnav{
	position: absolute;
	right: -35px;
	top: 45px;
	width: 24px;
}

.model_white_style.model_content  .template_display .title {
	background: url('/files/resources/models/cherokee_2014/bg_template_display_title.png') left center no-repeat transparent;
	color: #000;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 36px;
	height: 120px;
	line-height: 120px;
	padding-left: 35px;
	position: relative;
	padding-bottom: 0;

}
.model_white_style.model_content  .template_display  h1{
		color: #000;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 36px;
	height: 120px;
	line-height: 120px;
	padding-left: 35px;
	position: relative;
	padding-bottom: 0;
	font-weight: normal;

}

.model_white_style.model_content  .display {
	/* height: 454px; */
	position: relative;
}
.model_white_style.model_content   .display .gallery {
	height: 100%;
	position: relative;
	width: 100%;
}
.model_white_style.model_content   .display .gallery .outer {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.model_white_style.model_content   .display .gallery .outer .inner {
	height: 100%;
	position: relative;
	width: 50000px;
}
.model_white_style.model_content    .display .gallery .outer .inner .item {
	background: none;
	float: left;
	padding: 0;
	width: 990px;
	height: 100%;
	height: 454px;
}
.model_white_style.model_content  .item {
	background: url('/files/resources/style/loading.gif') center center no-repeat #000 !important;
}
.model_white_style.model_content   .display .gallery .dots {
	bottom: 0;
	height: 30px;
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
}
.model_white_style.model_content  .gallery .dots span {
	background: url('/files/resources/style/bg_template_display_dots.png') top center no-repeat transparent;
	display: inline-block;
	height: 17px;
	width: 17px;
}
 .model_white_style.model_content  .gallery .dots span.sel {
	background-position: bottom center;
}
.model_white_style.model_content   .display .gallery .prev {
	background: url('/files/resources/models/cherokee_2014/bg_template_display_arrows.png') left center no-repeat transparent;
	height: 65px;
	display: none;
	left: 10px;
	margin-top: -32px;
	position: absolute;
	top: 50%;
	width: 36px;
}
.model_white_style.model_content   .display .gallery .next {
	background: url('/files/resources/models/cherokee_2014/bg_template_display_arrows.png') right center no-repeat transparent;
	height: 65px;
	margin-top: -32px;
	position: absolute;
	right: 10px;
	top: 50%;
	width: 36px;
}
.model_white_style.model_content   .text {
	display: block;
	line-height: normal;
	margin: 0 auto;
	position: relative;
	width: 935px;
}
.model_white_style.model_content   .text .subtitle {
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 28px;
	padding-bottom: 10px;
	/* text-align: center !important; */
}
.model_white_style.model_content   .text {
	font-size: 14px !important;
	line-height: 21px !important;
}
.model_white_style.model_content  .scrollnav {
	position: absolute;
	right: -35px;
	top: 45px;
	width: 24px;
}
.model_white_style.model_content  .scrollnav a {
	background: url('/files/resources/style/bg_template_2014_scrollnav.png') bottom center no-repeat transparent;
	color: #FFF;
	display: block;
	font-size: 11px;
	height: 19px;
	/* line-height: 27px; */
	margin-bottom: 5px;
	padding-top: 5px;
	text-align: center;
	text-decoration: none;
}
.model_white_style.model_content .scrollnav a.sel {
	background-position: top center;
	color: #242424;
	font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	height: 20px;
	/* line-height: 24px; */
	margin-bottom: 2px;
	padding-top: 4px;
}
.model_white_style.model_content  .accordion li{
	margin: 0;
	padding: 0;
	width: 196px;
	position: absolute;
}
.model_white_style.model_content   .accordion li:before{
	display: none;
}

.model_white_style.model_content  .models {
	height: 425px;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.model_white_style.model_content .models .photos {
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 1;
}
.model_white_style.model_content  .models .photos .item {
	height: 100%;
	left: 0;
	padding: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.model_white_style.model_content .models .choose {
	background: url('/files/resources/style/bg_template_display_model_choose.png') top center no-repeat transparent;
	height: 400px;
	left: 10px;
	padding-left: 30px;
	position: absolute;
	top: 360px;
	z-index: 10;
	width: 300px;
}
.model_white_style.model_content  .choose .label {
	color: #FFF;
	display: block;
	font-size: 14px;
	height: 45px;
	line-height: 45px;
	margin-top: 20px;
}
.model_white_style.model_content  img{
	display: block;
}
.model_white_style.model_content .template_float {
	position: relative;
}
.model_white_style.model_content .template_float .text {
	margin: 0 auto;
	position: absolute;
}

.model_white_style.model_content .scroll_top {
	background: url('/files/resources/style/img_arrow_top.png') right center no-repeat;
	display: inline-block;
	font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 30px 0 0 0;
	padding: 0 15px 0 12px;
	color: #ffba02;
	text-decoration: none;
}

.cherokee footer{
	margin-top: 0;
}




/*Compass*/
.model_compass .full_tile,
.model_compass .content_left,
.model_compass .content_right {
  margin-bottom: 45px;
  position: relative;
}
.model_compass .content_tile {
  padding: 0px;
}
.model_compass #ext_controls {
	background-color: #444444;
	color: #8a8a8a;
	top: 25%;
	right: 0;
	display: none;
	height: 55px;
	position: fixed;
	z-index: 25;
	width: 55px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-moz-border-radius-topleft: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	padding-top: 15px;
}
a.top {
	/*background: url("/ru/shared/images/vehicles/arrow-top.gif") no-repeat scroll 100% 50% transparent;*/
	color: #fff;
	display: block;
	font-size: 10px;
	padding: 0 12px 0 0;
	position: absolute;
	right: 40px;
	text-transform: uppercase;
	text-decoration: none;
	top: 10px;
}
#ext_controls a.top {
	color: #8f8f8f;
	background-image: none;
	display: block !important;
	margin: 0 auto !important;
	padding: 0;
	position: relative;
	right: 0;
	top: 0;
	text-align: center;
}
#ext_controls a.top span {
	background: url("/files/resources/models/compass/sprite_buttons.png") no-repeat scroll -130px -30px transparent;
	width: 28px;
	height: 16px;
	display: block;
	margin: 0 auto;
	margin-bottom: 10px;
}
.model_compass .full_tile {
  padding: 0px;
}
.model_compass .dark_tile .content_left {
  margin-bottom: 20px;
}
.model_description .header h1{
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 5px;
	font-weight: normal;
	line-height: 14px;
}

.model_compass .copy {
  float:left;
  width:225px;
}
.model_compass .copy .inner {
  padding:0;
}
.model_compass .copy p {
  margin:0 0 15px;
}
.model_compass h4{
	margin: 0;
}
.model_compass .content_left .media,
.model_compass .content_right .media {
  width: 520px;
  float: left;
  position: relative;
  /*for absolute positioning wrapper*/

}
.model_compass .content_left .media img {
  float: right;
}


.model_compass .content_page{
	width: 950px;
	margin: 0 auto;
	float: none;
}
.model_compass #vehicle_wrap{
	width: 950px;
	background: #1A1A1A;
	color: #666666;
	margin: -120px auto 0;
	position: relative;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.model_compass .section {
	margin-bottom: 50px;
}

.model_compass .section h2 {
	font: normal normal 52px/52px 'Proxima N W01 Light', Arial, Helvetica, sans-serif;
	color: #CCC;
	text-align: center;
	padding: 0;
	background: none;
	text-transform: none !important;
	margin: 0;
	margin: 0 0 20px;
}

.model_compass .centeredtext {
	font-size: 14px;
	margin: 0 auto;
	width: 840px;
}
.model_compass .section h3 {
	text-align: left;
	font: normal normal 38px/45px 'Proxima N W01 Light', Arial, sans-serif;
	font-size: 38px !important;
	width: auto;
	color: #CCC;
	margin: 0;
	margin-bottom: 10px;
}
.model_compass .section .accordion-wrap h3 {
margin-left: 55px;
}
.model_compass ul.four_col{
	margin: 0 auto 50px;
	padding: 0;
	list-style: none;
	position: relative;
}
.model_compass ul.four_col li {
	display: block;
	overflow: hidden;
	padding: 0;
	float: left;
	width: 236px;
	height: 365px;
	position: absolute;
}
.model_compass ul.four_col li:before{
	display: none;
}
.model_compass .accordion{
width: 950px;
}
.model_compass .accordion img {
	margin-bottom: 10px;

}
.model_compass #vehicle_wrap{
color: #CCC !important;
}
.model_compass .section .media {
	float: right;
}
.model_compass .content_right .media {
	width: 520px;
	float: right;
	position: relative;
}
.model_compass .content_right .copy {
	width: 317px;
	float: left;
	margin-top: 50px;
	position: relative;
	z-index: 15;
	margin-right: 50px;
margin-left: 60px;
}
.model_compass .content_left {
	position: relative;
}
.model_compass  .content_left .copy {
	margin-left: 50px;
	margin-top: 50px;
}

.model_compass  .columns {
	width: 950px;
	text-align: center;
	margin: 0;
	padding: 0;
	float: none;
}
.model_compass  .columns li:before{
	display: none;
}
.model_compass  .columns li {
	width: 261px;
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: top;
	zoom: 1;
	float: none;
	margin: 0 15px;
}
.model_compass   .section .columns h4 {
	margin-bottom: 10px;
}
.model_compass  .section h4 {
	font: normal normal 22px/28px 'Proxima N W01 Light', Arial, sans-serif;
	color: #CCC;
	text-align: center;
}
.model_compass  .columns p {
	text-align: left;
	padding: 5px 15px;
}
.model_compass  .wheels {
	margin-top: 40px;
	margin-bottom: 45px;
	position: relative;
}
.model_compass  .content_right .media.wheel {
width: 433px;
margin-left: 55px;
}
.model_compass  .wheels .copy {
	width: 375px;
	margin-right: 0px;
}
.model_compass  .wheels .wheel-list {
	margin-top: 70px;
}
.model_compass  .wheel_toggle {
	opacity: .4;
	filter: alpha(opacity=40);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	display: block;
	/* width: 185px; */
	float: left;
	margin-bottom: 5px;
	min-height: 65px;
	height: auto !important;
	height: 65px;
}
.model_compass  .wheel_toggle.active {
	opacity: 1;
	filter: alpha(opacity=100);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.model_compass  .wheel_toggle span {
	float: left;
	width: 55px;
	height: 47px;
	margin-right: 10px;
	display: inline;
}
.model_compass  .wheel_toggle span.title {
	float: left;
	font-size: 12px !important;
	line-height: 14px;
	margin-top: 8px;
	width: 120px;
	display: block;
	margin-right: 0;
	height: auto;
	color: #666;
	text-transform: none;
}
.model_compass  .engines {
	margin-top: -50px;
	position: relative;
	z-index: 10;
}
.model_compass  .engines .ecodiesel {
	height: 430px;
}
.model_compass  #vehicle-capability .engines .engine-copy {
	width: 325px;
	margin-top: 80px !important;
	margin-right: 63px;
	margin-left: 50px;
	float: left;
	position: relative;
	z-index: 15;

}
.model_compass .content_left {
	position: relative;
}
.model_compass .engines .media {
	padding: 0 40px;
	width: 870px;
	position: absolute;
	top: -188px;
	left: 0;
}
.model_compass  #vehicle-safety-security .media {
margin-top: -120px;
}
.model_compass .stage-wrapper {
position: relative;
margin-bottom: 45px;
}
.model_compass h2.section_heading {
	font: 52px 'Proxima N W01 Light', Arial, Helvetica, sans-serif;
	color: #CCC;
	text-align: center;
	padding: 0;
	background: none;
	text-transform: none !important;
}
.model_compass  #vehicle-models {
	margin-top: 30px;
}
.model_compass #vehicle_container {
background: url(/files/resources/models/compass/colourizr-background.jpg) no-repeat 0 0;
left: 0px;
position: relative;
top: 0px;
width: 950px;
}
.model_compass #vehicle_progress {
	position: absolute;
	top: 170px;
	background-color: #000000;
	border-radius: 5px 5px 5px 5px;
	display: none;
	left: 370px;
	margin: 0 auto;
	opacity: 0.8;
	opacity: .8;
	filter: alpha(opacity=80);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	padding: 10px;
	text-transform: uppercase;
	width: 90px;
	z-index: 4;
	cursor: pointer;
}
.model_compass #vehicle_progress p {
	float: right;
	color: #ccc;
	padding-top: 3px;
	font-size: 13px;
}
#colorizer_swatches {
min-height: 415px;
height: 415px;
}
.modelizer .veh_info {
z-index: 2;
position: absolute;
top: 35px;
left: 35px;
}
#veh_name {
margin-bottom: 10px;
text-transform: capitalize;
}
.drivetrain_wrapper {
background: url("/files/resources/models/compass/drivetrain_toggle.png") no-repeat scroll 0 0 transparent;
display: block;
width: 203px;
height: 71px;
margin-bottom: 25px;
}
.drivetrain_toggle {
float: left;
display: block;
width: 52px;
height: 68px;
}
.drivetrain_toggle div, .drivetrain_toggle a {
font-family: 'Proxima N W01 Bold', Arial, Helvetica, sans-serif;
font-size: 16px;
color: #666666;
text-align: center;
width: 52px;
display: block;
padding-bottom: 4px;
padding-top: 8px;
cursor: pointer;
opacity: .4;
}
.drivetrain_toggle div.active, .drivetrain_toggle a.active {
opacity: 1;
}
#vehicle_msrp {
float: left;
padding-left: 15px;
padding-top: 15px;
width: 125px;
}
#vehicle_msrp span.copy {
display: block;
font-size: 9px;
margin-bottom: -5px;
text-transform: uppercase;
}
#vehicle_msrp span.MSRP {
font-size: 30px;
float: left;
}
#ModelMSRP_get .active-MSRP {
display: block;
}
#ModelFeatures, .with-award-tiles #ModelFeatures {
cursor: default;
float: none;
margin-top: 0px;
position: relative;
z-index: 99;
font-weight: bold;
}
#ModelFeatures .features {
width: 370px;
padding-left: 20px;
}
#ModelFeatures ul.features li:first-child {
font-weight: bold;
list-style: none;
color: #CCC !important;
}
#ModelFeatures ul li {
color: #CCC !important;
line-height: 14px;
}
#ModelFeatures .features {
display: none;
}
#ModelFeatures .active-features {
display: block;
left: 0px;
bottom: auto;
top: 0px;
padding: 0px;
position: relative;
background: none;
}
#ModelFeatures li {
color: #444444;
font-size: 11px;
margin: 0 15px 0px 0px;
list-style: disc outside none;
padding: 0;
}
#ModelFeatures li:before{
	display:none;
}
#colorizer_swatches img {
position: absolute;
top: -6px;
right: -1px;
z-index: 1;
}









#colorizer_picker {
  background: none repeat scroll 0 0 transparent !important;
  float: left;
  margin-left: 7px;
  margin-top: 8px;
  min-width: 120px;
  padding: 0;
  position: absolute;
  top: -104px;
  right: 22px;
}
#colorizer_picker .swatch {
  background: url("/files/resources/models/compass/sprite_colorizer.png") no-repeat scroll 0 0 transparent;
  cursor: pointer;
  float: left;
  height: 44px;
  margin: 10px 3px 0 0;
  position: relative;
  width: 38px;
  padding: 0;
}
#colorizer_picker .swatch span {
  /*left: -36px;*/

  width: 165px;
  display: none;
  /*position: absolute;
    top: -16px;*/

  color: #ccc;
  font-weight: bold;
}
#colorizer_picker .swatch.selected span {
  display: block;
}

#colorizer_picker a {
  display: block;
  color: transparent;
  width: 38px;
  height: 44px;
  cursor: pointer;
  text-indent: 9999px;
  margin-bottom: 5px;
}
#colorizer_picker .selected a,
#colorizer_picker .hovered a {
  background: url('../../../../ru/compass_2014/images/sprite_colorizer.png') no-repeat -485px 0;
  display: block;
  outline: none;
}
#color_name {
  display: none!important;
}

#colorizer_picker{
	overflow: hidden !important;
	height: 75px;
    padding-right: 100px;
}
#colorizer_picker .swatch{
	padding: 0;
}
#colorizer_picker .swatch:before{
	display: none;
}
#colorizer_picker .swatch {
  background-image: url(/files/resources/models/compass/colourizr-swatches.png); }

/* line 655, ../sass/main.scss */
#colorizer_picker .black {
  background-position: -2px 0; }
/* line 658, ../sass/main.scss */
#colorizer_picker .true-blue-pearl {
  background-position: -45px 0; }
/* line 661, ../sass/main.scss */
#colorizer_picker .deep-cherry-red {
  background-position: -87px 0; }
/* line 664, ../sass/main.scss */
#colorizer_picker .bright-white {
  background-position: -132px 0; }
/* line 667, ../sass/main.scss */
#colorizer_picker .bright-silver {
  background-position: -175px 0; }
/* line 670, ../sass/main.scss */
#colorizer_picker .winter-chill {
  background-position: -218px 0; }
/* line 673, ../sass/main.scss */
#colorizer_picker .rugged-brown {
  background-position: -263px 0; }
/* line 676, ../sass/main.scss */
#colorizer_picker .mineral-gray {
  background-position: -308px 0; }
/* line 679, ../sass/main.scss */
#colorizer_picker .billet-silver {
  background-position: -354px 0; }
/* line 682, ../sass/main.scss */
#colorizer_picker .granite {
  background-position: -400px 0; }
/* line 685, ../sass/main.scss */
#colorizer_picker .maximum-steel {
  background-position: -446px 0; }


  #modelizer_controls {
  background: none repeat scroll 0 0 transparent;
  position: relative;
  float: none;
  margin-left: 0px;
  margin-top: 0px;
  padding-left: 0px;
}
.with-award-tiles #modelizer_controls {
  height: 164px;
}
#controls_wrapper {
  background-color: transparent;
  position: relative;
  /*width: 185px;*/

  height: 164px;
  /*left: -134px;*/

  /*top: -38px;*/

  z-index: 3;
  color: #fff;
  padding: 0px 0 3px 4px;
  text-transform: uppercase;
  filter: none;
}
.v1 #modelizer_picker,
.v3 #modelizer_picker {
  width: 950px;
  text-align: center;
}
#modelizer_picker li {
  padding-top: 10px;
  position: relative;
}
.v3 #modelizer_picker li a {
  height: 106px;
  margin-bottom: 9px;
}
#modelizer_picker li:before{
	display: none;
}
#modelizer_picker li {
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: top;
	zoom: 1;
	float: none;
}
 #modelizer_picker li > a {
	height: 120px;
	background-image: url("/files/resources/models/compass/sprite-model-picker.jpg");
	display: block;
	height: 120px;
	margin: 0 13px;
	width: 205px;
}
#modelizer_picker li > a#limited {
	background-position: -420px 0;
}
#modelizer_controls .model h3 {
	height: 24px;
	line-height: 24px;
	position: relative;
	z-index: 2;
	font-family: 'Proxima N W01 Light', Arial, Helvetica, sans-serif;
	font-size: 24px !important;
	text-align: center;
	padding: 0;
	background: none;
	text-transform: none !important;
	margin-bottom: 10px;
	color: #CCC !important;
	font-weight: normal;

}

#overlay_360{
	cursor: url('/files/resources/models/compass/grab.png'), pointer
}

.model_compass .int360 {
	width: 950px;
	height: 130px;
	display: block;
	box-shadow: 0 5px 5px -2px #888888 inset;
	margin-bottom: 40px;
	position: relative;
	background-image: url("/files/resources/models/compass/int_360.jpg");
}
.model_compass .ir.int_cta {
	background: url("/files/resources/models/compass/sprite_buttons.png") no-repeat scroll 0px -70px transparent;
	width: 266px;
	height: 48px;
	margin: 0 auto;
	display: block;
	position: relative;
	top: 45px;
	text-indent: -9000px;
	cursor: pointer;

}
.model_compass #interior360_disc {
	position: absolute;
	bottom: 6px;
	left: 5px;
	text-shadow: #000 0px 0px 5px;
	color: #CCC;
}
.model_compass .close-btn {
display: none;
}
.interior_360_spritespin.loaded:after{
	display: none;
}
.interior_360_spritespin{
	cursor: url('/files/resources/models/compass/grab.png'), pointer
}
.interior_360_spritespin:after{
	content: '';
	display: block;
	background: url('/files/resources/style/loading.gif');
	width: 31px;
	height: 31px;
	position: absolute;
	top: 214px;
	left: 459px;

}
.model_compass #ext_controls{
	display: none;
}
.model_compass .model_precontent {
min-height: 830px;
}



/*grand cherokee*/
.sub-title {
	letter-spacing: -1px!important;
	font: 35px'proxima_nova_ltlight', sans-serif!important;
	color: #b2b2b2!important;
	line-height: 38px!important;
}
.sub-title-2 {
letter-spacing: -1px!important;
font: 30px'proxima_nova_ltlight', sans-serif!important;
color: #b2b2b2!important;
line-height: 33px!important;
}
.grand_cherokee h1, .grand_cherokee h2, .grand_cherokee h3, .grand_cherokee h4, .grand_cherokee ul, .grand_cherokee ul li, .grand_cherokee p{
	margin: 0;
	padding: 0;
}
.grand_cherokee h3 {
	line-height: 33px;
}
.grand_cherokee ul, .grand_cherokee ul li{
	font-size: 13px;
	line-height: 20px;
	/*list-style-type: disc;*/
}
.grand-ch-bl .txt-img-bls .left-tib p {
	text-align:left;
}
.grand-ch-bl .txt-img-bls .left-tib h3 {
	text-align:left;
}
.model_header h1{
	font-family: 'DinProBold';
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 36px;
}
.grand_cherokee li:before{
	display: none;

}
#content-2 {
  background-color: #191818;
  height: 457px;
  margin-left: 17px;
  position: relative;
  width: 950px;
}
#content-2 ul li {
  color: #b2b2b2;
  margin-bottom: 2px;
}
#content-2 p {
  color: #b2b2b2;
  line-height: 21px;
}
#content-2-info-text, #content-2-info-text2, #content-2-info-text3, #content-2-info-text4 {
  left: 34px;
  position: absolute;
  top: 0;
}
#content-2 .content-2-asterisco {
	font-size: 8px;
	margin-top: 80px;
	width: 210px;
	line-height: 13px !important;
}
#content-2-info-text h2, #content-2-info-text2 h2, #content-2-info-text3 h2, #content-2-info-text4 h2 {
  color: #b2b2b2;
  display: block;
  font: 40px'proxima_nova_ltlight', sans-serif;
  height: 88px;
  letter-spacing: -2px;
  line-height: 33px;
  padding-top: 20px;
}

.sprite-motori-sprite-04{
	background: url(/files/resources/models/cherokee_2015/motori-sprite-4.png) 0 -34px no-repeat;
	padding-left: 38px !important;
}

a:hover.sprite-motori-sprite-04, #content-2-menu-select .active.sprite-motori-sprite-04{
	background-position: 0 3px;
	color: #b2b2b2 !important;
	height: 34px !important;
}

.content-2-asterisco {
  font-size: 8px;
  margin-top: 80px;
}
#content-2-diesel {
  position: absolute;
  right: 127px;
  top: 10px;
}
#content-2-menu-select {
  background: url(/files/resources/models/cherokee_2015/bg-content-2-menu-select.png) 0 0 no-repeat;
  bottom: 100px;
  height: 60px;
  padding-left: 16px;
  position: absolute;
  right: 50px;
  width: 600px;
}
#content-2-menu-select a {
  color: #474747;
  display: block;
  float: left;
  font-size: 10px;
  font-weight: 700;
  margin: 9px 0 0 0px;
  padding: 8px 0 0 55px;
  text-decoration: none;
  width: 99px;
  height: 44px;
}
a.sprite-motori-sprite-01, a.sprite-motori-sprite-02, a.sprite-motori-sprite-03, a.sprite-motori-sprite-01-seiqui, a.sprite-motori-sprite-02-seiqui, a.sprite-motori-sprite-03-seiqui {
  background: url('/files/resources/models/cherokee_2015/motori-sprite.png') no-repeat
}
a.sprite-motori-sprite-01 {
  background-position: 0 0;
  height: 42px;
  width: 47px;
}
a:hover.sprite-motori-sprite-01, .sprite-motori-sprite-01.active {
  background-position: 0 -92px !important;
  color: #b2b2b2 !important;
  height: 42px;
  width: 47px;
}
a.sprite-motori-sprite-02 {
  background-position: 0 -184px;
  height: 44px;
  width: 44px;
}
a:hover.sprite-motori-sprite-02, .sprite-motori-sprite-02.active {
  background-position: 0 -278px;
  color: #b2b2b2 !important;
  height: 44px;
  width: 44px;
}
a.sprite-motori-sprite-03 {
  background-position: 0 -372px;
  height: 44px;
  width: 43px;
}
a:hover.sprite-motori-sprite-03, .sprite-motori-sprite-03.active {
  background-position: 0 -466px;
  color: #b2b2b2 !important;
  height: 44px;
  width: 43px;
}

#content-5 {
  background-color: #191818;
  height: 2100px !important;
  width:950px;
  margin-left:17px;
  position:relative;
  background: #191818 url(/files/resources/models/cherokee_2015/bg-content-5.jpg) no-repeat 0px 95px;
}
#content-5-title-bottom h2 {
  color: #b2b2b2;
  display: block;
  font: 55px'proxima_nova_ltlight', sans-serif;
  height: 88px;
  letter-spacing: -2px;
  line-height: 33px;
  padding-top: 20px;
  text-align: center;
}
#content-5 h2 {
  color: #b2b2b2;
  font: 40px'proxima_nova_ltlight', sans-serif;
  letter-spacing: -2px;
  margin-bottom: 10px;
}
#content-5 p {
  color: #b2b2b2;
  line-height: 21px;
  word-spacing:-1px;
}
#content5-info-text-1 {
  left: 30px;
  position: absolute;
  top: 535px;
  width: 765px;
}

#content5-block-list {
  position: absolute;
  left: 53px;
  top: 700px
}
.content5-block-list-item {
  float: left;
  margin-right: 33px;
  width: 260px;
}
.content5-block-list-item h2 {
  font: 30px'proxima_nova_ltlight', sans-serif !important;
  text-align: center;
}
.content5-block-list-item p {
  margin-top: 28px;
}
#content5-info-gomme {
  position: absolute;
  bottom: 550px !important;
  height: 387px;
}
#gomma {
  float: left;
  width: 470px;
}
#gomma-select {
  float: left;
  margin: 43px 0 0 45px;
  width: 385px;
}
#gomma-select a {
  color: #474747;
  display: block;
  margin-top: 9px;
  padding: 8px 0 0 65px;
  text-decoration: none;
}
#gomma-select p {
  margin-bottom: 30px;
}
a.sprite-gomme-1, a.sprite-gomme-2, a.sprite-gomme-3 {
  background: url('/files/resources/models/cherokee_2015/gomme-sprite.png') no-repeat;
}
a.sprite-gomme-1 {
  background-position: 0 0;
  height: 47px;
}
a:hover.sprite-gomme-1, .sprite-gomme-1.active {
  background-position: 0 -97px !important;
  color: #b2b2b2 !important;
  height: 47px;
}
a.sprite-gomme-2 {
  background-position: 0 -194px;
  height: 47px;
}
a:hover.sprite-gomme-2, .sprite-gomme-2.active {
  background-position: 0 -291px !important;
  color: #b2b2b2 !important;
  height: 47px;
}
a.sprite-gomme-3 {
  background-position: 0 -388px;
  height: 47px;
}
a:hover.sprite-gomme-3, .sprite-gomme-3.active {
  background-position: 0 -485px !important;
  color: #b2b2b2 !important;
  height: 47px;
}
#content5-info-fascination {
  bottom: 15px;
  left: 40px;
  position: absolute;
}
#info-fascination {
  float: left;
  width: 365px;
}
#fascination-jeep {
  float: left;
  width: 545px;
}
#banner-360 {
  bottom: 0;
  position: absolute;
}

#TDGChSRT{
	position: absolute;
	right: 0;
	top: 422px;
}

#content-10 {
  width:950px;
  height:1120px;
  margin-left:17px;
  padding-bottom: 100px;
  position:relative;
  color:#ffffff;
  /* background: #191818 url(../img/bg-content-10-14.png) no-repeat 0px 110px; */
  background: #191818 url(../img/bg-content-10.jpg) no-repeat 0px 110px;
}
#content-10 #content-10-info-box {
  width:468px;
  position:absolute;
  top:220px;
  line-height:19px;
  left:30px;
}
#content-10 #content-10-info-box h2 {
  color: #ffffff;
  display: block;
  font: 30px'proxima_nova_ltlight', sans-serif;
  height: 88px;
  letter-spacing: -2px;
  line-height: 33px;
  padding-top: 20px;
}
#content-10-info-box2 {
  width:900px;
  left: 30px;
  position: relative;
  top: 510px;
  padding-right:20px;
}
#content-10-info-box2 p {
  font-size: 13px;
  line-height: 20px;
}
#content-10-info-box2 ul {
  margin-top: 20px;
  list-style-type: none;
}
#content-10-info-box2 ul li {
  font-size: 13px;
  line-height: 20px;
}
#content-10-info-box3 {
  left: 30px;
  top:545px;
  position: relative;
}
.content-10-image {
  width:282px;
  height:285px;
  margin-right:21px;
}
#cars {
  position: absolute;
  right:0px;
  top:190px;
  bottom: 326px;
  width: 749px;
}
.car-item {
  height: 200px;
  width: 230px;
  cursor: pointer;
  opacity: 0.5;
}
.car-item.active {
	opacity: 1;
}
#content-9 #content-9-slect-model2{

}
#colors #r0_c0, #colors #r0_c1, #colors #r0_c2, #colors #r0_c3, #colors #r0_c4, #colors #r0_c5, #colors #r0_c6, #colors #r0_c7, #colors #r0_c8, #colors #r0_c9, #colors #r0_c10 {
  background: url('/files/resources/models/cherokee_2016/sprite_colorizer_2016.png') no-repeat scroll 0 0 transparent;
}
#colors .swatch {
  background: url('/files/resources/models/cherokee_2016/sprite_colorizer_2016.png') no-repeat scroll 0 0 transparent;
  cursor: pointer;
  float: left;
  height: 31px;
  margin: 10px 3px 0 0;
  position: relative;
  width: 31px;
}
#colors a {
  display: block;
  color: transparent;
  width: 38px;
  height: 44px;
  cursor: pointer;
  /*margin-bottom: 5px;*/
}
#colors .selected a, #colors .hovered a {
  background: url('/files/resources/models/cherokee_2015/sprite_colorizer.png') no-repeat -476px 3px;
  display: block;
  outline: none;
}
#colors .swatch.selected span {
  display: block;
}
#colors .swatch span {
  width: 165px;
  display: none;
  color: #666;
  font-weight: bold;
}
#colors .swatch.active span {
display: block;
}
#content-2-info-text h2 {
width: 400px;
}

 #content-14{
  width:950px;
  height:350px;
  margin-left:17px;
  padding-bottom:100px;
  background: #191818 url(/en/gc_2014/img/audio.jpg) 0px 20px no-repeat;
}

#content-14-info-text{
  width:380px;
  height:308px;
  margin-left:490px;
  color:#b2b2b2;
  padding-right:20px;
  line-height:30px;
  font: 14px/19px Arial, sans-serif;
}
#content-14-info-text-title .content-14-info-h2 {

  height:88px;
  color:#b2b2b2;
  padding-top: 135px;
  font:33px'proxima_nova_ltlight', sans-serif;
  letter-spacing:-1px;
  line-height:33px;
  width:300px;
}
#content-1-info-text-title_x .content-1-info-h2_x_desc{

  height:88px;
  color:#F00;
  padding-top: 150px;
  font:40px'proxima_nova_ltlight', sans-serif;
  letter-spacing:-1px;
  line-height:30px;
  width:30px;
}

 #content-15{
  width:950px;
  margin-left:17px;
  background: #191818 url(/en/gc_2014/img/bg_car.jpg) 0px 0px no-repeat;
}

#content-15-info-text{
	width: 410px;
	height: 369px;
	margin-left: 495px;
	color: #b2b2b2;
	padding-right: 0px;
	line-height: 30px;
	font: 14px/19px Arial, sans-serif;
}
#content-15-info-text-title .content-15-info-h2 {
	color: #b2b2b2;
	font: 33px'proxima_nova_ltlight', sans-serif;
	letter-spacing: -1px;
	line-height: 33px;
	width: 450px;
}

#header-bottom {
	width: 950px;
	height: 97px;
	padding-top: 34px;
	text-align: center;
	margin-top: 5px;
	margin-left: 17px;
	background: url(/files/resources/models/cherokee_2015/bg-header-bottom.jpg) no-repeat center;
}
#header-bottom h2 {
text-align: center;
}
#header-bottom h2 {
font: 40px'proxima_novaregular_italic', sans-serif;
font-style: italic;
color: #666666;
margin-bottom: 15px;
}
#sottotitolo-header {
font-size: 18px;
color: #666666;
}
#content-7 {
width: 950px;
height: 1290px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818 url(/files/resources/models/cherokee_2015/img_interior_overland2.jpg) no-repeat center 120px !important;
}

#content-7-title h2 {
	height: 70px;
	padding-top: 30px;
	font: 55px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	text-align: center;
	letter-spacing: -1px;
}
#content-7 #content-7-info-box {
	width: 90%!important;
	height: 200px;
	position: absolute;
	top: 640px;
	line-height: 19px;
	left: 34px;
}
#content-7-info-box-title h2 {
	height: 50px;
	font: 40px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	letter-spacing: -1px;
}
#content7-interior-accordion {
	width: 950px;
	height: 450px;
	position: absolute;
	top: 815px;
	overflow: hidden;
}
#content-7 #content-7-info-box-title-2 h2 {
	height: 70px;
	font: 40px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	letter-spacing: -1px;
	position: absolute;
	top: 4px;
	left: 0px;
}
#content7-interior-accordion h2 {
	height: 50px;
	font: 40px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	letter-spacing: -1px;
	margin: 0 0 20px 30px;
}
.grand_cherokee ul.accordionInt {
	list-style: none;
	position: relative;
	top: 63px;
	font-family: Cambria, serif;
	font-size: 16px;
	font-style: italic;
	line-height: 1.5em;
	max-width: 100% !important;
}
.grand_cherokee ul.accordionInt li {
	float: left;
	width: 188px;
	height: 380px !important;
	display: block;
	border-right: 2px solid #191818;
	border-bottom: 2px solid #191818;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
	overflow: hidden;
	cursor: pointer;
	-moz-box-shadow: 1px 3px 15px #191818;
	-webkit-box-shadow: 1px 3px 15px #191818;
	box-shadow: 1px 3px 15px #191818;
	padding: 0;
}
.grand_cherokee ul.accordionInt li:before{
	display: none;
}
.content-7-foto-description {
	width: 100%;
	height: 80px;
	display: block;
	text-align: center;
	top: 315px;
	letter-spacing: -1px;
	background: #191818;
	position: relative;
	font: 18px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
}
ul.accordionInt li.int1 {
	background: url(/files/resources/models/cherokee_2015/acc_int_05.jpg) 0px 0px no-repeat;
}
ul.accordionInt li.int2 {
background: url(/files/resources/models/cherokee_2015/grand_cherokee_audio.jpg) 0px 0px no-repeat;
}
ul.accordionInt li.int3 {
background: url(/files/resources/models/cherokee_2015/acc_int_03.jpg) 0px 0px no-repeat;
}
ul.accordionInt li.int4 {
background: url(/files/resources/models/cherokee_2015/grand_cherokee_sunroof.jpg) 0px 0px no-repeat;
}
ul.accordionInt li.int5 {
background: url(/files/resources/models/cherokee_2015/acc_int_01.jpg) 0px 0px no-repeat;
}

#content-8 {
width: 950px;
height: 895px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818 url(/files/resources/models/cherokee_2015/bg_content8.jpg) no-repeat 0px 0px;
}
#content-8 #content-8-info-box1 {
width: 390px;
position: absolute;
top: 11px;
line-height: 19px;
right: 23px;
}

#content-8 #content-8-info-box1 h2, #content-8 #content-8-info-box2 h2 {
color: #b2b2b2;
display: block;
font: 40px'proxima_nova_ltlight', sans-serif;
height: 88px;
letter-spacing: -2px;
line-height: 33px;
padding-top: 20px;
}
#content-8 #content-8-info-box1 h2, #content-8 #content-8-info-box2 h2 {
height: 135px;
}
#custom_cluster {
height: 81px;
left: 22px;
position: absolute;
top: 350px;
z-index: 10;
}
#custom_cluster a, #custom_cluster a .small {
float: left;
height: 81px;
margin-right: 19px;
position: relative;
width: 135px;
/* z-index: 10; */
}
#custom_cluster a, #custom_cluster a .small {
float: left;
height: 81px;
margin-right: 19px;
position: relative;
width: 135px;
/* z-index: 10; */
}
#custom_cluster a .big {
height: 81px;
display: none;
left: 0;
position: absolute;
top: 0;
width: 135px;
z-index: 100;
}

#content-8-info-box2-m {
width: 933px;
height: 450px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818 url(/files/resources/models/cherokee_2015/bg_content_8bis.jpg) no-repeat top right;
top: 470px;
}
#content-8 #content-8-info-box2 {
width: 400px;
position: absolute;
top: 499px;
line-height: 19px;
left: 30px;
}
#content-8 #content-8-info-box2 {
top: 0px;
}
#content-8 #content-8-info-box2 h2 {
height: 135px;
}
#content-1 {
	width: 950px;
	height: 457px;
	margin-left: 17px;
	padding-bottom: 100px;
	background: #191818 url(/files/resources/models/cherokee_2015/smooth-and-efficient-eight-speed-transmission.png) 0px 145px no-repeat;
}
#content-1-title h2 {
	height: 88px;
	display: block;
	padding-top: 20px;
	text-align: center;
	font: 55px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
}
#content-1-info-text{
	width: 525px;
	height: 308px;
	margin-right: 15px;
	color: #b2b2b2;
	padding-right: 20px;
	line-height: 30px;
	font: 14px/19px Arial, sans-serif;
}
#content-1-info-text h2{
	color: #b2b2b2;
	font: 14px/19px Arial, sans-serif;
	font-weight: bold;
}
#content-3 {
width: 950px;
height: 513px;
margin-left: 17px;
background: #191818 url(/files/resources/models/cherokee_2015/bg-content-3.png) no-repeat 294px 0px;
}
#content-3-info-text {
width: 555px;
height: 308px;
padding-left: 34px;
margin-right: 15px;
color: #b2b2b2;
line-height: 30px;
font: 14px/19px Arial, sans-serif;
}
#content-3-info-text-title h2 {
height: 68px;
display: block;
padding-top: 20px;
text-align: left;
letter-spacing: -1px;
font: 40px'proxima_nova_ltlight', sans-serif;
color: #b2b2b2;
}
#content-6 {
width: 950px;
height: 447px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818;
}
#content-6 {
height: 560px;
}
#content-6-title h2 {
height: 93px;
font: 55px'proxima_nova_ltlight', sans-serif;
color: #b2b2b2;
letter-spacing: -1px;
text-align: center;
}
#content-6-foto-box-general {
width: 950px;
height: 350px;
overflow: hidden;
position: relative;
background: #191818;
}
.grand_cherokee ul.accordion {
list-style: none;
position: relative;
top: 0px;
font-family: Cambria, serif;
font-size: 16px;
font-style: italic;
line-height: 1.5em;
}
.grand_cherokee ul.accordion li {
	float: right;
	width: 236px;
	height: 350px;
	display: block;
	border-bottom: 2px solid #fff;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;

overflow: hidden;
cursor: pointer;
-moz-box-shadow: 1px 3px 15px #555;
-webkit-box-shadow: 1px 3px 15px #555;
box-shadow: 1px 3px 15px #191818;
}
.grand_cherokee ul.accordion.kwicks-processed li{
	position: absolute;
}

.grand_cherokee .content-6-foto-description {
width: 100%;
position: absolute;
text-align: center;
bottom: 0px;
height: 30px;
font: 25px'proxima_nova_ltlight', sans-serif;
color: #b2b2b2;
}
.grand_cherokee ul.accordion li.bg1 {
background: #191818 url(/files/resources/models/cherokee_2015/rock.jpg) -45px 0px no-repeat;
border: none;
}
.grand_cherokee ul.accordion li.bg2 {
background: #191818 url(/files/resources/models/cherokee_2015/sand.jpg) -47px 0px no-repeat;
}
.grand_cherokee ul.accordion li.bg3 {
background: #191818 url(/files/resources/models/cherokee_2015/mud.jpg) -30px 0px no-repeat;
}
.grand_cherokee ul.accordion li.bg4 {
background: #191818 url(/files/resources/models/cherokee_2015/snow.jpg) 0px 0px no-repeat;
}
#content-4 {
width: 950px;
height: 1110px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818 url(/files/resources/models/cherokee_2015/bg-content-4.jpg) no-repeat 0px 0px;
}
#content-4 #content-4-title-bottom h2 {
height: 65px;
width: 950px;
position: absolute;
top: -85px;
left: 0px;
text-align: center;
letter-spacing: -1px;
font: 55px'proxima_nova_ltlight', sans-serif;
color: #b2b2b2;
}


#content-4 #content-4-box-info-2 {
width: 950px;
height: 250px;
position: absolute;
top: 840px;
left: 0px;
}
#content-4 #content-4-box-info-2 {
top: 350px;
}
#content-4 #content-4-box-info-3 {
width: 950px;
height: 250px;
position: absolute;
top: 1100px;
left: 0px;
}
#content-4 #content-4-box-info-3 {
top: 550px;
}
.content-4-image-box-info-1-2-3 {
width: 262px;
height: 188px;
position: absolute;
top: 0px;
left: 34px;
}
.content-4-text-box {
width: 620px;
height: 230px;
word-spacing: -1px;
padding-right: 10px;
line-height: 19px;
}
#content-14 {
width: 950px;
height: 350px;
margin-left: 17px;
padding-bottom: 100px;
background: #191818 url(/files/resources/models/cherokee_2015/audio.jpg) 0px 20px no-repeat;
}
#content-14-info-text {
width: 380px;
height: 308px;
margin-left: 490px;
color: #b2b2b2;
padding-right: 20px;
line-height: 30px;
font: 14px/19px Arial, sans-serif;
}
#content-14-info-text-title .content-14-info-h2 {
height: 88px;
color: #b2b2b2;
padding-top: 135px;
font: 33px'proxima_nova_ltlight', sans-serif;
letter-spacing: -1px;
line-height: 33px;
width: 300px;
}
#content-4 #content-4-box-info-1 {

}

#content-4 #content-4-box-info-1 {
width: 950px;
height: 250px;
position: absolute;
top: 741px;
left: 0px;
}

#content-15 {
width: 950px;
margin-left: 17px;
background: #191818 url(/files/resources/models/cherokee_2015/bg_car.jpg) 0px 0px no-repeat;
}
#content-9 {
width: 950px;
height: 770px;
margin-left: 17px;
position: relative;
color: #b2b2b2;
background: #191818 url(/files/resources/models/cherokee_2015/bg-content-9.png) no-repeat 1px 128px;
}
#content-9-title h2 {
	height: 70px;
	padding-top: 30px;
	font: 55px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	text-align: center;
	letter-spacing: -1px;
}
#content-9 #content-9-info-text {
width: 490px;
height: 200px;
left: 34px;
top: 150px;
position: absolute;
color: #b2b2b2;
line-height: 19px;
z-index: 100;
}
#content-9-info-box-title h2 {
height: 57px;
font: 30px'proxima_nova_ltlight', sans-serif;
color: #b2b2b2;
letter-spacing: -1px;
}
#content-9 #colors {
width: 370px;
height: 42px;
position: absolute;
top: 430px;
left: 34px;
}
/*#content-9 ul {
	margin-top: 15px;
}*/
#colors .swatch {
	background: url('/files/resources/models/cherokee_2016/sprite_colorizer_2016crop.png') no-repeat scroll 0 0 transparent;
	cursor: pointer;
	float: left;
	height: 31px;
	margin: 10px 3px 0 0;
	position: relative;
	width: 31px;
}
#colors .active a{
	background: url('/files/resources/models/cherokee_2016/bg_colors_sel.png') no-repeat 11px 27px;
	display: block;
	outline: none;
}
#content-9 #content-9-slect-model2 {
	width: 970px;
	height: 170px;
	position: absolute;
	top: 560px;
	left: 0px;
}
.grand_cherokee .content-9-machine-auto-title {
	width: 177px;
	height: 50px;
	margin-top: 20px;
	padding-left: 60px;
	font: 40px'proxima_nova_ltlight', sans-serif;
	color: #b2b2b2;
	letter-spacing: -1px;
}
#content-9 #arrow-model-selector {
width: 950px;
height: 15px;
position: absolute;
top: 525px;
left: 0px;
background: url(/files/resources/models/cherokee_2015/arrow-model-selector.png) no-repeat -800px 0px;
display: none;
}

#content-10 {
width: 950px;
height: 1120px;
margin-left: 17px;
padding-bottom: 100px;
position: relative;
color: #ffffff;
/* background: #191818 url(../img/bg-content-10-14.png) no-repeat 0px 110px; */
background: #191818 url(/files/resources/models/cherokee_2015/bg-content-10.jpg) no-repeat 0px 110px;
}
#content-10 #content-10-info-box {
width: 468px;
position: absolute;
top: 220px;
line-height: 19px;
left: 30px;
}
#content-10 #content-10-info-box h2 {
color: #ffffff;
display: block;
font: 30px'proxima_nova_ltlight', sans-serif;
height: 88px;
letter-spacing: -2px;
line-height: 33px;
padding-top: 20px;
}
#content-10 #content-10-info-box h2 {
margin-bottom: 60px;
}

#content-10-info-box2 {
width: 900px;
left: 30px;
position: relative;
top: 510px;
padding-right: 20px;
}
#content-10-info-box2 p {
font-size: 13px;
line-height: 20px;
}
#content-10-info-box2 ul {
margin-top: 20px;
list-style-type: none;
}
#content-10-info-box2 ul li {
font-size: 13px;
line-height: 20px;
}
/*#content-9 ul {
	margin-top: 15px;
}*/
#content-9 #content-9-info-text p {
margin-top: 20px;
display: block;
}
#content-9 ul li {
	margin-bottom: 3px;
	font-size: 10px;
	line-height: 14px;
	width: 430px;
}
.grand_cherokee .page_overlay{
	background: #000;
}
.grand_cherokee .model_content{
	background: none;
}
.grand_cherokee .content_page.wide_content{
	width: 984px;
	float: none;
	margin: 0 auto;
}
.grand_cherokee footer{
	margin-top: 0;
}
.grand_cherokee .model_precontent{
	min-height: 535px;
}




/*Grand cherokee srt*/
#opencontent {
	display: block;
	font-size: 13px;
	/* max-width: 1564px; */
	margin: 0 auto;
	/* padding: 0 18px; */
	position: relative;
}
.gc_2014_srt {
	position: relative;
}
.gc_2014_srt .head {
	/*background: url('/files/resources/models/grand_cherokee_srt/head_new.jpg') top center no-repeat #000;
	background: url('/files/resources/srt_ru.jpg') top center no-repeat #000;*/
	/*background: url('/files/resources/srt8_5_17_1920h545.jpg') top center no-repeat #000;*/
	background: url('/files/resources/srt_shourum_ru_2017.jpg') top center no-repeat #000;
	height: 567px;
	position: relative;
}

.gc_2014_srt .block {
	background: url('/files/resources/models/grand_cherokee_srt/bg_2.png') top center no-repeat #000;
	height: 650px;
	position: relative;
}
.gc_2014_srt .block.top {
	background: url('/files/resources/models/grand_cherokee_srt/bg_1.png') top center no-repeat #000;
	height: auto;
}
.gc_2014_srt .page_title {
	color: #FFF;
	display: block;
	font-size: 17px;
	line-height: 24px;
	padding: 60px 0 50px 0;
	position: relative;
	text-align: center;
}
.gc_2014_srt .slidegallery {
	margin: 0 auto;
	position: relative;
	width: 864px;
}
#srt_gallery_page_1 {
	display: block;
}
.gc_2014_srt .slidegallery .gallery .page {
	bottom: 87px;
	display: none;
	height: 435px;
	left: 124px;
	position: absolute;
	width: 630px;
}
.gc_2014_srt .slidegallery .nav {
	bottom: 47px;
	height: 8px;
	left: 389px;
	position: absolute;
	width: 200px;
}
.gc_2014_srt .slidegallery .nav a:hover, .gc_2014_srt .slidegallery .nav a.sel, .gc_2014_srt .slidegallery .nav a.sel:hover {
background: #FFF;
}
.gc_2014_srt .slidegallery .nav a {
background: #AC1A2F;
float: left;
height: 8px;
margin-right: 6px;
width: 46px;
}
.gc_2014_srt .slidegallery .open {
	background: url('/files/resources/models/grand_cherokee_srt/open.jpg') top center no-repeat #000;
	display: block;
	height: 144px;
}
.gc_2014_srt .block {
	background: url('/files/resources/models/grand_cherokee_srt/bg_2.png') top center no-repeat #000;
	height: 650px;
	position: relative;
}

.gc_2014_srt .slidegallery .gallery .page a {
	background: #000;
	float: left;
	height: 134px;
	margin: 0 9px 11px 0;
	overflow: hidden;
	width: 201px;
}
.gc_2014_srt .slidegallery .gallery {
	background: #111111;
	display: none;
	height: 610px;
	position: relative;
}
.gc_2014_srt .slidegallery .gallery .page a img {
	height: auto;
	width: 100%;
}

.gc_2014_srt .block .content {
	margin: 0 auto;
	position: relative;
	width: 980px;
	background: transparent;
}
.gc_2014_srt .block .content .title {
position: absolute;
top: 140px;
left: -63px;
line-height: normal;
text-align: center;
text-transform: uppercase;
width: 1100px;
z-index: 100;
}
.gc_2014_srt .block .content .title .red {
color: #AC1A2F;
display: inline-block;
font-family: 'DINPro-MediumItalic', Arial, Helvetica, sans-serif;
font-size: 36px;
margin: 0;
padding: 0 5px 0 0;
vertical-align: middle;
}
.gc_2014_srt .block .content .title .white {
color: #FFF;
display: inline-block;
font-family: 'DINPro-MediumItalic', Arial, Helvetica, sans-serif;
font-size: 68px;
padding-left: 5px;
vertical-align: middle;
}

.gc_2014_srt .block .content .text {
color: #FFF;
line-height: 18px;
position: absolute;
z-index: 50;
}

#gc_2014_box_1 .text {
height: 280px;
left: 50px;
top: 260px;
width: 425px;
}
#gc_2014_box_1 .image {
top: 200px;
right: 20px;
}
.gc_2014_srt .block .content .image {
position: absolute;
}

.gc_2014_srt .block {
background: url('/files/resources/models/grand_cherokee_srt/bg_2.png') top center no-repeat #000;
height: 650px;
position: relative;
}

.gc_2014_srt .block .content {
margin: 0 auto;
position: relative;
width: 980px;
background: transparent;
}
.gc_2014_srt .block .content .subtitle {
color: #818181;
font-family: 'DinProBold', Arial, Helvetica, sans-serif;
font-size: 18px;
font-weight: normal;
letter-spacing: 2px;
position: absolute;
text-align: center;
text-transform: uppercase;
top: 70px;
width: 980px;
}
#gc_2014_box_2 .text {
height: 280px;
right: 18px;
top: 260px;
width: 425px;
}
.gc_2014_srt .block .content .text strong {
display: block;
font-family: 'DinProBold', Arial, Helvetica, sans-serif;
font-size: 18px;
font-weight: normal;
padding-bottom: 10px;
}

#gc_2014_box_2 .image {
left: 0px;
top: 200px;
}
#gc_2014_box_3 .text {
height: 280px;
right: 20px;
top: 260px;
width: 425px;
}
#gc_2014_box_3 .image {
left: -30px;
top: 200px;
}
#gc_2014_box_4 {
height: 800px;
}
#gc_2014_box_4 .text {
height: 280px;
left: 30px;
top: 260px;
width: 900px;
}
#gc_2014_box_4 .image {
left: -90px;
top: 290px;
}
#gc_2014_box_5 {
height: 1045px;
}
#gc_2014_box_5 .text {
height: 280px;
left: 30px;
top: 810px;
width: 900px;
}
#gc_2014_box_5 .image {
left: -25px;
top: 240px;
}
#gc_2014_box_6 {
height: 1880px;
}
.gc_2014_srt .block .content .title_2 {
text-align: center;
padding-top: 20px;
z-index: 101;
}
.gc_2014_srt .block .content .title_2 .white {
color: #FFF;
display: inline;
font-family: 'DINPro-MediumItalic', Arial, Helvetica, sans-serif;
font-size: 36px;
padding-left: 5px;
vertical-align: middle;
}
.gc_2014_srt .block .content .title_2 .red {
color: #AC1A2F;
display: inline;
font-family: 'DINPro-MediumItalic', Arial, Helvetica, sans-serif;
font-size: 36px;
margin: 0;
padding: 0 5px 0 0;
vertical-align: middle;
text-align: center;
}

#gc_2014_box_6 .image {
left: -200px;
top: 110px;
}
#gc_2014_box_6 .text_1 {
color: #FFF;
height: 280px;
left: 30px;
position: absolute;
z-index: 100;
top: 200px;
width: 260px;
}
#gc_2014_box_6 .text_2 {
color: #FFF;
height: 150px;
left: 440px;
position: absolute;
z-index: 100;
top: 830px;
width: 450px;
line-height: 20px;
}
#gc_2014_box_6 .text_3 {
color: #FFF;
height: 200px;
left: 30px;
position: absolute;
z-index: 100;
top: 1100px;
width: 390px;
line-height: 20px;
}
#gc_2014_box_6 .text_4 {
color: #FFF;
height: 200px;
left: 440px;
position: absolute;
z-index: 100;
top: 1475px;
width: 390px;
line-height: 20px;
}
#opencontent .frame_side {
height: 100%;
position: absolute;
top: 0;
width: 18px;
z-index: 101;
}
#frame_side_right {
right: 0;
}
#frame_side_left {
left: 0;
}

.slogan-gc {
    position: absolute;
    right: 0;
    top: 80px;
    color: #000;
    font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
    max-width: 437px;
    text-align: right;
    font-weight: bold;
    font-size: 27px;
    line-height: 29px;
}
