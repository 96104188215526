.fotorama{
	.fotorama__fullscreen-icon {
		background-size: 18px;
		top: 0;
		right: 0;
		background: #FEB733 url('/files/images/007-zoom.png') no-repeat center !important;
	}
	.fotorama__arr {
		background-color: #FEB733;
		background-position: center;
		position: absolute;
		width: 30px;
		height: 45px;
		top: 50%;
	}
	.fotorama__arr--prev {
		left: 0;
		&::before{
			content: "\f109";
			font-family: "Flaticon";
			font-style: normal;
			font-weight: bold;
			font-variant: normal;
			line-height: 1;
			color: #000;
			padding-left: 5px;
			font-size: 24px;
			position: relative;
			display: block;
			top: 25%;
			@include transformCss(scaleX(-1));
		}
	}
	.fotorama__arr--next {
		right: 0;
		&::after{
			content: "\f109";
			font-family: "Flaticon";
			font-style: normal;
			font-weight: bold;
			font-variant: normal;
			line-height: 1;
			color: #000;
			padding-left: 5px;
			font-size: 24px;
			position: relative;
			top: 25%;
		}
	}
}