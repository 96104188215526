.paginator {
	text-align: center;
	display: flex;
	justify-content: center;
	margin-bottom: 170px;
	.link {
		background-color: #ECECEC;
		color: #000;
		font-size: 20px;
		font-weight: 600;
		margin-right: 12px;
		padding: 5px 13.4px;
		height: 38px;
		width: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
		&:hover{
			@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
			background-color: #EDAD01;
		}
		&.active {
			background-color: #FECF77;
			color: #fff;
		}
		&.to_right {
			margin-right: 0;
			padding: 0;
			&::after{
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #FECF77;
				padding-left: 5px;
				font-size: 24px;
				position: relative;
				top: 35%;
				left: -1px;
				@include transformCss(translateY(-50%));
			}
		}
		&.to_left {
			padding: 0;
			&::before {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #FECF77;
				padding-left: 5px;
				font-size: 24px;
				position: relative;
				display: block;
				top: 35%;
				left: 1px;
				@include transformCss(scaleX(-1) translateY(-50%));
			}
		}
	}
}