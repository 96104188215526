.accordion{
	height: 400px;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 990px;
}
.accordion li{
	margin: 0;
	padding: 0;
	display: block;
    overflow: hidden;
    float: left;
    width: 198px;
    height: 400px;
}
.accordion li span{
	color: #414141;
	display: block;
	font-family: 'NovecentoBook', Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: normal;
	padding: 25px 0 0 0;
	text-align: center;
	
}

.display .late{
	bottom: -50px;
	color: #414141;
	font-family: 'NovecentoBook', Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: normal;
	position: absolute;	
	right: 45px;
}
