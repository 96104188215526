.cars-card {
	font-family: "robotoregular", sans-serif;
	.main-title {
		font-size: 34px;
		font-weight: 600;
		text-align: center;
		margin-top: 54px;
		margin-bottom: 49px;
		line-height: 1.3;
		@media (max-width: 767px) {
			margin-top: 38px;
			margin-bottom: 35px;
			text-align: left;
		}

	}
	.information-block {
		display: flex;
		justify-content: space-between;
		margin-bottom: 109px;
		@media (max-width: 1439px) {
			margin-bottom: 47px;
		}
		@media (max-width: 1023px) {
			margin-bottom: 33px;
		}
		@media (max-width: 767px) {
			display: block;
			margin-bottom: 47px;
		}
		.gallery-block {
			width: 78%;
			@media (max-width: 1439px) {
				width: 75%;
			}
			@media (max-width: 1023px) {
				width: 67%;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
			.real-photos{
				width: 100%;
				height: 585px;
				@media (max-width: 1439px) {
					height: 481px;
				}
				@media (max-width: 1023px) {
					height: 390px;
				}
				@media (max-width: 767px) {
					height: 193px;
				}
			}
			.standart-image {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				.photo {
					@media (max-width: 1023px) {
						width: 100%;
					}
				}
			}
			.fotorama:not(.fotorama--fullscreen) {
				.fotorama__nav--thumbs {
					max-width: 100%;
					margin-top: -176px;
					display: block!important;
					@media (max-width: 1439px){
						margin-top: -235px;
						display: block!important;
					}
					@media (max-width: 1279px){
						margin-top: -117px;
					}
					@media (max-width: 1023px){
						margin-top: -5px;
						display: block!important;
					}
					@media (max-width: 767px){
						display: none!important;
					}
				}
			}
			.fotorama__nav:before,
			.fotorama__stage:before,
			.fotorama__nav:after,
			.fotorama__stage:after {
				background: none !important;
			}
			.fotorama {
				.fotorama__wrap--slide .fotorama__stage__frame{
					width: 100%;
					height: 432px;
					@media (max-width: 1439px) {
						height: 365px;
					}
					@media (max-width: 767px){
						height: 200px;
					}
				}
				.fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__stage .fotorama__img{
					width: 100% !important;
					height: 100% !important;
					object-fit: cover;
				}
				.fotorama__nav--thumbs .fotorama__nav__frame{
					width: 220px!important;
					height: 122px!important;
					margin-right: 4px;
					@media (max-width: 1439px) {
						width: 168.5px!important;
						height: 98px!important;
						margin-right: 0;
					}
					@media (max-width: 1023px){
						width: 110px!important;
						height: 63px !important;
						margin-right: -4px;
					}
					&.fotorama__active{
						border: 1px solid #000;
						padding: 0;
						margin-right: 15px!important;
					}
				}
				.fotorama__error .fotorama__img, .fotorama__loaded .fotorama__img{
					width: 100%!important;
					height: 100%!important;
					left: 0!important;
					top: 0!important;
					object-fit: cover;
				}
				.fotorama__thumb-border {
					display: none;
				}
				.fotorama__fullscreen-icon {
					background-size: 18px;
					top: 0;
					right: 0;
					background: #FEB733 url('/files/images/007-zoom.png') no-repeat center !important;
				}
				.fotorama__arr {
					background-color: #FEB733;
					background-position: center;
					position: absolute;
					width: 30px;
					height: 45px;
					top: 35%;
					@media (max-width: 1439px){
						top: 30%;
					}
					@media (max-width: 1279px){
						top: 40%;
					}
					@media (max-width: 1023px){
						top: 50%;
					}
				}
				.fotorama__arr--prev {
					left: 0;
					&::before{
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #000;
						padding-left: 5px;
						font-size: 24px;
						position: relative;
						display: block;
						top: 25%;
						@include transformCss(scaleX(-1));
					}
				}
				.fotorama__arr--next {
					right: 0;
					&::after{
						content: "\f109";
						font-family: "Flaticon";
						font-style: normal;
						font-weight: bold;
						font-variant: normal;
						line-height: 1;
						color: #000;
						padding-left: 5px;
						font-size: 24px;
						position: relative;
						top: 25%;
					}
				}
			}
			&.-full{
				@media (max-width: 1439px){
					width: 70%;
				}
				@media (max-width: 1279px){
					margin-right: 85px;
					width: 40%;
				}
				@media (max-width: 1023px){
					margin-right: 0;
					width: 67%;
				}
				@media (max-width: 767px){
					width: 100%;
				}
				.standart-image{
					@media (max-width: 1279px){
						height: auto;
						width: auto;
					}
					.photo{
						@media (max-width: 1279px){
							width: 100%;
						}
					}
				}
			}
		}
		.info-block {
			@media (max-width: 767px) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
			.instock-price {
				font-size: 34px;
				margin-bottom: 31px;
				@media (max-width: 767px) {
					margin-bottom: 27px;
					font-size: 29px;
				}
				.total-price {
					font-weight: 600;
				}
				.old-price {
					width: fit-content;
					position: relative;
					color: #868686;
					margin-top: -6px;
					&::after {
						content: "";
						width: 100%;
						height: 1px;
						background-color: #868686;
						position: absolute;
						left: 0;
						top: 50%;
						@include transformCss(translate(0, -50%));
					}
				}
			}
			.cars-prepay {
				margin-bottom: 40px;
				@media (max-width: 1439px) {
					margin-bottom: 105px;
				}
				@media (max-width: 767px) {
					margin-bottom: 5px;
				}
				.link {
					cursor: pointer;
					border-radius: 2px;
					font-weight: bold;
					font-size: 14px;
					display: inline-block;
					text-transform: uppercase;
					padding: 12px 0;
					min-width: 194px;
					width: fit-content;
					text-align: center;
					color: #000;
					background-color: #ffba00;
					margin-bottom: 29px;
					@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
					&:hover {
						@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
						background-color: #edad01;
					}
					i{
						margin-left: 10px;
					}
					@media (max-width: 767px) {
						width: 100%;
					}
				}
				.description {
					font-size: 14px;
					max-width: 195px;
					@media (max-width: 767px) {
						font-size: 16px;
						max-width: initial;
						margin-bottom: 20px;
					}
				}
			}
			.order-wrapper {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 10px;
				@media (max-width: 1023px) {
					display: none;
				}
				@media (max-width: 767px) {
					display: block;
					font-size: 20px;
					order: -2;
					margin-bottom: 4px;
				}
				.order-number {
					font-weight: normal;
					@media (max-width: 767px) {
						display: flex;
						margin: 25px 0;
					}
					.value {
						font-weight: 600;
					}
				}
			}
			.characteristic {
				font-size: 17px;
				margin-bottom: 29px;
				@media (max-width: 1439px) {
					margin-bottom: 0;
				}
				@media (max-width: 1023px) {
					display: none;
				}
				@media (max-width: 767px) {
					order: -1;
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 30px;
					.volume, .engine {
						display: flex;
					}
				}
				.separator {
					display: none;
					@media (max-width: 767px) {
						display: block;
					}
				}
			}
			.button-wrapper {
				@media (max-width: 1439px) {
					&.xl-visible {
						display: none;
						@media (max-width: 767px) {
							display: block;
						}
					}
				}
				.link {
					&:last-child {
						margin-bottom: 0;
						@media (max-width: 767px) {
							margin-bottom: 0;
						}
					}
					margin-bottom: 19px;
					@media (max-width: 767px) {
						margin-bottom: 26px;
					}
					.item {
						text-transform: uppercase;
						border-radius: 2px;
						font-weight: bold;
						font-size: 14px;
						display: block;
						padding: 12px 0;
						min-width: 194px;
						width: fit-content;
						text-align: center;
						color: #000;
						background-color: #ffba00;
						@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
						&:hover {
							@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
							background-color: #edad01;
						}
						@media (max-width: 767px) {
							width: 100%;
						}
						i{
							margin-left: 10px;
						}
					}
				}
			}
			&.-full-screen{
				display: flex;
				width: 50%;
				height: fit-content;
				@media (max-width: 1279px){
					width: 55%;
				}
				@media (max-width: 1023px){
					width: auto;
				}
				.column-1{
					margin-right: 110px;
					@media (max-width: 1023px){
						margin-right: 0;
					}
					.instock-price{
						margin-bottom: 25px;
						.old-price{
							display: inline-block;
							margin-bottom: 7px;
							@media (max-width: 767px){
								margin-bottom: 0;
							}
						}
					}
				}
				.column-2{
					@media (max-width: 1023px){
						display: none;
					}
					@media (max-width: 767px){
						display: block;
					}
					.characteristic{
						margin-bottom: 20px;
					}
					.button-wrapper{
						@media (max-width: 1439px){
							display: inline-block;
						}
						@media (max-width: 767px){
							width: 100%;
						}
					}
				}
			}
		}
		&.-margin{
			margin-bottom: 40px;
			@media (max-width: 1439px){
				margin-bottom: 40px;
			}
		}
	}
	.container-one-photo{
		display: none;
		@media (max-width: 1023px) {
			display: block;
		}
	}
	.characteristic-inside {
		margin-bottom: 52px;
		&.-md-visible {
			display: none;
			@media (max-width: 1023px) {
				display: block;
			}
			@media (max-width: 767px) {
				display: none;
			}
		}
		.order-wrapper {
			.order-number {
				font-size: 19px;
				font-weight: 600;
				margin-bottom: 5px;
			}
		}
		.characteristic {
			font-size: 18px;
		}
	}
	.button-inside {
		display: none;
		justify-content: space-between;
		margin-bottom: 95px;
		@media (max-width: 1023px) {
			margin-bottom: 55px;
		}
		&.-lg-visible {
			@media (max-width: 1439px) {
				display: flex;
			}
			@media (max-width: 767px) {
				display: none;
			}
		}
		.link {
			.item {
				border-radius: 2px;
				font-size: 14px;
				font-weight: 600;
				display: block;
				padding: 12px 0;
				min-width: 297px;
				min-height: 34px;
				width: fit-content;
				text-align: center;
				color: #000;
				background-color: #ffba00;
				text-transform: uppercase;
				@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
				&:hover {
					@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
					background-color: #edad01;
				}
				@media (max-width: 1023px) {
					min-width: 212px;
				}
			}
		}
	}
	.equipment-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 45px;
		@media (max-width: 1439px) {
			display: block;
		}
		.equipment-layout {
			width: 45%;
			display: inline-block;
			@media (max-width: 1439px) {
				width: 60%;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
			.title {
				font-size: 25px;
				margin-bottom: 40px;
				@media (max-width: 767px) {
					font-size: 30px;
					margin-bottom: 20px;
				}
			}
			.list {
				@media (max-width: 1439px) {
					&.first {
						margin-bottom: 60px;
					}
				}
				ul {
					li {
						font-size: 16px;
						margin-bottom: 14px;
						@media (max-width: 767px) {
							margin-bottom: 12px;
						}
					}
				}
				.list_control {
					margin-top: 50px;
					display: block;
					@media (max-width: 767px) {
						margin-top: 35px;
					}
					&.minus {
						display: none;
					}
					.btn_control_link {
						font-size: 18px;
						font-weight: 600;
						color: #000;
						cursor: pointer;
						&::after {
							content: "\f109";
							font-family: "Flaticon";
							font-style: normal;
							font-weight: bold;
							font-variant: normal;
							line-height: 1;
							color: #FEB733;
							padding-left: 14px;
							font-size: 14px;
						}
					}
				}
			}
			&.tick {
				ul {
					li {
						position: relative;
						margin-left: 30px;
						&:after{
							content: '';
							height: 20px;
							width: 20px;
							display: block;
							position: absolute;
							left: -30px;
							top: 4px;
							background-size: cover;
							margin-right: 10px;
							background-image: url(/files/images/tick.png);
						}
					}
				}
			}
		}
	}
	.details-model {
		margin-bottom: 150px;
		@media (max-width: 767px) {
			margin-bottom: 100px;
		}
		.item {
			border-radius: 2px;
			font-size: 14px;
			font-weight: 600;
			display: block;
			padding: 12px 0;
			min-width: 297px;
			min-height: 34px;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			text-transform: uppercase;
			@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
			&:hover {
				@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
				background-color: #edad01;
			}
			@media (max-width: 767px) {
				width: 100%;
				padding: 6px 0;
			}
			i{
				margin-left: 10px;
			}
		}
	}
	.instock-features {
		.title {
			font-size: 25px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 36px;
			@media (max-width: 767px) {
				font-size: 30px;
				margin-bottom: 14px;
			}
		}
		.features-list {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ccc;
			margin-bottom: 104px;
			@media (max-width: 1023px) {
				margin-bottom: 90px;
			}
			@media (max-width: 767px) {
				display: block;
				border: none;
				margin-bottom: 62px;
			}
			.custom-col {
				width: 45%;
				display: inline-block;
				@media (max-width: 767px) {
					width: 100%;
				}
				.list {
					.item {
						border-bottom: 1px solid #ccc;
						padding-top: 18px;
						padding-bottom: 18px;
						&:last-child {
							border: none;
						}
						@media (max-width: 767px) {
							border: none;
							padding-bottom: 6px;
						}
						.features-label {
							font-size: 18px;
							font-weight: 600;
							margin-bottom: 2px;
							@media (max-width: 767px) {
								font-size: 22px;
								border-bottom: 1px solid #ccc;
								padding-bottom: 4px;
								margin-bottom: 8px;
							}
						}
						.value {
							font-size: 16px;
							@media (max-width: 767px) {
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.banner {
		background-color: #F8F4F4;
		padding-top: 41px;
		padding-bottom: 70px;
		margin-bottom: 100px;
		@media (max-width: 1439px) {
			margin-bottom: 90px;
		}
		@media (max-width: 1023px) {
			margin-bottom: 65px;
			padding-bottom: 41px;
		}
		@media (max-width: 737px) {
			padding-top: 34px;
			margin-bottom: 45px;
		}
		.min-header {
			font-size: 25px;
			margin-bottom: 33px;
			@media (max-width: 767px) {
				font-size: 30px;
				line-height: 1.2;
				margin-bottom: 26px;
			}
			.value {
				font-size: 29px;
				font-weight: 600;
				margin-left: 15px;
				@media (max-width: 767px) {
					font-size: 38px;
					margin-left: 0;
					margin-top: 25px;
					display: block;
				}
			}
		}
		.desciption {
			.text {
				font-size: 16px;
				max-width: 410px;
				margin-bottom: 34px;
				@media (max-width: 767px) {
					font-size: 17px;
				}
			}
			.list-btn {
				.item {
					font-size: 15px;
					display: inline-block;
					padding: 6px 23px;
					border: 1px solid #FEB733;
					margin-right: 45px;
					margin-bottom: 20px;
					min-width: 153px;
					text-align: center;
					@media (max-width: 1023px) {
						margin-right: 29px;
						padding: 6px 13px;
					}
					@media (max-width: 767px) {
						width: 100%;
						font-size: 20px;
						padding: 12px 13px;
						margin-bottom: 17px;
					}
					&:last-child {
						margin-right: 0;
					}
					.option-title {

					}
					.value {
						&:first-letter {
							font-size: 0;
						}
					}
				}
			}
			.hint {
				margin-top: 13px;
				@media (max-width: 1023px) {
					max-width: 450px;
				}
				@media (max-width: 767px) {
					font-size: 17px;
				}
			}
		}
	}
	.similar-cars {
		.container {
			> .title {
				font-size: 25px;
				padding-bottom: 15px;
				border-bottom: 1px solid #ccc;
				margin-bottom: 36px;
				@media (max-width: 767px) {
					font-size: 23px;
				}
			}
		}
	}
	.btn-wrapper {
		text-align: center;
		margin-top: 78px;
		margin-bottom: 160px;
		@media (max-width: 1023px) {
			margin-top: 93px;
			margin-bottom: 170px;
		}
		@media (max-width: 767px) {
			margin-top: 74px;
		}

		.all-car-btn {
			border-radius: 2px;
			font-size: 14px;
			font-weight: 600;
			background-color: #ffba00;
			color: #000;
			padding: 12px 20px;
			text-transform: uppercase;
			@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
			&:hover {
				@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
				background-color: #edad01;
			}
			@media (max-width: 1023px) {
				padding: 6px 65px;
			}
			@media (max-width: 767px) {
				padding: 6px 33px;
			}
			i{
				margin-left: 10px;
			}
		}
	}
}

