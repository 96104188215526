.feature-pillar .wrapper .stripes .stripe .info-wrapper .info .copy {
	font-weight: normal;
}

.main-menu-container .copybox .hero-copy-box p {
	font-weight: normal;
}

.page-inner .textarea .wrapper-traid-in-btn {
	margin-top: 30px;
	text-align: center;
}

.commercial #page_home p {
	margin-bottom: 15px;
}

.roadside-mod {
	padding-bottom: 50px;
}

//HEADER MENU
.header-box-footer-container ul li a{
	font-family: Roboto, sans-serif;
}

.main-menu-container ul.main-nav li a.main-nav-item{
	font-family: "Roboto", sans-serif;
}

.header-box-container h2, .header-box-container div.h2, .header-box-container span.h3, .header-box-footer-container h2, .header-banner-container h2, .header-box-container h3  {
	font-size: 14px;
	font-family: Roboto, sans-serif;
}

@media only screen and (max-width: 999px) {
	.commercial #page_home {
		width: auto;
	}
	.commercial #page_home p {
		text-align: left;
	}
}