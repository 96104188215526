.benefits_box {
	margin: 55px 0;
	background: #FBFBFB;
	.benefits_container {
		margin-bottom: 20px;
		position: relative;
		.prev, .next {
			display: block;
			position: absolute;
			opacity: 0.9;
			top: 50%;
			margin-top: -22px;
			height: 45px;
			width: 65px;
			text-align: center;
			line-height: 45px;
			cursor: pointer;
			z-index: 9999;
			&.disabled {
				cursor: default;
				opacity: .5;
			}
			&:after {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				color: #000;
				font-size: 45px;
				position: relative;

			}
		}
		.prev:after {
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
			@include transformCss(rotate(180deg));
			display: block;
		}

		.prev {
			left: 0;
		}

		.next {
			right: 0;
		}

	}
	.benefits_slider {
		position: relative;
		.slider_header {
			font-size: 16px;
			padding: 0 20px 8px;
			.title {
				padding-left: 62px;
				font-size: 24px;
				font-weight: 300;
				text-transform: uppercase;
				margin: 0;
				.count_benefits {
					font-size: 163px;
					font-weight: 600;
					line-height: 163px;
					color: #FBECD3;
					position: relative;
					top: 6px;
					display: inline-block;
					vertical-align: middle;
					margin-top: -12px;
				}
				.additional {
					position: relative;
					margin-top: 64px;
					vertical-align: middle;
					display: inline-block;
					margin-left: -92px;
					font-size: 35px;
					font-weight: 100;
					text-transform: capitalize;
				}
			}
			.counter {
				font-weight: bold;
				font-size: 24px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.slider_window {
			overflow: hidden;
			position: relative;
			margin: 0 65px;
			padding-left: 60px;
			margin-right: 0;
			z-index: 0;
			.benefits_list {
				@include transitionCss(margin 0.3s);
				position: relative;
				width: 10000px;
				counter-reset: list1;
				.benefit_card {
					float: left;
					max-width: 340px;
					counter-increment: list1;
					position: relative;
					z-index: 2;
					margin-top: -175px;
					margin-right: 20px;
					@media (max-width: 1023px){
						max-width: 345px;
					}
					&::before {
						font-weight: 700;
						content: counter(list1);
						position: relative;
						color: #FDB447;
						font-size: 135px;
						top: 155px;
						z-index: -1;
						left: -50px;
					}
					&::after{
						content: "";
						background-color: #FBFBFB;;
						width: 100%;
						height: 100%;
						z-index: -1;
						position: absolute;
						left: 0;
						top: 0;
					}
					.title {
						font-size: 19px;
						font-weight: 600;
						.counter {
							font-weight: bold;
							font-size: 57px;
							color: #FDB447;
							line-height: 57px;
						}
						.additional {
							position: relative;
							@media (max-width: 767px){
								font-size: 18px;
							}
						}
					}
					.link {
						margin-top: 5px;
						display: block;
					}

					.benefit_card_wrap {
						padding: 1px 20px 20px;
						min-height: 218px;
						.content_block {
							font-size: 16px;
							font-weight: 300;
							line-height: 22px;
							margin-top: 6px;
							max-width: 275px;
							@media (max-width: 767px){
								font-size: 15px;
							}
						}
					}
				}
			}
		}
		.disabled {
			opacity: 0.3;
		}
	}
	.shadow {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 190px;
		height: 100%;
		z-index: 9;
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(241, 241, 241, 1)));
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 100%);
		background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 100%);
		background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 100%);
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#e4e4e4', GradientType=1);
	}
}

@media (max-width: 959px) {
	.benefits_box {
		.benefits_slider {
			.slider_window {
				margin: 0 0 0 65px;
				.benefits_list .benefit_card {
					max-width: 340px;
				}
			}
			.shadow {
				display: block;
			}
		}
	}
}

@media (max-width: 767px) {
	.benefits_box {
		.benefits_container {
			.prev, .next {
				height: 30px;
				width: 40px;
				line-height: 30px;
				&:after {
					font-size: 22px
				}
			}
		}
		.benefits_slider {
			.slider_header {
				padding: 0 5px 8px;
				.title {
					padding-left: 12px;
					.count_benefits {
						font-size: 120px;
						line-height: 120px;
					}
					.additional {
						margin-left: -65px;
						font-size: 30px;
						max-width: 225px;
					}
				}
			}
			.slider_window {
				margin: 0 20px 0 20px;
				padding-left: 38px;
				padding-right: 0;
				.benefits_list {
					width: 5000%!important;
					.benefit_card {
						max-width: 224px;
						margin-top: -140px;
						&::after{
							content: "";
							background-color: #FBFBFB;
							width: 90%;
							height: 100%;
							z-index: -1;
							position: absolute;
							left: 15px;
							top: 0;
						}
						&::before{
							font-weight: 700;
							content: counter(list1);
							position: relative;
							color: #FDB447;
							font-size: 100px;
							top: 115px;
							z-index: -1;
							left: -25px;
						}
						.benefit_card_wrap {
							padding: 1px 20px 30px;
						}
						.title {
							font-size: 20px;
							.counter {
								font-size: 47px;
								line-height: 47px;
							}
						}
					}
				}
			}
		}
		.shadow {
			display: none;
		}
	}
}