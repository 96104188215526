
body.black{
	background-color: #212121;
}
.old {
	background-color: #fff;
	*{
		margin: 0px;
		padding: 0px;
	}
	html *{
		/*max-height:1000000px;*/
	}
	html{
		/*-webkit-text-size-adjust: 100%;*/
		height: 100%;
	}
	/*body:after{ content:''; display:block; height:70px; }*/

	body{
		/*min-height:100%;*/
		padding:0;
		margin:0;
		height: 100%;
		min-width: 1400px;
		/*position:relative;*/
	}
	.cf:before,
	.cf:after {
		content: " ";
		display: table;
	}

	.cf:after {
		clear: both;
	}

	/**
 * For IE 6/7 only
 */
	.cf {
		*zoom: 1;
	}

	.roubl {
		font-family: als_rublregular;
		font-weight: normal;
	}
	.f_9{
		font-size: 9px;
	}
	.f_10 {
		font-size: 10px;
	}
	.f_11{
		font-size: 11px;
	}
	.f_11_b{
		font-size: 11px;
		font-weight: bold;
	}
	.f_12{
		font-size: 12px;
	}
	.f_13{
		font-size: 13px;
	}
	.f_14{
		font-size: 14px;
	}
	.f_15{
		font-size: 15px;
	}
	.f_16{
		font-size: 16px;
	}
	.f_16_b{
		font-size: 16px;
		font-weight: bold;
	}
	.f_18{
		font-size: 18px;
	}
	.f_18_b{
		font-size: 18px;
		font-weight: bold;
	}
	.f_20{
		font-size: 20px;
	}
	.f_22{
		font-size: 22px;
	}
	.f_30{
		font-size: 30px;
	}
	.f_34 {
		font-size: 34px;
	}
	.f_24{
		font-size: 24px;
	}
	.txt_upper{
		text-transform: uppercase;
	}

	.c_white{
		color: #ffffff;
	}

	.c_black{
		color: #000000;
	}
	.indent{
		text-indent: -99999px;
	}

	.transition{
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-khtml-transition: all 0.5s ease;
	}
	.transition_bg {
		transition: background 0.5s ease;
		-webkit-transition: background 0.5s ease;
		-moz-transition: background 0.5s ease;
	}

	.transition_bg_color{
		transition: background 0.5s ease, color 0.5s ease;
		-webkit-transition: background 0.5s ease, color 0.5s ease;
		-moz-transition: background 0.5s ease, color 0.5s ease;
	}

	.transition_color {
		transition: color 0.5s ease;
		-webkit-transition: color 0.5s ease;
		-moz-transition: color 0.5s ease;
	}
	.block{
		display: block;
	}
	.center_block {
		text-align: center;
	}
	.l_float{
		float: left;
	}
	.r_float{
		float: right;
	}
	.no_disc{
		list-style: none;
	}
	.i_block{
		display: inline-block;
		*display: inline;
		vertical-align: top;
		zoom: 1;
	}


	@font-face {
		font-family: 'als_rublregular';
		src: url('/files/resources/font/rouble_webfont.eot');
		src: url('/files/resources/font/rouble_webfont.eot?#iefix') format('embedded-opentype'),
		url('/files/resources/font/rouble_webfont.woff') format('woff'),
		url('/files/resources/font/rouble_webfont.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}
















	/*   Карточка авто - Преимущества
--------------------------------------------------------------------- */

	.benefits_container {
		margin-bottom: 40px;
		padding-top: 25px;
	}

	.benefits_slider{
		position: relative;
		border: 1px solid #ffba02;
	}

	.benefits_slider .slider_header{
		font-size: 16px;
		background: #ffba02;
		padding: 10px 20px;
		color: #fff;
	}
	.benefits_slider .slider_header .counter{
		font-weight: bold;
	}
	.benefits_slider .slider_window{
		overflow:hidden;
		position: relative;
		padding-left: 15px;
	}
	.benefits_slider .benefits_list{
		-webkit-transition: margin 0.3s;
		-moz-transition: margin 0.3s;
		-ms-transition: margin 0.3s;
		-o-transition: margin 0.3s;
		transition: margin 0.3s;
		position: relative;
	}
	.benefits_slider .benefits_list .benefit_card{
		padding: 20px;
		float: left;
	}
	.benefits_slider .benefits_list .benefit_card .title{
		font-family: 'PT Sans', sans-serif;
		font-size: 18px !important;
		margin-bottom: 15px;
		margin-top: 10px !important;
	}
	.benefits_slider .benefits_list .counter{
		font-family: 'PT Sans', sans-serif;
	}
	.benefits_slider .benefits_list .benefit_card .link{
		margin-top: 5px;
		display: block;
	}

	.benefits_slider .prev,
	.benefits_slider .next
	{
		display: block;
		position: absolute;
		opacity: 0.9;
		top: 50%;
		margin-top: -13px;
		height: 26px;
		width: 30px;
		text-align: center;
		line-height: 26px;
		cursor: pointer;
	}
	.benefits_slider .next:after {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	.benefits_slider .prev:after,
	.benefits_slider .next:after
	{
		content: "";
		width: 10px;
		height: 16px;
		display: block;
		position: absolute;
		top: 5px;
		left: 9px;
		background: url(/files/resources/style/sprite.png);
		background-position: -122px -349px;
	}

	.benefits_slider .prev{
		left :0;
	}
	.benefits_slider .next{
		right: 0;
	}
	.benefits_slider .disabled{
		opacity: 0.3;
	}
	.benefits_slider .shadow{
		position: absolute;
		top: 0;
		right: 0;
		display: none;
		width: 170px;
		height:  100%;
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgb(0, 0, 0) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(100%, rgb(0, 0, 0)));
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgb(0, 0, 0) 100%);
		background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgb(0, 0, 0) 100%);
		background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgb(0, 0, 0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgb(0, 0, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e4e4e4',GradientType=1 );
	}

	.benefits_slider .slider_header .title{
		font-size: 14px;
		text-transform: uppercase;
		font-family: "PT Sans", sans-serif !important;
		color: #000;
		font-weight: normal;

	}
	.benefits_slider .slider_header .counter{
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
	}
	.benefits_slider .shadow{
		display: block;
	}
	.benefits_slider .benefits_list .benefit_card {
		max-width: 365px;
	}
	.benefits_slider .benefits_list{
		width: 10000px;
	}





















	@font-face {
		font-family: 'DinProBold';
		src: url('/files/resources/font/dinpro_bold_webfont.eot');
		src: url('/files/resources/font/dinpro_bold_webfont.eot?#iefix') format('embedded-opentype'),
		url('/files/resources/font/dinpro_bold_webfont.woff') format('woff'),
		url('/files/resources/font/dinpro_bold_webfont.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'DINPro-Regular';
		src: url('/files/resources/font/DINPro_Regular.eot');
		src: url('/files/resources/font/DINPro_Regular?#iefix') format('embedded-opentype'),
		url('/files/resources/font/DINPro_Regular.woff') format('woff'),
		url('/files/resources/font/DINPro_Regular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	html {

	}
	body{
		background: #000000;
		min-width: 1100px;
	}
	.page_overlay{

	}
	.middle_content{

	}
	header{
		height: 65px;
		background: rgb(51,51,51); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(51,51,51,1) 0%, rgba(35,35,35,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(51,51,51,1)), color-stop(100%,rgba(35,35,35,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(51,51,51,1) 0%,rgba(35,35,35,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(51,51,51,1) 0%,rgba(35,35,35,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(51,51,51,1) 0%,rgba(35,35,35,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(51,51,51,1) 0%,rgba(35,35,35,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#232323',GradientType=0 ); /* IE6-9 */
		border-bottom: 1px solid #191919;
	}
	a, img{
		outline: none;
		width: 100%;
	}

	.fix_content{
		//width: 1010px;
		margin: 0px auto;
		//position: relative;
	}

	.header_jeep_logo{
		display: block;
		float: left;
		width: 101px;
		height: 65px;
		border-right: 1px solid #191919;
		position: relative;
	}
	.header_jeep_logo:after{
		position: absolute;
		content: ' ';
		top: 12px;
		left: -3px;
		width: 96px;
		height: 44px;
		background: url("/files/resources/style/sprite.png") transparent no-repeat 0 0;
	}
	.header_top_menu{
		/*width: 547px;*/
		min-height: 65px;
		float: left;
	}
	.content_menu_opacity{
		display: none;
		height: 100%;
		left: 0px;
		position: fixed;
		top: 64px;
		width: 100%;
		z-index: 103;
		background-color: #000000;
		opacity: 0;
	}
	.top_menu_list .top_menu_item {
		display: block;
		height: 65px;
		position: relative;
		float: left;
		z-index: 200;
	}
	.top_menu_list .top_menu_item .top_menu_link{
		color: #ffffff;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		text-transform: uppercase;
		text-decoration: none;
		height: 65px;
		line-height: 65px;
		border-right: 1px solid #191919;
		display: block;
		padding: 0 10px;
		text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.75);
		position: relative;
		cursor: pointer;
	}
	.top_menu_list .top_menu_item.hover .top_menu_link{
		color: #ffba02;
		background: #151515;
	}
	.top_menu_list .top_menu_item.hover .top_menu_link:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 3px;
		background: #ffba02;
	}
	header .td_button{
		color: #ffba02;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		text-transform: uppercase;
		text-decoration: none;
		height: 65px;
		line-height: 65px;
		border-right: 1px solid #191919;
		display: block;
		padding: 0 10px;
		text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.75);
		position: relative;
		cursor: pointer;
		float: left;
	}
	header .td_button:hover{

		background: #151515;
	}
	header .td_button:hover:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 3px;
		background: #ffba02;
	}
	.top_menu_list .top_menu_item:hover .top_menu_list_lvl2{
		display: block;
	}

	.top_menu_list .top_menu_list_lvl2.model_range_menu{
		width: 208px;
		background: #151515;
		padding-top: 14px;
		padding-bottom: 4px;
	}
	.top_menu_list .top_menu_list_lvl2.model_range_menu .top_menu_item_model{
		margin-bottom: 7px;
	}
	.top_menu_list .top_menu_list_lvl2.model_range_menu .top_menu_item_model .top_menu_link_model{
		color: #ffffff;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		text-transform: uppercase;
		text-decoration: none;
		display: block;
		padding: 0 10px 0 19px;
		position: relative;
		line-height: 30px;
		width: 175px;
		z-index: 200;
		text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.75);


	}
	/*text-shadow: 0px 2px 2px rgba(239, 164, 1, 0.6);*/
	.top_menu_list .top_menu_list_lvl2.model_range_menu .top_menu_item_model.hover .top_menu_link_model{
		color: #333333;
		background: #ffba02;
		text-shadow: 0px 2px 2px rgba(205, 137, 12, 1);



	}
	.top_menu_list .top_menu_list_lvl2.model_range_menu .top_menu_item_model.hover .top_menu_link_model:before{
		content: "";
		display: block;
		position: absolute;
		width: 5px;
		height: 100%;
		left: -5px;
		top: 0;
		background: #ffba02;
	}
	.top_menu_list .top_menu_list_lvl2.model_range_menu .top_menu_item_model.hover .top_menu_link_model:after{
		content: "";
		display: block;
		position: absolute;
		right: -10px;
		top: 0;
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-left-color: #ffba02;
		border-right: 0;
		border-width: 15px 0 15px 10px;
	}
	.top_menu_item_model .model_info_item .model_info_menu_top {
		height: 216px;
	}
	.top_menu_item_model .model_info_item .model_info_menu_top .model_name{
		font-family: "PT Sans", sans-serif;
		color: #ffffff;
		font-size: 26px;
		width: 270px;
		height: 85px;
		position: absolute;
		top: 20px;
		left: 22px;
	}
	.top_menu_item_model .model_info_item .model_info_menu_top .model_price{
		font-family: "PT Sans", sans-serif;
		color: #ffffff;
		font-size: 20px;
		position: absolute;
		top: 84px;
		left: 22px;
	}

	.top_menu_item_model .model_info_item .model_info_menu_top .model_image{
		width: 296px;
		height: 197px;
		position: absolute;
		top: 8px;
		left: 283px;
		background-repeat: no-repeat;
		display: block;
		background-position: center;
	}
	/*.model_info_item .model_list_buttons{
	overflow: hidden;
	height: 30px;
	border: 2px solid #0d0d0d;
	margin-left: 24px;
	float: left;

}*/
	.model_info_item .model_info_buttons_overlay {
		border: 2px #0c0c0c solid;
		border-radius: 4px;
		box-shadow: 0px 1px 2px -1px #000000;
		height: 30px;
		overflow: hidden;
		float: left;
		margin: 1px 0 0 24px;
	}
	.model_info_item .model_list_buttons{
		background-color: #2e2e2e;
		height: 30px;
		border-right: 1px #1C1C1C solid;
	}


	.model_info_item .model_list_buttons .info_button_overlay {
		float: left;
		border-left: 1px #1C1C1C solid;
		border-top: 1px #464646 solid;
	}
	.model_info_item .model_list_buttons .info_button_overlay:first-child{
		border-left: none;
		border-top-left-radius: 2px;
	}
	.model_list_buttons .button_info{
		width: 113px;
		height: 29px;
		background: #2e2e2e;
		display: block;
		color: #ffffff;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		float: left;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		line-height: 29px;
		border-left: 1px #464646 solid;
	}
	.model_info_item .model_list_buttons .info_button_overlay:first-child  .button_info{
		border-left: none;
		border-top-left-radius: 2px;
	}
	.yellow_left_arrow_button{
		width: 146px;
		height: 36px;
		display: block;
		float: left;
		margin-left: 20px;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		line-height: 36px;
		color: #333333;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		background: url("/files/resources/style/sprite.png") transparent no-repeat 0 -286px;
	}
	.model_list_buttons {
		list-style: none;
	}

	.model_list_buttons .button_info:hover{
		color: #ffba02;
	}
	.model_list_buttons .button_info.first_button{
		border-top: solid 1px #464646;
		border-right: 1px solid #1c1c1c;
	}
	.top_menu_list .model_info_item{
		width: 661px;
		height: 268px;
		position: absolute;
		top: 0;
		left: 220px;
		background: #2d2d2d;
		left: 208px;
		z-index: 199;
		display: none;
	}
	.top_menu_list .top_menu_item_model.hover  .model_info_item{
		display: block;
	}
	.top_menu_list_lvl2{
		position: absolute;
		background: #151515;
		z-index: 100;
		width: 263px;
		list-style: none;
		display: none;
		z-index: 200;
	}
	.top_menu_item_lvl2.first .top_menu_link_lvl2{
		padding-top: 22px;
	}
	.top_menu_item_lvl2.last .top_menu_link_lvl2{
		padding-bottom: 20px;
	}
	.top_menu_list_lvl2 .top_menu_link_lvl2{
		color: #ffffff;
		display: block;
		font-family: "PT Sans", sans-serif;
		font-size: 14px;
		text-decoration: none;
		text-transform: uppercase;
		padding: 11px 19px;
	}
	.top_menu_list_lvl2 .top_menu_link_lvl2:hover{
		color: #ffba02;
	}

	.header_foris_logo{
		display: block;
		float: right;
		width: 192px;
		height: 65px;

	}
	.header_configurator_link_wrapper {
		float: right;
		width: 83px;
		height: 65px;
	}
	.header_configurator_link{
		display: block;
		width: 83px;
		height: 113px;
		background: url("/files/resources/style/sprite.png") transparent no-repeat -286px -8px;
		position: relative;
		z-index: 120;
	}

	.bread_crumbs_list {
		white-space: normal;
		list-style: none;
		//width: 766px;
		//margin: 18px 0px 20px 0px;
	}
	.bread_crumbs_list .bread_crumbs_item{
		padding-right: 12px;
		position: relative;
		font-family: "robotoregular",sans-serif;
		font-size: 12px;
		z-index: 1;
		float: left;
		color: #fff;
	}

	.bread_crumbs_item .bread_crumbs_link {
		position: relative;
		color: #fff;
	}
	.bread_crumbs_item.active .bread_crumbs_link {
		color: #fff;
		font-weight: bold;
	}
	.bread_crumbs_item a.bread_crumbs_link:hover{
		color: #ffba02;
	}
	.bread_crumbs_list .bread_crumbs_item:before {
		position: absolute;
		content: '/';
		top: 0px;
		right: 2px;
		color: #afafaf;
	}
	.bread_crumbs_item.active:before{
		display: none;
	}
	.content_page, .fw_win_content{
		color: #000;
		line-height: 18px;
		font-size: 14px;
		font-family: Arial;
	}
	.content_page:before,
	.content_page:after {
		content: " ";
		display: table;
	}

	.content_page:after {
		clear: both;
	}

	/**
 * For IE 6/7 only
 */
	.content_page {
		*zoom: 1;
	}
	.content_page{
		//width: 766px;
		//float: left;
		min-height: 300px;
		position: relative;
		padding-bottom: 40px;
	}

	.content_page.wide_content{
		width: fit-content;
		margin: 0 auto;
	}

	.content_overlay .content_page .routing_list{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
		@media only screen and (max-width: 1045px){
			flex-direction: column;
		}
	}
	footer{

		font-size: 12px;
		color: #777777;
		overflow: hidden;
		border-top: 1px solid #484848;
		padding: 37px 0 41px 0;
		margin: 38px 0 0 0;

		background: rgb(35,35,35); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(35,35,35,1) 0%, rgba(20,20,20,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(35,35,35,1)), color-stop(100%,rgba(20,20,20,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(35,35,35,1) 0%,rgba(20,20,20,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(35,35,35,1) 0%,rgba(20,20,20,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(35,35,35,1) 0%,rgba(20,20,20,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(35,35,35,1) 0%,rgba(20,20,20,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232323', endColorstr='#141414',GradientType=0 ); /* IE6-9 */


	}
	.footer_menu{
		overflow: hidden;
	}
	.footer_col:first-child .footer_menu_list{
		margin-left: 0px;
	}
	.footer_menu_list{
		list-style: none;
		margin: 10px 0 0 0;
	}
	.footer_menu_link{
		text-decoration: none;
		padding: 4px 0;
		display: block;
		color: #777777;
		line-height: 13px;
	}
	.footer_menu_link.footer_social_link{
		padding-left: 17px;
		position: relative;
	}
	.footer_menu_link.footer_social_link:before{
		content: "";
		display: block;
		background: url("/files/resources/style/sprite.png") -357px -360px;
		width: 10px;
		height: 10px;
		position: absolute;
		left: 0;
		top: 6px;
	}
	.footer_menu_link.footer_social_link.fb_link:before{
		background-position:  -357px -387px;
	}
	.footer_menu_link.footer_social_link.ytb_link:before{
		background-position:  -357px -417px;
	}
	.footer_menu_link.footer_social_link.tw_link:before{
		background-position:  -357px -444px;

	}
	.footer_menu_link:hover{
		color: #ffba02;
	}
	.footer_col:first-child .footer_menu_header{
		margin-left: 0;
	}
	.footer_menu_header{
		font-weight: bold;
		font-size: 12px;
		color: #c2c2c2;
		text-transform: uppercase;
	}
	.footer_col{
		width: 231px;
		float: left;
		padding: 0 10px 20px 15px;
		position: relative;
	}
	.footer_col.first_in_row {
		padding-left: 0;
	}

	.footer_bottom{
		margin: 41px 0 0 0;
	}
	.footer_bottom_copyright{
		width: 760px;
		float: left;
		margin-right: 75px;
	}
	.footer_bottom_phone{
		width: 175px;
		float: left;
	}
	.footer_manufac_copyright{
		margin: 15px 0 10px 0;
		font-size: 11px;
		color: #595959;
	}
	.footer_col:after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 4px;
		background: url('/files/resources/style/footer_border.png');
	}
	.footer_col.last_in_row:after{
		display: none;
	}
	.sidebar{
		float: left;
		width: 224px;
	}
	h1, .style_h1, .fw_win_wrapper .fw_win_content_container .fw_win_title{
		font-size: 28px;
		line-height: 32px;
		margin: 30px 0px 30px 0px;
		padding: 0px;
		color: #000;
		font-family: "proxima_nova_rgbold","robotobold",sans-serif;
		font-weight: bold;
	}

	h2, .style_h2 {
		font-size: 22px;
		line-height: 27px;
		margin: 29px 0px 15px 0px;
		padding: 0px;
		color: #000;
		font-family: "proxima_nova_rgbold","robotobold",sans-serif;
		font-weight: bold;
	}
	h3, .style_h3{
		font-size: 18px;
		line-height: 22px;
		margin: 20px 0px 20px 0px;
		padding: 0px;
		color: #000;
		font-family: "proxima_nova_rgbold","robotobold",sans-serif;
		font-weight: bold;
	}
	h4, .style_h4{
		font-size: 16px;
		line-height: 22px;
		margin: 20px 0px;
		padding: 0px;
		color: #000;
		font-family: "proxima_nova_rgbold","robotobold",sans-serif;
		font-weight: bold;
	}
	.sub_header_line{
		display: inline-block;
		border-top: 1px solid #777777;
		padding: 6px 0 0 0;
		margin: 0px 0px 15px 0px;
		color: #777777;
	}
	.content_page p, .style_p {
		margin-bottom: 20px;
		line-height: 20px;
	}
	.content_page a, a.standart_link{
		color: #d64b00;
	}
	.content_page a:hover, a.standart_link:hover{
		color: #ffba02;
	}
	.link_download_file{
		padding-left: 15px;
		position: relative;

	}
	.link_download_file:before{
		position: absolute;
		content: ' ';
		left: 1px;
		top: 2px;
		width: 9px;
		height: 11px;
		background: url("/files/resources/style/sprite.png") no-repeat 0 -88px;
	}
	.link_download_file:hover:before{
		background-position: -10px -88px;
	}


	.target_link{
		position: relative;
		padding-right: 17px;
	}
	.target_link:before{
		position: absolute;
		content: ' ';
		right: 0;
		top: 2px;
		width: 11px;
		height: 11px;
		background: url("/files/resources/style/sprite.png") no-repeat 0 -109px;
	}
	.target_link:hover:before{
		background-position: -12px -109px;
	}



	.link_exit_point{
		position: relative;
		padding-left: 13px;
	}
	.link_exit_point:before{
		position: absolute;
		content: ' ';
		left: 0;
		top: 4px;
		width: 8px;
		height: 11px;
		background: url("/files/resources/style/sprite.png") no-repeat 0 -70px;
	}
	.link_exit_point:hover:before{
		background-position: -8px -70px;
	}
	.content_page.wide_content img{
		max-width: 1010px;
	}
	.content_page img{
		//max-width: 766px;

	}
	.content_page .news_card  img{
		height: auto!important;
	}
	.img_with_text {
		width: 335px;
		display: block;
		float: right;
		margin: 0px 0px 20px 20px;
	}
	.text_under_img {
		display: block;
		font-size: 11px;
		padding-left: 10px;
		margin-top: 18px;
		border-left: 2px solid #afafaf;
		font-style: italic;
		line-height: 15px;
	}
	.l_float_in_text {
		float: left;
		margin: 0px 20px 20px 0px;
	}

	.content_page table{
		width: 100%;
		margin-bottom: 15px;
	}
	.content_page table tr > th{
		text-align: center;
	}
	.content_page table tr th:first-child, .content_page table tr td:first-child{
		text-align: left;
		padding-left: 20px;
	}
	.content_page table tr th{
		border: 1px solid #484848;
		font-size: 12px;
		padding: 10px;

	}

	.content_page table tr td{
		font-size: 12px;
		border: 1px solid #484848;
		padding: 8px 8px 8px 10px;
		text-align: center;
		vertical-align: top;
	}

	.content_page table tr:nth-child(2n) {
		//background: #000000;
	}
	.content_page table tr:nth-child(2n+1) {
		//background: #282828;
	}
	.content_page table tr:hover{
		//background: #3d3d3d;
	}
	.content_page .footnote , .content_page .disclaimer{
		font-size: 12px;
		font-style: italic;
	}

	.content_page ul{
		list-style: none;
		margin-bottom: 20px;

	}
	.content_page ul li{
		line-height: 18px;
		position: relative;
		padding-left: 16px;
		padding-bottom: 10px;
	}

	.content_page ul li:before{
		position: absolute;
		content: ' ';
		left: 0px;
		top: 6px;
		width: 6px;
		height: 6px;
		background: url("/files/resources/style/sprite.png") no-repeat left -54px;
	}
	.content_page ul li ul {
		margin: 20px 10px 0px 0px;
	}

	.content_page ol{
		margin-bottom: 34px;
	}
	.content_page ol li{
		line-height: 18px;
		margin-left: 16px;
	}
	.content_page ol li ol{
		margin: 20px 0px;
	}
	.content_page ul.list_menu li{
		padding-left: 0;
		margin-bottom: 12px;
	}
	.content_page ul.list_menu li:before{
		display: none;
	}
	.content_page ul.list_menu li a{
		padding-left: 15px;
	}

	.content_page ul.list_menu li a:before {
		position: absolute;
		content: ' ';
		left: 2px;
		top: 5px;
		width: 6px;
		height: 10px;
		background: url("/files/resources/style/sprite.png") no-repeat left -70px;
	}
	.content_page ul.list_menu li a:hover:before {
		background: url("/files/resources/style/sprite.png") no-repeat -8px -70px;
	}
	.content_page .button_orange, .button_orange{
		color: #242424;
		display: block;
		font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
		font-size: 14px;
		height: 29px;
		line-height: 29px;
		padding: 0 0 0 11px;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		background: url('/files/resources/style/bg_button_orange_l.png');
		margin: 0 0 10px 0;

	}
	.content_page .button_orange, .button_orange{
		width: 275px;
	}
	.button_orange.m {
		background-image: url('/files/resources/style/bg_button_orange_m.png');
		width: 227px;
	}
	.content_page .button_orange:hover, .button_orange:hover,.button_orange.sel{
		color: #242424;
		background-position: left bottom;
	}
	.content_page .btn,
	.btn{
		display: inline;
		vertical-align: top;
		margin: 11px 0px 0px 0px;
		position: relative;
		padding: 8px 20px 7px 20px;
		border: none;
		color: #333333;
		text-transform: uppercase;
		font-size: 14px;
		text-decoration: none;
		background: #ffba02;
		font-family: "PT Sans", sans-serif;
		line-height: 16px;
		outline: none;
		width: fit-content;
	}
	.content_page .btn:hover,
	.btn:hover{
		text-decoration: none;
		background: #ff8a00;
		color: #333333;
	}
	.content_page .btn:active,
	.btn:active{
	}
	.content_page .btn:after,
	.btn:after{
		display: none;
	}
	.btn.success_close_button{
		display: none;
	}
	.fw_win_content .btn.success_close_button{
		display: inline-block;
	}
	.content_page .ico_btn,.ico_btn {
		padding-left: 40px;
	}
	.content_page .ico_wide_btn,.ico_wide_btn {
		padding-left: 55px;
	}
	.content_page .ico_middle_btn,.ico_middle_btn {
		padding-left: 49px;
	}
	.ico_btn:before, .ico_wide_btn:before, .ico_middle_btn:before{
		content: '';
		display: block;
		position: absolute;
		left: 20px;
		top: 8px;
		width: 14px;
		height: 14px;
		background-image: url("/files/resources/style/sprite.png");
	}
	.ico_wide_btn:before{
		width: 29px;
	}
	.ico_middle_btn:before{
		width: 19px;
	}

	.ico_test_drive_btn:before{
		background-position: 0 -154px;
	}
	.ico_send_msg_btn:before{
		background-position: -135px -154px;
	}
	.ico_categories_list_btn:before{
		background-position: -120px -154px;
	}
	.ico_special_btn:before{
		background-position: -75px -154px;
	}
	.ico_faq_btn:before{
		background-position: -15px -154px;
	}
	.ico_contacts_btn:before{
		background-position: -60px -154px;
	}
	.ico_estimation_btn:before{
		background-position: -90px -154px;
	}
	.ico_credit_btn:before{
		background-position: -30px -154px;
	}
	.ico_serv_order_btn:before{
		background-position: -45px -154px;
	}
	.ico_info_btn:before{
		background-position: -105px -154px;
	}

	.ico_cars_btn:before{
		background-position: 0 -139px;
	}
	.ico_cars_reserve_btn:before{
		background-position: -60px -139px;
	}

	.ico_used_btn:before{
		background-position: -30px -139px;
	}
	.ico_wrangler_unlimited_btn:before{
		background-position: 0 -139px;
	}
	.ico_model_range_btn:before{
		background-position: 0 -169px;
	}
	.ico_car_reserve_btn:before{
		background-position: -20px -169px;
	}
	.list_thumb_inform_gallery .thumb_img_inform_gallery, .list_thumb_inform_gallery .thumb_img_inform_gallery .thumb_img{
		display: block;
		width: 176px;
		height: 134px;

	}
	.list_thumb_inform_gallery {
		margin-bottom: 20px;
	}
	.list_thumb_inform_gallery .thumb_img_inform_gallery{
		float: left;
		position: relative;
		margin: 0 20px 20px 0;
	}
	.list_thumb_inform_gallery .thumb_img_inform_gallery.last{
		margin-right: 0;
	}
	.list_thumb_inform_gallery .thumb_img_inform_gallery:after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 21px;
		height: 22px;
		background: url("/files/resources/style/sprite.png") -166px -286px;
		/*opacity: 0.3;*/
	}
	.list_thumb_inform_gallery .thumb_img_inform_gallery:hover:after{
		background-position: -188px -286px;
	}


	.right_menu .header_gray_menu{
		color: #c2c2c2;
		font-family: "PT Sans", sans-serif;
		text-transform: uppercase;
		font-size: 18px;
		background: #222222;
		padding: 18px 30px 20px 30px;
		font-weight: bold;
		border-radius: 5px 5px 0 0;
	}


	.right_menu .right_multilvl_menu{
		padding: 9px 0 17px 0;
		background: #282828;
		border-radius: 0 0 5px 5px;
	}

	.right_menu .right_multilvl_menu a{
		color: #ffffff;
		font-family: "PT Sans", sans-serif;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 22px;
		text-decoration: none;
		position: relative;
		display: block;
	}
	.right_menu .right_multilvl_menu a:hover{
		color: #ffba02;
	}
	.right_menu .right_multilvl_menu .active>a{
		color: #ffba02;
	}
	.right_menu .right_multilvl_item.active .right_multilvl_link:after, .right_menu .right_item_lvl2.active .right_lvl2_link:after{
		content: "";
		display: block;
		position: absolute;
		height: 100%;
		top: 0;
		background: #ffba02;
	}
	.right_menu .right_multilvl_item.active .right_multilvl_link:after{
		width: 6px;
		left: 0;
	}
	.right_menu .right_item_lvl2.active .right_lvl2_link:after{
		width: 4px;
		left: 29px;
	}
	.right_menu .right_multilvl_item.active .right_multilvl_link:before, .right_menu .right_item_lvl2.active .right_lvl2_link:before{
		content: "";
		display: block;
		position: absolute;
		height: 100%;
		top: 0;
		border-top: 6px solid #282828;
		border-bottom: 6px solid #282828;
		box-sizing: border-box;
		z-index: 2;
		width: 6px;

	}
	.right_menu .right_multilvl_item.active .right_multilvl_link:before{
		left: 0;
	}

	.right_menu .right_item_lvl2.active .right_lvl2_link:before{
		left: 29px;
	}
	.right_menu .right_multilvl_menu ul {
		list-style: none;
	}
	.right_menu .right_multilvl_menu .right_multilvl_item{

	}
	.right_menu .right_multilvl_menu .right_multilvl_item .right_multilvl_link{
		padding: 6px 30px;
	}
	.right_menu .right_multilvl_menu .right_multilvl_item .right_lvl2_link {
		padding: 6px 30px 6px 50px;
	}
	.right_menu .right_multilvl_menu .right_multilvl_item .right_lvl3_link {
		padding: 6px 30px 6px 70px;

	}
	.right_menu .right_multilvl_menu .right_multilvl_item .right_lvl3_link{
		font-size: 14px;
	}

	.root_banner_wrapper{
		height: 600px;
		width: 100%;
		position: relative;
		min-width: 1010px;
		z-index: 100;
	}
	.root_banner_wrapper .slide_prev,.root_banner_wrapper .slide_next{
		position: absolute;
		top: 284px;
		width: 40px;
		height: 40px;
	}
	.root_banner_wrapper .slide_prev a,.root_banner_wrapper .slide_next a{
		display: block;
		width: 100%;
		height: 100%;
	}
	.root_banner_wrapper .slide_prev{
		left: 36px;
		background: url("/files/resources/style/sprite.png") -1px -236px;
	}
	.root_banner_wrapper .slide_next{
		right: 36px;
		background: url("/files/resources/style/sprite.png") -42px -236px;
	}
	.root_banner_wrapper .banner_image{
		width:100%;
		height: 600px;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bx-wrapper .bx-viewport{
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border: none;
		background: #000000;
	}
	.standart_item{
		position: relative;
	}
	.standart_item .standart_item_img_link{
		display: block;
	}
	.standart_item .standart_item_img_link .standart_item_img{
		display: block;
	}
	.standart_item .standart_item_header{
		margin: 0;
		position: relative;
	}
	.standart_item .standart_item_subheader{
		font-family: "robotoregular", sans-serif;
		font-weight: bold;
		font-size: 16px;
		color: #fff;
	}
	.standart_item .standart_item_header a{
		color: #000;
	}
	.standart_item .standart_item_header a:hover, .standart_item .standart_item_header.hover a{
		color: #ffb302;
	}
	.standart_item .standart_item_description_strong{
		font-weight: bold;
		font-size: 14px;
	}
	.standart_item .standart_item_description{
		color: #666;
		font-size: 14px;
		margin: 12px 0 0 0;
		line-height: 20px;
		position: relative;
	}
	.standart_item .short_description{
		color: #777777;
		font-size: 14px;
		border-top: 1px solid #777777;
		display: inline-block;
		padding: 5px 0 0 0;
	}
	.standart_item .one_price .total_price{
		color: #afafaf;
	}
	.standart_item .total_price{
		color: #d64b00;
		font-size: 16px;
		font-weight: bold;

	}
	.standart_item .old_price{
		font-size: 12px;
		text-decoration: line-through;
		font-weight: bold;
	}

	.root_action_wrapper{
		height: 100px;
		position: relative;
		z-index: 1000;
	}

	.actions_root_list{
		position: absolute;
		top: -189px;
		left: 1px;
	}
	.actions_root_list .standart_item{
		margin: 0 16px 0 0;
		width: 240px;
		height: 295px;
		float: left;
	}
	.actions_root_list .standart_item.last{
		margin-right: 0;
	}
	.actions_root_list .standart_item  .special_img_link{
		overflow: hidden;
		position: relative;
		display: block;
		width: 240px;
		height: 227px;
		background: #3a3a3a; /* Old browsers */
		background: -moz-linear-gradient(top,  #3a3a3a 0%, #000000 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3a3a3a), color-stop(100%,#000000)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  #3a3a3a 0%,#000000 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  #3a3a3a 0%,#000000 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  #3a3a3a 0%,#000000 100%); /* IE10+ */
		background: linear-gradient(to bottom,  #3a3a3a 0%,#000000 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3a3a3a', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	}

	.actions_root_list .standart_item.hover .special_img_link{
		background: #ffa200; /* Old browsers */
		background: -moz-linear-gradient(top,  #ffa200 0%, #000000 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffa200), color-stop(100%,#000000)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  #ffa200 0%,#000000 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  #ffa200 0%,#000000 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  #ffa200 0%,#000000 100%); /* IE10+ */
		background: linear-gradient(to bottom,  #ffa200 0%,#000000 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa200', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
		/*height: 260px;*/
	}
	.actions_root_list .standart_item .special_img_link .special_offer_img{
		display: block;
		width: 234px;
		height: 227px;
		margin: 3px 0 0 3px;
		background-repeat: no-repeat;
		background-color: #000000;

	}

	.actions_root_list .standart_item .special_img_link .special_offer_img:after{
		content: "";
		display: block;
		width: 234px;
		height: 34px;
		left: 3px;
		position: absolute;
		bottom: 30px;
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	}

	.actions_root_list .standart_item .special_offer_bottom:before{
		content: "";
		display: block;
		width: 234px;
		height: 80px;
		position: absolute;
		left: 3px;
		top: -10px;
		opacity: 0.75;
		background: #000000;

	}
	.actions_root_list .standart_item .special_offer_bottom{
		padding: 0 13px;
		position: absolute;
		top: 149px;
	}

	.root_action_wrapper_renew{
		height: 197px;
		position: absolute;
		bottom: 2px;
		z-index: 120;
	}

	.root_action_wrapper_renew .root_action_item{
		display: block;
		width: 240px;
		display: block;
		background: #000;
		position: relative;
		bottom: 0;
		overflow: hidden;
		height: 197px;
		float: left;
		height: 327px;
		z-index: 5;
		margin: 0 16px 0 0;
		text-decoration: none;
	}
	.root_action_wrapper_renew .root_action_item.last{
		margin-right: 0;
	}
	.root_action_wrapper_renew .root_action_item:hover{
		/*top: -130px;*/
	}
	.root_action_wrapper_renew .root_action_item:hover:after {
		background-position: top right;
	}
	.root_action_wrapper_renew .root_action_item:after {
		content: "";
		display: block;
		background: url('http://www.jeep-russia.ru/ru/img/layout/bg_reel_home_box_frame.png') top left no-repeat transparent;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

	}
	.root_action_wrapper_renew .button_orange {
		left: 15px;
		position: absolute;
		top: 280px;
		width: 187px;
		text-shadow: rgb(0, 0, 0) 0px 1px 1px;
		z-index: 10;
	}
	.root_action_wrapper_renew .button_orange:hover {
		color: #242424;
		background-position: left bottom;
	}
	.root_action_wrapper_renew .root_action_header{
		color: #FFF;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		font-weight: normal;
		text-transform: uppercase;
		text-decoration: none;
		left: 0px;
		position: absolute;
		top: 10px;
		text-align: center;
		width: 100%;
		margin: 0;
		z-index: 10;
		line-height: normal;
		text-shadow: 1px 1px 1px #000;
	}
	.root_action_wrapper_renew .root_action_image_wrapper{

		top: 3px;
		left: 3px;
		position: absolute;
	}
	.root_action_wrapper_renew .root_action_image{
		display: block;
	}
	.root_action_wrapper_renew .root_action_image_wrapper:before{
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 50px;
		top: 0px;
		z-index: 10;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc2JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 76%, rgba(0,0,0,0) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,1)), color-stop(76%,rgba(0,0,0,0.4)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 76%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 76%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 76%,rgba(0,0,0,0) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 76%,rgba(0,0,0,0) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-8 */

	}
	.root_action_wrapper_renew .jeep_territory .root_action_image_wrapper:before{
		display: none;
	}
	.renegade_after:after{background:none!important;}
	.root_action_wrapper_renew .root_action_image_wrapper:after{
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 34px;
		bottom: 0px;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-8 */

	}
	body.root_page footer{
		margin-top: 0;
		z-index: 125;
		position: relative;
	}
	.root_action_wrapper_renew .root_action_item_description{
		color: #fff;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		top: 162px;
		left: 0px;
		position: absolute;
		width: 234px;
		margin: 0 3px;
		text-shadow: 1px 1px 1px #000;
	}
	.news_list .standart_item{
		overflow: hidden;
		margin: 0 0 30px 0;
	}
	.news_list .standart_item .news_list_item_left{
		float: left;
	}
	.news_list .standart_item .news_list_item_right{
		float: left;
		width: 515px;
	}
	.news_list .standart_item .standart_item_header a:before{
		content: "";
		display: block;
		position: absolute;
		top: 0px;
		left: -251px;
		width: 251px;
		height: 198px;
	}
	.news_list .standart_item .standart_item_description{
		margin: 9px 0 0 0;
	}
	.news_list .standart_item .short_description{
		margin: 15px 0 0 0;
	}
	.news_list .standart_item .news_list_item_left .standart_item_img_link{
		display: block;
		padding: 0 17px 0 0;
		width: 234px;
	}
	.paginator .link, .paginator .active{
		display: block;
		font-size: 14px;
		color: #afafaf;
		float: left;
		padding: 8px 8px 8px 8px;
		margin-right: 8px;
		line-height: 10px;
		text-decoration: none;
	}
	.paginator .link.disabled, .paginator .link.disabled:hover{
		color: #333333;
		border: 1px solid #484848;
		background: none;
		padding: 8px 8px 8px 8px;
	}
	.paginator.ajax_paginator .link {
		margin: 0 20px 0 0;
	}
	.paginator .link{
		border: 1px solid #484848;
	}
	.paginator .active, .paginator .link:hover{
		background: #ffb302;
		color: #333333;
		padding: 9px;
		border: none;
	}
	.actions_list .standart_item{
		width: 234px;
		margin: 0 32px 30px 0;
		float: left;
	}
	.actions_list .standart_item.last{
		margin-right: 0;
	}
	.actions_list .standart_item .standart_item_header{
		margin: 16px 0 0 0;
	}
	.actions_list .standart_item .standart_item_header a:before{
		content: "";
		display: block;
		width: 100%;
		height: 215px;
		position: absolute;
		top: -215px;
		left: 0;
	}
	.actions_list .standart_item .standart_item_description{
		margin: 16px 0 0 0;
	}

	.another_news_link_wrapper:before{
		content: "";
		display: block;
		clear: both;
	}
	.content_page.wide_content .soft_notice{
		margin-top: 10px;
	}
	.soft_notice{
		border: solid 2px #484848;
		padding: 25px 0 25px 58px;
		margin: 0 0 20px 0;
		position: relative;
		/*width: 704px;*/
	}
	.soft_notice:before{
		content: "";
		display: block;
		width: 28px;
		height: 27px;
		position: absolute;
		background: url("/files/resources/style/sprite.png") -239px -198px;
		top: 20px;
		left: 20px;
	}
	.soft_notice .soft_notice_text{
		font-size: 14px;
	}

	.cars_main_models_list .standart_item{
		width: 225px;
		height:245px;
		float: left;
		margin: 0 36px 30px 0;

	}
	.cars_main_models_list .standart_item.last{
		margin-right: 0;
	}
	.cars_main_models_list .standart_item .standart_item_header{
		white-space: nowrap;
		margin: 6px 0 0 0;
	}
	.cars_main_models_list .standart_item .standart_item_header a:before{
		content: "";
		display: block;
		width: 100%;
		height: 144px;
		position: absolute;
		top: -144px;
		left: 0;
	}
	.cars_main_models_list .standart_item .standart_item_description_strong{
		margin: 3px 0 0 0;
	}
	.cars_main_models_list .standart_item .standart_item_description{
		margin: 0;
	}
	.cars_main_models_list .standart_item.no_instock  .standart_item_header{
		color: #777777;
	}
	.cars_main_models_list .standart_item.no_instock  .standart_item_description{
		color: #777777;
	}
	.car_item_form.standart_item{
		margin: 0 0 20px 0;
		overflow: hidden;
	}
	.cars_list .standart_item {
		margin: 0 22px 30px 0;
		overflow: hidden;
		float: left;
	}
	.cars_list .standart_item.last{
		margin-right: 0;
	}
	.cars_list .standart_item .cars_list_item_left, .car_item_form.standart_item .cars_list_item_left{
		float: left;
		width: 150px;
		height: 112px;
		padding: 0 20px 0 0 ;
	}
	.cars_list .standart_item .standart_item_header a:before{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: -170px;
		width: 170px;
		height: 112px;
	}
	.cars_list .standart_item .cars_list_item_right, .car_item_form.standart_item  .cars_list_item_right{
		width: 570px;
		float: left;
	}
	.cars_list .standart_item .standart_item_description, .car_item_form.standart_item  .standart_item_description{
		margin: 5px 0 0 0;
	}
	.cars_list .standart_item .cars_list_color_list, .car_item_form.standart_item  .cars_list_color_list{
		margin: 1px 0 0 0;
	}
	.cars_list .standart_item .cars_list_color_list .cars_label_color, .car_item_form.standart_item  .cars_label_color{
		float: left;
		margin: 0 4px 0 0;
	}
	.cars_list .standart_item .cars_list_color_list .cars_color_item.dark_color,
	.car_item_form.standart_item  .cars_list_color_list .cars_color_item.dark_color{
		width: 14px;
		height: 10px;
		border: 1px solid #757575;
	}
	.cars_list .standart_item .cars_list_color_list .cars_color_item, .car_item_form.standart_item  .cars_color_item{
		display: block;
		float: left;
		width: 16px;
		height: 12px;
		margin: 4px 6px 4px 0;
		text-indent: -99999px;

	}
	.cars_list .standart_item .cars_list_color_list .cars_color_item:hover{
		border: 1px solid #ffba02;
		width: 14px;
		height: 10px;
	}
	.cars_list .standart_item .cars_list_price, .car_item_form.standart_item  .cars_list_price{
		margin: 6px 0 0 0;
	}
	.cars_list .standart_item .one_price .total_price, .car_item_form.standart_item .cars_list_price{
		float: left;


	}
	.cars_list .standart_item .total_price, .car_item_form.standart_item .total_price{
		float: left;
	}
	.cars_list .standart_item .old_price, .car_item_form.standart_item   .old_price{
		float: left;
		margin-left: 10px;
	}

	.cars_list .standart_item .discounts_label{
		color: #fff;
		font-size: 10px;
		border: 1px solid #d64b00;
		display: inline-block;
		padding: 0 7px 0 7px;
	}


	.cars_card_header_wrapper{
		overflow: hidden;
	}
	.cars_card_header_wrapper h1{
		width: 377px;
		float: left;
	}
	.instock_price_card{
		border: 4px solid #ffba02;
		float: right;
		padding: 16px 18px 15px 18px;
		height: 18px;
	}
	.instock_total_price{
		color: #ffba02;
		font-family: "robotoregular", sans-serif;
		font-size: 24px;
		font-weight: bold;
		float: left;
	}
	.instock_old_price{
		float: left;
		font-family: "robotoregular", sans-serif;
		font-size: 17px;
		font-weight: bold;
		color: #afafaf;
		margin: 0 0 0 29px;
		text-decoration: line-through;
	}
	.cars_card_prepay{
		overflow: hidden;
		margin: 0 0 28px 0;
	}
	.cars_card_prepay .btn {
		margin: 0 20px 0 0;
		float: left;
	}

	.cars_card_prepay .footnote{
		float: left;
		width: 270px;
		line-height: 15px;
	}

	.real_photos{
		width: 766px;
		margin: 0 0 17px 0px;
	}
	.fotorama {
		min-height: 525px;
		min-width: 766px;
	}
	.fotorama__stage {
		min-height: 427px;
	}
	.fotorama__thumb {
		opacity: 0.6;
	}
	.fotorama__active .fotorama__thumb, .fotorama__thumb:hover {
		opacity: 1;
	}
	.fotorama a.fotorama_item{
		visibility: hidden;
	}
	.fotorama__thumb-border{
		border-color: #ffba02;
	}
	.discounts_block{
		border: 3px solid #ffba02;
		padding: 25px 28px 25px 28px;
		margin: 0 0 30px 0;
	}
	.discounts_block .discount_title {
		font-family: 'PT Sans', sans-serif;
		font-size: 21px;
		font-weight: bold;
		color: #ffffff;
		margin: 0 0 19px 0;
	}
	.discounts_block .discount_title  .discount_min_price_value , .discounts_block .discount_title  .roubl {
		font-size: 24px;
		color: #ffba02;
	}
	.discounts_block   .discount_descritption_label{
		margin: 0 0 8px 0;
	}

	.discounts_block .discount_options_block{
		margin: 0 0px 8px 20px;
	}
	.discounts_block .discount_options_block .discount_min_price_value {
		font-weight: bold;
	}
	.discounts_block .discount_options_block .rouble{
		font-size: 14px;
		font-weight: bold;
	}
	.discounts_block .discount_hint{
		font-size: 12px;
	}
	.instock_img_big_wrapper{
		overflow: hidden;
		background: #fff;
		margin: 0 0 22px 0;
	}
	.instock_img_big{
		display: block;
		margin: 0 auto;
	}
	.instock_card_colors {
		margin: 0 0 30px 0;
	}
	.instock_card_label_color{
		float: left;
		line-height: 24px;
		font-size: 14px;
		font-weight: bold;
		margin-right: 12px;
	}
	.instock_card_colors_list  .instock_card_color_item{
		width: 32px;
		height: 24px;
		float: left;
		display: block;
		margin: 0 14px 0 0;
		text-indent: -99999px;
		position: relative;
	}
	.instock_card_colors_list  .instock_card_color_item.dark_color{
		border: 1px solid #757575;
		width: 30px;
		height: 22px;
	}



	.instock_card_colors_list  .instock_card_color_item:hover, .instock_card_colors_list  .instock_card_color_item.selected{
		border: 2px solid #ffba02;
		width: 28px;
		height: 20px;

	}

	.instock_card_colors_list  .instock_card_color_item.selected:before {
		content: "";
		display: block;
		position: absolute;
		width: 32px;
		height: 10px;
		top: -12px;
		left: -2px;
		background: url('/files/resources/style/sprite.png') -92px -236px ;
	}
	.instock_car_actions_block {
	}
	.instock_car_actions_block .btn {
		margin: 0 20px 0 0;
	}
	.instock_def_features{
		overflow: hidden;
	}
	.features_col_1, .features_col_2{
		float: left;
		width: 373px;
		margin: 0;

	}
	.features_col_1{
		margin-right: 20px;
	}
	.features_list ul{
		margin: 0;
	}
	.features_list ul li{
		margin: 0 0 21px 0;
		padding: 0;
	}
	.features_list ul li:before{
		display: none;
	}

	.features_list .features_label {
		font-weight: bold;
		display: inline-block;
		margin-right: 5px;
		vertical-align: top;
		width: 115px;
		font-size: 16px;
	}
	.features_list .features_descr {
		width: 250px;
		display: inline-block;
		font-size: 16px;
	}
	.equipment_layout{
		overflow: hidden;
	}
	.equipment_list ul{
		overflow: hidden;
		margin-bottom: 11px;
	}

	.equipment_layout .equipment_list {
		margin-bottom: 11px;
	}
	.instock_similar_cars{
		overflow: hidden;
	}
	.equipment_list li {
		float: left;
		width: 345px;
		margin-right: 20px;
	}

	.list_control{
		padding: 0 0 0 13px;
		position: relative;
		text-decoration: none;
	}
	.list_control:before{
		content: "";
		display: block;
		position: absolute;
		width: 8px;
		height: 7px;
		background: url('/files/resources/style/sprite.png') -23px -71px;
		top: 6px;
		left: 0px;
	}
	.list_control:hover:before{
		background-position: -32px -71px;
	}

	.list_control.minus:before{
		background-position: -48px -71px;
	}
	.list_control.minus:hover:before{
		background-position: -57px -71px;
	}


	.list_control  .btn_control_link{
		border-bottom: 1px dashed #d64b00;
		text-decoration: none;
	}
	.list_control:hover  .btn_control_link{
		border-bottom: 1px dashed #ffba02;
	}

	.contacts_sidebar{
		margin: 31px 0 0 0;
	}
	.contacts_sidebar_content{
		background: #282828;
		border-radius: 0 0 5px 5px;
		padding: 11px 0 17px 0;
	}
	.contacts_sidebar_item{
		padding: 6px 30px 15px 30px;
		position: relative;
	}
	.contacts_sidebar_item.showroom_header .contacts_sidebar_header{
		font-weight: bold;
		padding-left: 0;
	}
	.contacts_sidebar_item.showroom_header.nr_showroom {
		margin-top: 30px;
	}
	.contacts_sidebar_header{
		font-family: "robotoregular", sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		color: #ffffff;
	}
	.contacts_sidebar_description{
		font-size: 14px;
		color: #ffffff;
	}
	.contacts_sidebar_header{
	}
	.contacts_sidebar_header, .contacts_sidebar_description{
		padding: 0 0 0 26px;
		line-height: 19px;
	}
	.contacts_sidebar_item.address:before{
		content: "";
		display: block;
		position: absolute;
		top: 5px;
		left: 30px;
		width: 18px;
		height: 20px;
		background: url('/files/resources/style/sprite.png') -248px -284px;
	}
	.contacts_sidebar_item.phone:before{
		content: "";
		display: block;
		position: absolute;
		top: 5px;
		left: 30px;
		width: 18px;
		height: 20px;
		background: url('/files/resources/style/sprite.png') -288px -284px;
	}
	.contacts_sidebar_item.schedule:before{
		content: "";
		display: block;
		position: absolute;
		top: 5px;
		left: 30px;
		width: 18px;
		height: 20px;
		background: url('/files/resources/style/sprite.png') -268px -284px;
	}
	.standart_item.used_item_form{
		overflow: hidden;
	}
	.used_list .standart_item{
		width: 372px;
		float: left;
		margin: 0 22px 30px 0;
	}
	.used_list .standart_item.last{
		margin-right: 0;
	}
	.used_list .standart_item .used_list_item_left, .standart_item.used_item_form .used_list_item_left{
		float: left;
		width: 204px;
		height: 153px;
		padding: 0 20px 0 0;
	}
	.used_list .standart_item .used_list_item_right, .standart_item.used_item_form  .used_list_item_right{
		float: left;
		width: 148px;
	}
	.used_list .standart_item .standart_item_description, .standart_item.used_item_form  .standart_item_description{
		margin: 4px 0 0 0;
	}
	.used_list .standart_item .total_price, .standart_item.used_item_form .total_price  {
		margin: 5px 0 0 0;
	}
	.used_list .standart_item .old_price, .standart_item.used_item_form  .old_price {
		margin: 4px 0 0 0;
	}
	.used_list .standart_item .standart_item_header a:before{
		content: "";
		display: block;
		width: 224px;
		height: 153px;
		position: absolute;
		left: -224px;
		top: 0;
	}

	.expert_link_block {
		text-decoration: none;
		display: block;
		border: 2px solid #ea5b0c;
		padding: 11px 10px;
		height: 76px;
		position: relative;
		margin: 30px 0 0 0;
	}
	.expert_link_block:before{
		content: "";
		display: block;
		width: 238px;
		height: 76px;
		background: red;
		position: absolute;
		top: 11px;
		left: 10px;
		background: url("/files/resources/style/expert_link.png");
	}
	.expert_link_block .expert_link_description{
		margin: 14px 0 0 290px;
	}
	.expert_link_block .standart_item_header{
		text-decoration: underline;
	}
	.expert_link_block:hover .standart_item_header{
		color: #ffb302;
	}
	.expert_link_block .standart_item_description{
		margin-top: 7px;
	}
	.used_card_header_wrapper {
		overflow: hidden;
		margin: 0 0 21px 0;
	}
	.used_card_header_wrapper h1{
		margin: 0px 0px 4px 0px;
	}
	.used_card_header_wrapper .instock_header_left{
		width: 377px;
		float: left;
	}
	.used_card_header_wrapper .instock_subheader{
		font-family: "robotoregular", sans-serif;
		color: #fefefe;
		font-size: 16px;
		font-weight: bold;
	}

	.used_image_wrapper .bxslider-container li{
		margin: 0;
		padding: 0;
		cursor: pointer;

	}
	.used_image_wrapper .bxslider-container li:before{
		display: none;
	}
	.used_image_wrapper .bxslider-wrapper{
		margin: 20px 0 0 0;
	}
	.used_image_wrapper .bx-wrapper .bx-viewport{
		left: -1px;
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a{
		width: 69px;
		height: 94px;
		opacity: 1;
		background: #0e0e0e;
		top: 16px;
		z-index: 1;
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a:before{
		content: "";
		width: 10px;
		height: 16px;
		display: block;
		position: absolute;
		top: 46px;
		left: 30px;
		background: url('/files/resources/style/sprite.png');

	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-prev:before{
		background-position: -122px -349px
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-next:before{
		background-position: -154px -349px
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-prev:hover:before{
		background-position: -134px -349px
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-next:hover:before{
		background-position: -166px -349px
	}

	.used_image_wrapper .bx-wrapper .bx-controls-direction a:hover{
		background: #222222;
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-prev{
		left: -70px;
	}
	.used_image_wrapper .bx-wrapper .bx-controls-direction a.bx-next{
		left: 626px;
	}
	.used_image_wrapper {
		position: relative;
		margin: 0 0 30px 0;
		text-align: center;
	}
	.used_image_wrapper .bx_image_control{
		width: 69px;
		height: 470px;
		display: block;
		background: #0e0e0e;
		position: absolute;
		top: 0;
		cursor: pointer;
	}
	.used_image_wrapper .bx_image_control:hover{
		background: #222222;
	}
	.used_image_wrapper .bx_image_control.prev{
		left: 0;
	}
	.used_image_wrapper .bx_image_control:before{
		content: "";
		display: block;
		width: 14px;
		height: 24px;
		position: absolute;
		top: 216px;
		left: 28px;
		background: url('/files/resources/style/sprite.png');


	}
	.used_image_wrapper .bx_image_control.prev:before{
		background-position: -2px -341px;
	}
	.used_image_wrapper .bx_image_control.prev:hover:before{
		background-position: -18px -341px;
	}
	.used_image_wrapper .bx_image_control.next:before{
		background-position: -52px -341px;
	}
	.used_image_wrapper .bx_image_control.next:hover:before{
		background-position: -68px -341px;
	}
	.used_image_wrapper .bx_image_control.next{
		right: 1px;
	}
	.used_image_wrapper #target_image{
		position: relative;
		top: 0;
		left: 69px;
		display: block;
		cursor: pointer;
	}
	.faq_top_button.btn{
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
	}
	.faq_bottom_button.btn{
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.questions_list{
		margin: 15px 0 0 0;
	}
	.questions_card{
		margin: 16px 0 0 0;
	}
	.questions_list  .standart_item .question, .questions_card  .standart_item .question{
		margin-bottom: 20px;
	}
	.questions_list .standart_item, .questions_card .standart_item{
		border-bottom: 1px solid #777777;
		padding: 0  0 25px 0;
		margin: 0 0 35px 0;
	}
	.questions_list .standart_item_header, .questions_card .standart_item_header{
		margin: 0 0 16px 0;
	}
	.questions_list .standart_item_description, .questions_card .standart_item_description{
		margin: 6px 0 0 0;
	}

	.another_questions_menu .list_menu{
		margin: 0;
	}
	.found_word{
		background:#ffba02;
		color: #333333;
		padding: 0 2px;
	}
	.contacts_box{
		overflow: hidden;
		margin: 30px 0 0 0;
	}
	.contacts_box .contacts_list{
		float: left;
		width: 286px;
	}
	.contacts_box .contacts_image{
		width: 480px;
		float: left;

	}
	.contacts_box .contacts_box_item {
		padding: 0 0 0 47px;
		margin: 0 0 29px 0;
		position: relative;
	}
	.contacts_box .contacts_box_item:before{
		content: "";
		display: block;
		width: 27px;
		height: 30px;
		position: absolute;
		background: url('/files/resources/style/sprite.png');
		top: 10px;
		left: 0;
	}
	.contacts_box .contacts_box_item.address:before{
		background-position: -248px -315px;
	}
	.contacts_box .contacts_box_item.schedule:before{
		background-position: -277px -315px;
	}
	.contacts_box .contacts_box_item.phone:before{
		background-position: -306px -315px;
	}

	.contacts_box .contacts_box_header{
		font-size: 16px;
		line-height: 22px;
		color: #fefefe;
		font-family: "PT Sans", sans-serif;
		font-weight: bold;
		margin: 0 0 11px 0;
	}

	.old_browsers_download .browser_item {
		display: block;
		float: left;
		text-align: center;
		margin: 10px 0 0 0;
		position: relative;
		width: 25%;
	}
	.old_browsers_download .browser_item .browser_name{
		position: relative;
		padding-right: 17px;
		width: 100%;
		margin: 0 auto;
	}
	.old_browsers_download .browser_item .browser_name .link_arrow{
		position: absolute;
		content: '';
		right: 50px;
		top: 4px;
		width: 11px;
		height: 11px;
		background: url("/files/resources/style/sprite.png") no-repeat 0 -109px;

	}
	.old_browsers_download .browser_item.hover .browser_name .link_arrow {
		background-position: -12px -109px;
	}
	.old_browsers_download img {
		width: 20%;
	}
	#old_browsers_download .content_page{
		min-height: 300px;
	}
	#old_browsers_download .sidebar{
		margin: 0;
	}

	.sitemap_header .sitemap_header_link{
		color: #ffffff;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
	}
	.sitempap_col{
		width: 186px;
		float: left;
		margin: 0 20px 0 0;
	}
	.sitempap_col.last{
		margin-right: 0;
	}
	.sitempap_col ul{
		list-style: none;
	}
	.sitemap_lev1 li:before{
		display: none;

	}
	.sitemap_wrapper .sitemap_lev1 li{
		padding: 0;
		margin: 0 0 15px 0;
	}
	.sitemap_wrapper li a{
		color: #afafaf;
		text-decoration: none;
	}
	.sitemap_wrapper .sitemap_header{
		margin: 0 0 18px 0;
	}
	.sitemap_wrapper a{
		display: block;
	}
	.sitemap_wrapper .sitemap_lev2{
		margin: 15px 0 0 14px;
	}
	.sitemap_lev2>li>a{
		position: relative;
		padding: 0 0 0 14px;
		display: block;
	}
	.sitemap_lev2>li>a:before{
		content: "";
		display: block;
		position: absolute;
		top: 4px;
		left: 0px;
		/*width: 4px;
	height: 7px;*/
		border: 4px solid transparent;
		border-left-color: #afafaf;
		border-right: 0;
	}
	.sitemap_lev2>li>a:hover:before{
		border-left-color: #ffba02;
	}
	.sitemap_lev2>li.opened>a:before{
		top: 8px;
		border: 4px solid transparent;
		border-top-color: #afafaf;
	}
	.sitemap_lev2>li.opened>a:hover:before{
		border-top-color: #ffba02;
	}

	.sitemap_wrapper .sitemap_lev3{
		margin: 15px 0 0 25px;
	}





	/*start model_range_css*/
	.model_collage{
		position: absolute;
		width: 100%;
		min-width: 1010px;
		height: 750px;
		background-position: center top;
		background-repeat: no-repeat;
	}
	.model_description_wrapper{
		left: 0px;
		top: 100px;
		position: relative;
		padding: 32px 0 30px;
		width: 348px;
	}
	.model_description{
		background: url('/files/resources/style/bg_product_page_show_table_body_original.png') center top repeat-y;
		padding: 0 32px;
		color: #fff;
	}

	.model_description:before,
	.model_description:after{
		position: absolute;
		content: " ";
		width: 100%;
		left: 0;
	}
	.model_description:before{
		height: 32px;
		top: 0;
		background: url('/files/resources/style/bg_product_page_show_table_top.png') center bottom no-repeat;
	}
	.model_description:after{
		height: 30px;
		bottom: 0;
		background: url('/files/resources/style/bg_product_page_show_table_bottom.png') center top no-repeat;
	}
	.model_description .button_orange{
		margin: 10px 0 0 0;

	}
	.model_description .header{
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 5px;

	}
	.model_description .description{
		font-size: 12px;
		line-height: normal;
	}
	.model_description .separator{
		background: url('/files/resources/style/bg_product_page_show_table_sep.png') center center no-repeat;
		height: 20px;

	}

	.model_precontent{
		min-height: 455px;
		position: relative;
	}
	.model_description_wrapper .btn{
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		width: 284px;
	}
	.model_submenu_wrapper{
		background: #323333;
		height: 44px;
		line-height: 43px;
		font-size: 14px;
	}
	.model_submenu{
		margin: 0 auto;
		width: 1010px;
	}
	.model_submenu .item{
		color: #fff;
		text-transform: uppercase;
		text-decoration: none;
	}
	.model_submenu .item.active,
	.model_submenu .item:hover{
		color: #FFBA02;
	}
	.content_page .link_plus{
		height: 53px;
		line-height: 53px;
		overflow: hidden;
		padding-left: 60px;
		position: absolute;
		background: url('/files/resources/style/bg_product_page_show_model_point.png') left bottom no-repeat;
		text-transform: uppercase;
		text-decoration: none;
		visibility: hidden;
		white-space: nowrap;
		color: #fff;
	}
	.content_page .link_plus:hover{
		color: #fff;
		background-position: left top;
	}
	.model_content{
		background: url('/files/resources/style/bg_product_page.png') left top repeat-x #0f0f0f;
	}
	.content_item{
		position: relative;
		padding: 15px 12px 130px;
		color: #d3d3d3;;
	}
	.content_item h2{
		text-transform: uppercase;
		font-weight: normal;
		font-size: 35px;
		font-family: Arial, Helvetica, sans-serif;
		background: url('/files/resources/style/bg_product_page_section_title.png') left bottom repeat-x;
		padding: 5px 0 12px;
		margin-top: 0;
	}
	.content_item.with_image .text{
		font-size: 12px;
		line-height: 17px;
		width: 440px;
	}
	.content_item.with_image .image{
		position: absolute;
		right: 10px;
		top: 0;
	}
	.content_item .to_top{
		display: inline-block;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		margin: 25px 0 0;
		padding: 0 15px 0 0;
		text-decoration: none;
		text-transform: uppercase;
		background: url('/files/resources/style/img_arrow_top.png') center right no-repeat;
		color: #FFBA02;
	}
	.content_item .to_parent_page{
		top: 13px;
		right: 13px;
		position: absolute;
	}

	.model_page_gallery .item_image{
		display: inline-block;
		margin: 0 14px 13px 0;
		line-height: 0;
		border: 10px solid #272727;
		position: relative;
		width: 216px;
		height: 122px;
		overflow: hidden;
	}
	.model_page_gallery .item_image.last{
		margin-right: 0;
	}
	.model_page_gallery .shadow{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-box-shadow: inset 0 3px 6px 1px rgba(39,39,39,0.9);
		-moz-box-shadow: inset 0 3px 6px 1px rgba(39,39,39,0.9);
		box-shadow: inset 0 3px 6px 1px rgba(39,39,39,0.9);
	}
	.model_header{
		font-family: 'DinProBold';
		position: absolute;
		color: #fff;
		line-height: 1;
		text-transform: uppercase;
		font-weight: bold;
	}
	/*end model_range_css*/







	.form_layout .formjor-asterisk{
		color: #ffba02;
	}
	.form_layout .form_field_item{
		display:block;
		margin: 0 0 20px 0;
		background: none;
	}
	.form_layout .form_group{
		margin-bottom: 35px;
	}
	.form_layout .form_group .group_name{
		margin-bottom: 24px;
	}
	.form_layout .form_field_layout{
		float: left;
	}
	.form_layout .ik_select_link, .form_layout .def_inp{
		border: 1px solid #484848;
		background: none;
		background-color: #000000;
		color: #afafaf;
		outline: none;
		width: 278px;
		height: 20px;
		padding: 9px 9px 9px 9px;
		border-radius: 0;
		box-shadow: none;
		display: block;


	}
	.form_layout textarea.def_inp{
		max-width: 278px;
		height: 100px;
		min-width: 278px;
	}
	input.def_inp:-webkit-autofill, textarea.def_inp:-webkit-autofill, select.def_inp:-webkit-autofill{
		-webkit-box-shadow: 0 0 0px 1000px #000000 inset;
		-webkit-text-fill-color: #afafaf;
	}
	.form_layout .def_inp::-ms-clear{
		display: none;

	}

	.form_layout .def_inp::-ms-reveal{
		display: none;

	}

	.form_layout .def_inp.formjor-err, .form_layout .formjor-err .ik_select_link{
		border-color: #cc3333;
	}

	.form_layout .form_label_layout {
		display: block;
		font-family: "robotoregular", sans-serif;
		font-weight: bold;
		font-size: 16px;
		color: #fefefe;
		width: 191px;
		float: left;
		margin: 0 5px 0 0;
		position: relative;
		min-height: 1px;
	}
	.form_layout .form_label_layout .form_label{
		margin-top: 6px;
		display: block;
	}
	.form_layout .form_label_layout .error_notice{
		font-family: arial, serif;
		font-size: 11px;
		display: none;
		position: absolute;
		top: 26px;
		left: 0;


	}
	.form_layout .form_label_layout .form_label.formjor-err + .error_notice{
		display: block;

	}
	.form_layout .form_label_layout .error_notice{
		font-size: 11px;
		color: #cc3333;
	}
	.form_layout .form_label_layout .form_label.formjor-err{
		background: none;
		border: none;
	}
	.form_layout .form_field_item .form_field_layout{
		position: relative;
	}
	.form_layout .form_field_item.type_date .form_field_layout:after{
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		background: url('/files/resources/style/sprite.png') -2px -392px;
		position: absolute;
		top: 10px;
		right: 11px;
	}
	.form_layout .form_field_item.type_time .form_field_layout:after{
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		background: url('/files/resources/style/sprite.png') -20px -392px;
		position: absolute;
		top: 10px;
		right: 11px;
		z-index: 100;
	}
	.form_layout .form_field_item.type_checkbox input[type=checkbox] {
		display: none;

	}



	.form_layout .form_field_item.type_checkbox label {
		padding-left: 39px;
		display: block;
		padding-top: 3px;
		min-height: 27px;
		max-width: 260px;
	}
	.form_layout .form_field_item.type_checkbox label:before {
		content: '';
		display: block;
		width: 22px;
		height: 22px;
		border: 1px solid #484848;
		position: absolute;
		left: 0;
		top: 0;
	}
	.form_layout .form_field_item.type_checkbox input:not(:checked).formjor-err + label:before {
		border-color: #cc3333;
	}
	.form_layout .form_field_item.type_checkbox input:checked + label:before {
		border-color: #ffba02;
		background: url(/files/resources/style/checked.png) no-repeat center #ffba02;
	}
	.form_layout .type_checkbox .error_notice {
		margin-left: 39px;
		color: #c33;
		display: none;
	}


	.form_layout .form_field_item.type_select .form_field_layout:after,
	.form_layout .form_field_item.type_select_test_drive .form_field_layout:after{
		content: '';
		display: block;
		width: 12px;
		height: 8px;
		background: url('/files/resources/style/sprite.png') -2px -378px;
		position: absolute;
		top: 17px;
		right: 11px;
	}
	.form_layout .form_field_layout .ik_select{
		top: 0;
	}

	.ik_select_dropdown.select_form-dd .ik_select_list{
		padding: 0;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: none;
		background-color: #000000;
		-webkit-box-shadow: none;
		box-shadow: none;
		color: #444;
		border: 1px solid #484848;
		border-top: none;
	}
	.ik_select_dropdown.select_form-dd .ik_select_list li{
		background: none;
		background-color: #000000;
		height: 36px;
		line-height: 36px;
		color: #afafaf;
		font-size: 14px;
		padding: 0 9px 0px 9px;
		list-style: none;
	}
	.ik_select_dropdown.select_form-dd .ik_select_list li.ik_select_hover {
		background: none;
		background-color: #ffba02;
		-webkit-box-shadow: none;
		box-shadow: none;
		color: #333333;
	}
	.ik_select_dropdown.select_form-dd .ik_select_list li.ik_select_active span:after{
		display: none;
	}

	.faq_search.form_layout .field_search-field{
		width: 337px;
		float: left;
		margin-right: 20px;
	}
	.faq_search.form_layout .form_field_item.type_select{
		float: left;
		margin-right: 23px;
	}
	.faq_search.form_layout .ik_select_link{
		width: 238px;
		z-index: 99;
	}
	.faq_search.form_layout .faq_search_button{
		width: 87px;
		display: block;
		float: left;
		box-sizing: content-box;
		margin: 0;
		height: 40px;
		padding: 0;
		color: #ffffff;
		background: #282828;
		padding-left: 21px;
		position: relative;

	}
	.faq_search.form_layout .faq_search_button:before{
		content: "";
		display: block;
		position: absolute;
		top: 13px;
		left: 20px;
		width: 14px;
		height: 14px;
		background: url("/files/resources/style/sprite.png") -2px -413px;
	}
	.faq_search.form_layout h3{
		margin: 0px 0 9px 0;
	}
	.faq_search.form_layout .faq_search_button:hover{
		background: #3d3d3d;
	}
	#ui-datepicker-div.ui-datepicker .ui-datepicker-header{
		border-radius: 0;
		background: none;
		border: none;
		padding: 0;
		margin: 0;
		cursor: pointer;
	}
	#ui-datepicker-div{
		border-radius: 0;
		padding: 0;
		width: 296px;
		border: 1px solid  #484848;
		background: #000000;
	}

	#ui-datepicker-div .ui-datepicker-calendar{
		width: 266px;
		margin: 0 auto 12px;
	}
	#ui-datepicker-div .ui-datepicker-calendar td{
		padding: 0;
		opacity: 1;
	}
	#ui-datepicker-div.ui-datepicker .ui-datepicker-title{
		padding: 0 0 3px 0;
		color: #afafaf;
		font-size: 14px;
		font-family: "robotoregular", sans-serif;
		font-weight: bold;
		border-bottom: 1px solid #333333;
		width: 254px;
		margin: 12px auto 13px;
	}
	#ui-datepicker-div.ui-datepicker th{
		padding: 0 0 11px 0;
		color: #afafaf;
		font-size: 15px;
		font-family: "robotoregular", sans-serif;
	}
	#ui-datepicker-div .ui-datepicker-calendar td .ui-state-default{
		width: 23px;
		height: 20px;
		display: block;
		padding: 0;
		text-align: center;
		font-weight: normal;
		font-size: 14px;
		background: none;

		color: #afafaf;
		font-family: arial, serif;
		line-height: 20px;
		border-color: #333333;
		margin: 0 auto 10px;


	}
	#ui-datepicker-div .ui-datepicker-calendar td .ui-state-default.ui-state-hover, #ui-datepicker-div .ui-datepicker-calendar td .ui-state-default.ui-state-active{
		background-color: #ffba02;
		border-color: #ffba02;
		color: #333333;
	}
	#ui-datepicker-div .ui-datepicker-calendar td.ui-state-disabled .ui-state-default{
		color: #333333;
	}
	#ui-datepicker-div .ui-widget-header a{
		border-radius: 0;
		opacity: 1;
		border: none;
		right: 29px;
		top: 7px;
		background: none;
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-prev{
		left: 27px;
	}
	#ui-datepicker-div .ui-widget-header a.ui-state-hover{
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-next:after{
		content: "";
		display: block;
		position: absolute;
		top: 10px;
		left: 14px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-left-color: #afafaf;
		border-right: 0;
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-next:hover:after{
		border-left-color: #ffba02;
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-prev:after{
		content: "";
		display: block;
		position: absolute;
		top: 10px;
		left: 14px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-right-color: #afafaf;
		border-left: 0;
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-prev.ui-state-disabled:after, #ui-datepicker-div .ui-widget-header a.ui-datepicker-prev.ui-state-disabled:hover:after{
		border-right-color: #333333;
	}
	#ui-datepicker-div .ui-widget-header a.ui-datepicker-prev:hover:after{
		border-right-color: #ffba02;
	}
	#ui-datepicker-div .ui-widget-header a .ui-icon{
		display: none;
	}
	.container_timepicker{
		width: 296px;
		padding: 0;
		border: 1px solid #484848;
		background: #000000;
	}
	.container_timepicker table{
		margin: 0 auto 10px;
	}
	.container_timepicker .cell_timepicker a, .container_timepicker .cell_header_timepicker a{
		width: 54px;
		height: 20px;
		display: block;
		border: 1px solid #333333;
		margin: 0 18px 10px 18px;
		background: #000000;
		color: #afafaf!important;
		font-size: 14px;
		font-weight: normal;
		opacity: 1;
		-moz-opacity: 1;
		-webkit-opacity: 1;
		filter: Alpha(Opacity=100);
	}
	.container_timepicker .cell_header_timepicker a{
		border-top: none;
		border-left: none;
		border-right: none;
		font-family: 'PT Sans';
		font-weight: bold;
		font-size: 14px;
		color: #afafaf!important;
		text-transform: none;
		margin: 16px auto 10px;
		padding: 0 0 4px 0;
	}
	.container_timepicker .cell_timepicker.disabled_timepicker a, .container_timepicker .cell_timepicker.disabled_timepicker a:hover{
		color: #333333!important;
		background: #000000;
	}
	.container_timepicker .cell_timepicker a:hover, .container_timepicker .cell_timepicker.selected_timepicker a{
		background: #ffba02;
		color: #333333!important;
	}
	.submit_button_wrapper button.btn {
		margin-left: 196px

	}

	.fw_win_content.fw_win_common_offset{
		padding: 0px 76px 30px 30px;
		width: 494px;

	}
	.fw_win_wrapper .fw_win_content_container{
		-webkit-border-radius: 5px;
		-moz-border-radius: 0;
		-ms-border-radius: 0;
		border-radius: 0;
		background: #000000;
		border: 1px solid #484848;
	}
	.fw_win_wrapper .fw_win_content_container .fw_win_title{
		margin: 36px 0 8px 30px;
	}
	.fw_win_wrapper .fw_win_content_container .fw_win_title .fw_win_close_button{
		opacity: 1;
		top: -2px;
		right: 20px;
	}
	.fw_win_wrapper .fw_win_content_container .fw_win_title .fw_win_close_button:after{
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background: url("/files/resources/style/sprite.png") transparent no-repeat -63px -392px;
		position: absolute;
		top: 12px;
		left: 12px;

	}
	.fw_win_wrapper .fw_win_content_container .fw_win_title .fw_win_close_button:hover:after{
		background-position: -80px -392px;
	}


	.bottom_cherokee_menu{
		background: url('/files/resources/style/bg_template_cta.png') bottom center repeat-x #FFF;
		padding: 10px 0 0 15px;
		height: 240px;
	}
	.bottom_cherokee_menu .menu_item{
		float: left;
		height: 101px;
		padding: 139px 0 0 0;
		text-align: center;
		width: 239px;
		display: block;
		color: #000;
		display: block;
		font-family: 'ProximaBold', Arial, Helvetica, sans-serif;
		font-size: 22px;
		text-align: center;
		text-decoration: none;
		position: relative;
	}
	.bottom_cherokee_menu .menu_item:hover:before{
		opacity: 0.5;
	}
	.bottom_cherokee_menu .menu_item.brochure:before{
		content: "";
		display: block;
		background: url('/files/resources/style/bg_template_cta_brochure.png') ;
		width: 36px;
		height: 46px;
		position: absolute;
		top: 57px;
		left: 101px;
	}
	.bottom_cherokee_menu .menu_item.quote:before{
		content: "";
		display: block;
		background: url('/files/resources/style/bg_template_cta_quote.png') ;
		width: 60px;
		height: 51px;
		position: absolute;
		top: 56px;
		left: 90px;
	}
	.bottom_cherokee_menu .menu_item.testdrive:before{
		content: "";
		display: block;
		background: url('/files/resources/style/bg_template_cta_testdrive.png') ;
		width: 79px;
		height: 27px;
		position: absolute;
		top: 67px;
		left: 80px;
	}
	.bottom_cherokee_menu .menu_item.dealer:before{
		content: "";
		display: block;
		background: url('/files/resources/style/bg_template_cta_dealer.png') ;
		width: 34px;
		height: 50px;
		position: absolute;
		top: 55px;
		left: 103px;
	}

	.bottom_cherokee_menu .menu_item:hover{
		color: #000;
	}
	.routing_list .standart_item{
		width: 234px;
		float: left;
		margin: 0 12px 30px 12px;
		@media only screen and (max-width: 1045px){
			margin-bottom: 15px;
		}

	}
	.routing_list .standart_item.last{
		margin-right: 0;
	}
	.routing_list .standart_item .standart_item_header{
		margin: 18px 0 0 0;
	}
	.routing_list .standart_item .standart_item_header a:after{
		content: "";
		display: block;
		position: absolute;
		top: -213px;
		width: 234px;
		height: 213px;
	}
	.routing_list .standart_item .standart_item_description{
		margin: 10px 0 0 0;
	}
	/*start root_baner*/

	.main_wraper.wide{
		width: 100%;
	}
	.models_banner{
		height: 460px;
		display: block;
		overflow: hidden;
		position: relative;
	}
	.models_banner_inner{
	}

	.models_banner_inner .models_image_wrapper{
		height: 558px;
		left: 50%;
		margin-left: -782px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 1600px;
		z-index: 100;
	}
	.models_banner_inner .models_image_item{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		background-position: center;
	}
	.models_banner .root_models_menu{
		top: 0;
		height: 350px;
		left: 50%;
		margin-left: -495px;
		position: absolute;
		width: 990px;
		/*z-index: 10000;*/
	}
	.models_banner .root_models_menu .root_models_menu_item{
		cursor: pointer;
		height: 220px;
		position: absolute;
		top: 0;
		width: 200px;
		z-index:10001;
		left: 10000px;

	}
	.models_banner .models_image_item.jeep_wrangler_unlimited{
		background-image: url('/files/resources/banners/winter.jpg');
	}
	.models_banner .models_image_item.jeep_wrangler{
		background-image: url('/files/resources/banners/winter.jpg');
	}
	.models_banner .models_image_item.jeep_compass_2014{
		background-image: url('/files/resources/banners/winter.jpg');
	}
	.models_banner .models_image_item.jeep_grand_cherokee{
		background-image: url('/files/resources/banners/winter.jpg');
	}
	.models_banner .models_image_item.cherokee-2014{
		background-image: url('/files/resources/banners/winter.jpg');
	}

	.info .price {
		font-family: 'DinProBold';
		font-size: 22px;
		position: absolute;
		top: 156px;
		text-shadow: 1px 1px 1px #000;
	}
	.info .price p {
		background-color: rgba(0, 0, 0, 0.7);
		text-align: center;
		padding: 0px 10px;
		position: absolute;
		width: 200px;
		left: 500px;
		top: 0;
		color:rgb(0,0,0);
	}
	.models_image_wrapper .info {
		top: 105px;
		height: 290px;
		left: 50%;
		margin-left: -455px;
		position: absolute;
		width: 910px;
		z-index: 1100;
	}
	.models_banner .root_models_menu .root_models_menu_item.jeep_wrangler_unlimited{
		left: 400px;
		top: 120px;
		width: 220px;
	}
	.models_banner .root_models_menu .root_models_menu_item.jeep_wrangler_unlimited .root_models_submenu {
		height: 53px;
		top: 138px;
	}
	.models_banner .root_models_menu .root_models_menu_item.jeep_wrangler{
		left: 620px;
		top: 120px;
		width: 190px;

	}
	.models_banner .root_models_menu .root_models_menu_item.jeep_compass_2014{
		left: 68px;
		top: 120px;
		width: 225px;

	}
	.models_banner .root_models_menu .root_models_menu_item.jeep_grand_cherokee{
		left: 343px;
		top: 120px;
		width: 220px;

	}
	.models_banner .root_models_menu .root_models_menu_item.cherokee-2014{
		left: 725px;
		top: 120px;
		width: 220px;

	}


	.models_banner .root_models_menu .root_models_menu_item .root_model_menu_link{
		width: 100%;
		height: 100%;
		display: block;
	}
	.models_banner .root_models_menu_item.active .root_models_submenu{
		opacity: 1;
	}
	.models_banner .root_models_menu_item .root_models_submenu{
		background: url('/files/resources/style/bg_reel_home_info_6.png') center -11px repeat-x transparent;
		opacity: 0;
		transition: opacity 0.2s linear;
		height: 132px;
		//padding-top: 11px;
		position: absolute;
		top: 59px;
		width: 100%;
		min-width: 220px;
		z-index:10001;

	}

	.models_banner .root_models_menu_item .root_models_submenu span {
		color: #FFF;
		display: block;
		font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
		font-size: 14px;
		height: 28px;
		line-height: 26px;
		padding-left: 11px;
		text-transform:uppercase;
		font-weight:bold;
	}

	.models_banner .root_models_menu_item .root_models_submenu a{
		display: block;
		font-family: 'NovecentoDemiBold', Arial, Helvetica, sans-serif;
		font-size: 14px;
		height: 26px;
		line-height: 24px;
		padding-left: 11px;
		color: #ffba02;
		text-decoration: none;
		text-transform: uppercase;
	}
	.models_banner .root_models_menu_item .root_models_submenu a:hover{
		background: url('/files/resources/style/bg_reel_home_info_item.png') top left no-repeat transparent;
		color: #242424;
	}
	.models_banner{
		position: relative;
	}
	.models_banner:before{
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		z-index: 1000;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-8 */
	}

	.cars_card .bxslider-container{
		height: 106px;
		overflow: hidden;
	}

	.content_page .action_models .action_model_button{
		margin: 0 0 10px 0;
	}

	.actions_contact .standart_item{
		width: 350px;
		float: left;
		padding: 0 0 0 33px;
	}
	.actions_contact .standart_item:before {
		content: "";
		display: block;
		width: 27px;
		height: 30px;
		position: absolute;
		background: url('/files/resources/style/sprite.png');
		top: 35px;
		left: 0;
		background-position: -248px -315px;
	}
	.actions_contact .link_exit_point{
		margin-top: 11px;
		display: block;
	}
	.additional_model_price {
		font-family: "PT Sans", sans-serif;
		color: #ffba02;
		font-size: 14px;
		position: absolute;
		top: 124px;
		left: 22px;
		text-transform: uppercase;
		width: 270px;
		line-height: 14px;
	}
	.additional_model_price span {
		display:block;
		color: #dddddd;
		font-size: 10px;
		text-transform: none;
		margin-top: 5px;
	}
	.wrap_price {
		background-color: #2C2C2C;
		padding: 10px 0;
		height:125px;
	}
	.wrap_price div {
		display: block;
		text-align: center;
		margin: 5px;
		line-height: normal;
		font-size: 25px;
		color:#FFB100;
		text-transform:uppercase;
	}
	.wrap_price div.price {
		display: block;
		text-align: center;
		margin: 5px;
		line-height: normal;
		font-size: 25px;
		color:#ffffff;
		text-transform:uppercase;
	}
	.wrap_price span{
		display:block;
		color: #dddddd;
		font-size: 10px;
		text-transform: none;
		margin-top: 5px;
		text-align: center;
	}
	.button_orange.configurator_button {
		position:absolute;
		bottom:0;
		width:225px;
	}
	.more_about_box {
		margin-top: 20px;
	}
	.i_agree_personal_data,
	.more_about_security
	{
		color: #007bbc;
		text-decoration: underline;
		cursor: pointer;
	}
	.major_form .input-layout *[type="checkbox"] {
		display: none;
	}
	.major_form .input-layout *[type="checkbox"] + label {
		padding-left: 28px;
		padding-top: 1px;
		font-size: 14px;
		pointer-events: auto;
		cursor: default;
		color: #333;
	}
	@media (max-width: 768px) {
		.major_form .input-layout *[type="checkbox"] + label {
			color: #fff;
		}
	}
	.major_form .input-layout *[type="checkbox"] + label:before {
		display: inline-block;
		content: '';
		width: 16px;
		height: 16px;
		background-color: #fff;
		border: 1px solid #848484;
		position: absolute;
		left: 0;
		top: 3px;
	}
	.major_form .input-layout *[type="checkbox"]:checked + label:before {
		background: #3cc0f3 url('/files/resources/images/style/cheked_white.png') no-repeat center;
		border-color: #3cc0f3;
	}
	.request_form_container {
		max-width: 100% !important;
	}

	.reel_2017 .owl-item, .reel_2017 .owl-wrapper, .reel_2017 .owl-wrapper-outer, .video-wrapper .owl-wrapper-outer .owl-wrapper .owl-item {
		width: 100% !important;
	}

	.reel_2017 .reel_box_container {
		margin-left: 30px;
	}

	.video-wrapper .owl-wrapper-outer .owl-wrapper {
		width: 90% !important;
		margin: 0 auto;
	}

	.servizi-navigation{
		width: 100%;
		left: 0;
		z-index: 20;
		height: 45px;
		background: #212121;
		top: 80px;
	}

	.content_page table tr:first-child{
		font-weight: bold;
	}
	@media only screen and (max-width: 1045px) {
		.content_overlay .content_page .routing_list{
			Grid-template-columns: 1fr 1fr 1fr;
		}
	}

	@media only screen and (max-width: 814px) {
		.content_overlay .content_page .routing_list{
			Grid-template-columns: 1fr 1fr;
		}
	}
	@media only screen and (max-width: 531px) {
		.content_overlay .content_page .routing_list{
			Grid-template-columns: 1fr;
		}
	}
}


@media only screen and (max-width: 1599px) {
	.main-menu-container ul.main-nav li {
		padding:0 4px!important;
	}
}
.main-menu-container ul.main-nav li {
	padding:0 4px!important;
	font-size:11px!important;
}

@import "variables";
@import "layout";

