.servizi-navigation{width:100%;left:0;z-index:20;height:45px;background:#212121;top:80px}
@media(max-width:767px){.servizi-navigation{top:45px;height:45px}
}
.servizi-navigation a{color:#fff !important}
.servizi-navigation.js-fixed{height:45px}
.servizi-navigation ul{list-style:none}
.servizi-navigation ul li{display:inline-block;margin-right:30px;margin-right:0;height:45px}
.servizi-navigation ul li a{cursor:pointer;font-family:"robotoregular",sans-serif;font-size:12px;display:block;text-transform:uppercase;line-height:40px;margin-bottom:-4px;padding:0;line-height:inherit;color:#bdbdbd;font-size:12px;padding:15px 15px 0 15px}
@media(max-width:767px){.servizi-navigation ul li a{font-size:12px}
}
.servizi-navigation ul li a:hover,.servizi-navigation ul li a:focus{background-color:transparent}
.servizi-navigation ul li.active{background-color:#3e3e3e;border-bottom:4px solid #ffba00}
.servizi-navigation ul li.active:after{width:0;height:0;border-style:solid;border-width:0 2px 4px 2px;border-color:transparent transparent #ffba00 transparent;content:" ";position:absolute;bottom:0;left:47%}
.servizi-navigation ul li.active a{font-family:"proxima_nova_rgbold","robotobold",sans-serif;font-size:12px;font-weight:bold}
@media(max-width:767px){.servizi-navigation ul li.active a{font-size:12px}
}
.servizi-navigation ul li:hover{background-color:#3e3e3e;border-bottom:4px solid #ffba00}
.servizi-navigation ul li:hover:after{width:0;height:0;border-style:solid;border-width:0 2px 4px 2px;border-color:transparent transparent #ffba00 transparent;content:" ";position:absolute;bottom:0;left:47%}
.servizi-navigation .breadcrumb{
	padding:0;margin:0;background-color:transparent;border-radius:0;
	.hidden-xs{

	}
}
.servizi-navigation .breadcrumb .ico-arrow-back{font-size:20px;position:relative;top:4px;margin-right:5px}
.servizi-navigation .breadcrumb a span{text-transform:uppercase;font-family:"robotoregular",sans-serif;font-size:12px;line-height:40px;padding-right: 15px;}
@media(max-width:767px){.servizi-navigation .breadcrumb a span{font-size:12px}
}
.servizi-navigation .breadcrumb a span.second-level{font-family:"proxima_nova_rgbold","robotobold",sans-serif;font-size:12px}
@media(max-width:767px){.servizi-navigation .breadcrumb a span.second-level{font-size:12px}
}
@media(max-width:767px){.servizi-navigation .breadcrumb a span{line-height:30px}
}
.servizi-navigation .favorites{position:absolute;top:0;right:0}
.servizi-navigation .favorites i{font-size:20px;margin-right:5px;position:relative;top:4px}
@media(max-width:1023px){.servizi-navigation .favorites{right:15px;line-height:40px}
}
@media(max-width:767px){.servizi-navigation .favorites{right:10px;line-height:27px}
}
.servizi-navigation .favorites a{text-transform:uppercase;font-family:"robotoregular",sans-serif;font-size:12px;line-height:40px;line-height:45px;color:#bdbdbd}
@media(max-width:767px){.servizi-navigation .favorites a{font-size:12px}
}
@media(max-width:767px){.servizi-navigation .favorites a{line-height:30px}
}
.servizi-navigation .shoppingButton{position:absolute;top:0;right:100px}
.servizi-navigation .shoppingButton i{font-size:20px;margin-right:5px;position:relative;top:4px}
@media(max-width:1023px){.servizi-navigation .shoppingButton{right:115px;line-height:40px}
}
@media(max-width:767px){.servizi-navigation .shoppingButton{right:110px;line-height:27px}
}
.servizi-navigation .shoppingButton a{text-transform:uppercase;font-family:"robotoregular",sans-serif;font-size:12px;line-height:40px}
@media(max-width:767px){.servizi-navigation .shoppingButton a{font-size:12px}
}
@media(max-width:767px){.servizi-navigation .shoppingButton a{line-height:30px}
}