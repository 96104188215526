.main-menu-container {
  top: 0;
  left: 0;
  width: 100%;
  transition: all .3s ease;
  background: #fff;
  z-index: 1000;

  &.out{
    transform: translateY(-100%);
  }

  &.fix {
    position: fixed !important;
  }
}

.main-menu-container-space{
  width: 100%;
  height: 80px;
  @media (max-width: 1023px) {
    height: 45px;
  }
}

