@font-face {
  font-family: "Flaticon";
  src: url("/images/admin/admin_icons/dashboard/fontello.eot");
  src: url("/images/admin/admin_icons/dashboard/fontello.eot?#iefix") format("embedded-opentype"),
  url("/images/admin/admin_icons/dashboard/fontello.woff") format("woff"),
  url("/images/admin/admin_icons/dashboard/fontello.ttf") format("truetype"),
  url("/images/admin/admin_icons/dashboard/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/images/admin/admin_icons/dashboard/OpenSans.eot');
  src: url('/images/admin/admin_icons/dashboard/OpenSans.eot?#iefix') format('embedded-opentype'),
  url('/images/admin/admin_icons/dashboard/OpenSans.woff2') format('woff2'),
  url('/images/admin/admin_icons/dashboard/OpenSans.woff') format('woff'),
  url('/images/admin/admin_icons/dashboard/OpenSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/images/admin/admin_icons/dashboard/OpenSans-Light.eot');
  src: url('/images/admin/admin_icons/dashboard/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Light.woff2') format('woff2'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Light.woff') format('woff'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/images/admin/admin_icons/dashboard/OpenSans-Bold.eot');
  src: url('/images/admin/admin_icons/dashboard/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Bold.woff2') format('woff2'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Bold.woff') format('woff'),
  url('/images/admin/admin_icons/dashboard/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/images/admin/admin_icons/dashboard/fontello.svg#fontello") format("svg");
  }
}

.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-lightbulb:before { content: '\e808'; } /* '' */
.icon-logout:before { content: '\e800'; } /* '' */
.icon-globe:before { content: '\e801'; } /* '' */
.icon-eye:before { content: '\e802'; } /* '' */
.icon-eye-off:before { content: '\e803'; } /* '' */
.icon-pencil:before { content: '\e804'; } /* '' */
.icon-pencil-1:before { content: '\e805'; } /* '' */
.icon-plus:before { content: '\e806'; } /* '' */
.icon-plus-1:before { content: '\e807'; } /* '' */
.icon-logout:before { content: '\e800'; } /* '' */
.icon-globe:before { content: '\e801'; } /* '' */
.icon-play:before { content: '\e809'; } /* '' */
.icon-stop:before { content: '\e80a'; } /* '' */
.flaticon-code:before { content: "\f100"; }
.flaticon-chain:before { content: "\f101"; }
.flaticon-computer:before { content: "\f102"; }
.flaticon-picture:before { content: "\f103"; }
.flaticon-halloween-spider:before { content: "\f104"; }
.flaticon-man-user:before { content: "\f105"; }
.flaticon-settings-gears:before { content: "\f106"; }
.flaticon-update-arrows:before { content: "\f107"; }
.flaticon-up-arrow:before { content: "\f108"; }
.flaticon-right-arrow:before { content: "\f109"; }
.flaticon-interface:before { content: "\f10a"; }
.flaticon-close:before { content: "\f10b"; }
.flaticon-close-1:before { content: "\f10c"; }
.flaticon-multimedia:before { content: "\f10d"; }
.flaticon-information:before { content: "\f10e"; }
.flaticon-check:before { content: "\f10f"; }
.flaticon-attention-exclamation-triangular-signal:before { content: "\f110"; }
.flaticon-empty-set-mathematical-symbol:before { content: "\f111"; }
