.used-list {
	font-family: "robotoregular", sans-serif;
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title {
		font-size: 38px;
		font-weight: 600;
		text-align: center;
		margin-top: 63px;
		margin-bottom: 45px;
		@media (max-width: 767px) {
			text-align: left;
			font-size: 35px;
			margin-top: 45px;
			margin-bottom: 42px;
		}
	}
	.modifications-title {
		font-size: 22px;
		padding-bottom: 8px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 36px;
	}
	.any-modifications {
		flex-flow: wrap;
		display: flex;
		margin: 0 -15px 28px -15px;
		@media (max-width: 1023px) {
			margin-bottom: 0;
		}
		@media (max-width: 767px) {
			display: none;
		}
		.column {
			flex-basis: 20%;
			margin-bottom: 15px;
			padding-left: 15px;
			padding-right: 15px;
			@media (max-width: 1439px) {
				flex-basis: 25%;
			}
			@media (max-width: 1023px) {
				flex-basis: 33.3333%;
			}
			.button {
				margin-bottom: 0;
				margin-right: 0;
				display: block;
				text-align: center;
				min-width: 165px;
				padding: 4px 0;
				&.-white {
					background-color: #fff;
					border: 1px solid #000;
					color: #000;
					margin-bottom: 15px;
					@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
					&:hover {
						@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
						background-color: #FEB733;
						border-color: #FEB733;
					}
				}
				&.active {
					background-color: #FEB733;
					border-color: #FEB733;
				}
			}
		}
	}
	.complectation-wrapper {
		display: none;
		margin: 25px 0;
		@media (max-width: 767px) {
			display: block;
		}
		.complectation-open {
			border: 1px solid #000;
			font-size: 17px;
			font-weight: 600;
			padding: 7px 13px 7px;
			position: relative;
			&::after {
				content: "\f108";
				font-family: "Flaticon";
				font-size: 1rem;
				position: absolute;
				top: 55%;
				@include transformCss(translateY(-50%) rotate(180deg));
				right: 15px;
			}
		}
		.list-wrapper {
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.32), 0 0 0 1px rgba(0, 0, 0, 0.08);
			display: none;
			.list {
				.ul {
					list-style-type: none;
					padding: 0;
					.item {
						text-align: center;
						font-weight: 600;
						font-size: 17px;
						padding: 10px 0;
						@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
						&:hover {
							@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
							background-color: #EDAD01;
						}
						.link {
							color: #000;
						}
					}
					.list-active {
						background-color: #FEB733;
						color: #fff;
						.link {
							color: #fff;
						}
					}
				}
			}
		}
		.tern::after {
			@include transformCss(translateY(-50%) rotate(0deg));
		}
		.open {
			display: block !important;
		}
	}
	.card {
		height: 237px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		cursor: pointer;
		position: relative;
		@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
		&:hover {
			@include transitionCss(background-color .2s cubic-bezier(.215, .61, .355, 1));
			background-color: #F6F6F6;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}
		@media (max-width: 1023px) {
			height: 270px;
			border: 1px solid #fff;
			flex-direction: column;
			justify-content: center;
			align-items: baseline;
		}
		@media (max-width: 767px) {
			display: block;
			height: 100%;
			margin-bottom: 57px;
			&:hover {
				background-color: #fff;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
			}
		}
		.full-link {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.photo {
			width: 356px;
			height: 100%;
			margin-right: 30px;
			@media (max-width: 1023px) {
				width: 397px;
				margin-right: -5px;
			}
			@media (max-width: 767px) {
				width: 100%;
				height: 222px;
				margin-bottom: 24px;
			}
			.link {
				width: 100%;
				height: 100%;
				display: block;
				.image {
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					@media (max-width: 1023px) {
						background-size: cover
					}
				}
			}
		}
		.information {
			@media (max-width: 1023px) {
				max-width: 250px;
			}
			.header {
				font-size: 24px;
				font-weight: 600;
				margin-bottom: 13px;
				@media (max-width: 1023px) {
					margin-bottom: 18px;
				}
				@media (max-width: 767px) {
					margin-bottom: 16px;
				}
				.item {
					color: #000;
				}
			}
			.description {
				font-size: 16px;
				@media (max-width: 1023px) {
					margin-bottom: 28px;
					max-width: 295px;
				}
			}
		}
		.price {
			font-size: 30px;
			margin-left: auto;
			margin-right: 60px;
			@media (max-width: 1023px) {
				margin-left: 0;
			}
			.total {
				font-weight: 600;
				.roubl {
					font-size: 33px;
				}
			}
			.old-price {
				font-size: 30px;
				color: #868686;
				position: relative;
				width: fit-content;
				&::after {
					content: "";
					width: 100%;
					height: 1px;
					background-color: #868686;
					position: absolute;
					left: 0;
					top: 50%;
					@include transformCss(translate(0, -50%));
				}
			}
		}
	}
	.list {
		margin-bottom: 62px;
		@media (max-width: 767px) {
			margin-bottom: 56px;
		}
	}
	.paginator {
		margin-bottom: 0;
		@media (max-width: 1023px) {
			text-align: left;
			justify-content: left;
		}
	}
	.expert-block {
		margin: 107px 0 107px;
		display: block;
		@include transitionCss(.2s);
		@media (max-width: 1023px) {
			margin-bottom: 118px;
		}
		@media (max-width: 767px) {
			margin-bottom: 69px;
		}
		.left {
			width: 335px;
			height: 170px;
			float: left;
			background: rgba(241, 241, 241, .05);
			@media (max-width: 1260px) {
				width: 275px;
			}
			@media (max-width: 1023px) {
				width: 100%;
				float: none;
				height: auto;
			}
			.img {
				width: 236px;
				display: block;
				margin: -61px auto -12px;
			}
			.logo {
				width: 100%;
				height: 65px;
				background: #ec7616 url(/files/images/me_logo.svg) no-repeat center center;
				background-size: 187px;
			}
		}
		.right {
			float: left;
			height: 170px;
			width: calc(100% - 335px);
			background: #f1f1f1;
			border-left: 1px solid #fff;
			color: #000;
			position: relative;
			@media (max-width: 1260px) {
				width: calc(100% - 275px);
			}
			@media (max-width: 1023px) {
				margin-bottom: 0;
				width: 100%;
				float: none;
				height: auto;
			}
			.absolute {
				position: absolute;
				top: 50%;
				padding: 0 50px;
				@include transformCss(translate(0, -50%));
				@media (max-width: 1260px) {
					padding: 0 25px;
				}
				@media (max-width: 1023px) {
					padding: 20px 25px;
					position: static;
					@include transformCss(translate(0, 0));
				}
				.title {
					font-size: 36px;
					text-transform: uppercase;
					margin-bottom: 5px;
					line-height: 40px;
					@media (max-width: 1260px) {
						font-size: 28px;
						line-height: 32px;
					}
					@media (max-width: 767px) {
						font-size: 23px;
						margin-bottom: 10px;
					}
				}
				.description {
					font-size: 22px;
					line-height: 24px;
					font-weight: 300;
					@media (max-width: 1260px) {
						font-size: 18px;
						line-height: 22px;
					}
					@media (max-width: 767px) {
						font-size: 18px;
						line-height: 1.4;
					}
				}
			}
		}
	}
}