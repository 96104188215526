.forma-standart {
	.main-title {
		text-align: center;
		font-size: 35px;
		line-height: 1.3;
		font-family: "robotoregular", sans-serif;
		font-weight: 600;
		margin: 40px 0;
		@media (max-width: 767px) {
			font-size: 30px;
			margin: 35px 0;
			text-align: left;
		}
	}
	.form_def {
		.form_field_item.type_select_test_drive.cf, .form_field_item.type_select.cf {
			.form_label_layout {
				font-size: 12px;
				top: 48%;
			}
		}
		.ik_select {
			.ik_select_link {
				background: none !important;
				font-size: 16px;
				display: flex;
				align-items: center;
				height: 50px;
				padding: 25px 10px 2px 0;
				border: none;
				border-bottom: 1px solid #BEBEBE;
				border-radius: 0;
				z-index: 9;
				&::before {
					content: "\f108";
					font-family: "Flaticon";
					display: block;
					font-size: 17px;
					color: #000;
					position: absolute;
					right: 15px;
					top: 27px;
					@include transitionCss(.2s);
					@include transformCss(rotate(180deg));
				}
				&.ik_select_link_focus {
					box-shadow: none;
				}
			}
		}
		.group_name {
			margin-bottom: 15px;
			margin-top: 25px;
			font-size: 20px;
		}
		.form_fields_group_wrapper {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			.type_textarea {
				.form_label_layout {
					top: 30px !important;
				}
				textarea {
					width: 100%;
					height: 92px;
					box-sizing: border-box;
					border-bottom: 1px solid #AFAFAF;
					border-radius: 0;
					box-shadow: none;
					font-size: 16px;
					padding: 10px;
					margin-top: 30px;
				}
			}
			.type_date, .type_time {
				.form_label_layout {
					font-size: 12px !important;
					top: 48% !important;
				}
			}
			.type_checkbox {
				margin-top: 30px;
			}
			.form_field_item {
				position: relative;
				.form_label_layout {
					color: #BEBEBE;
					font-size: 14px;
					position: absolute;
					top: 100%;
					left: 0;
					padding: 0 10px 0 0;
					width: 100%;
					z-index: 1;
					@include transformCss(translateY(-100%));
					@include transitionCss(all .15s ease-out);
					&.td-form__input_not-empty {
						@include transitionCss(all .15s ease-out);
						top: 50%;
						font-size: 12px;
					}
				}
			}
			input {
				width: 100%;
				border: none;
				font-size: 16px;
				border-bottom: 1px solid #BEBEBE;
				padding: 23px 10px 2px 0;
				&.formjor-err {
					border-color: red;
				}
			}
			.type_checkbox {
				.form_field_layout {
					//display: flex;
					.flex {
						align-items: center;
					}
					.def_inp {
						font-size: 1px;
						height: 0;
						width: auto;
						position: relative;
						&.formjor-err {
							&::before {
								border-color: #ff1a4c;
							}
						}
						&:checked {
							&::after {
								@include transformCss(scale(1));
								@include transitionCss(transform .15s ease);
							}
							&::before {
								border: 1px solid #FEB733;
								@include transitionCss(border-color .15s ease);
							}
						}
						&::after {
							content: '';
							display: block;
							position: absolute;
							left: 3px;
							top: -7px;
							font-size: 23px;
							width: 10px;
							border-radius: 100%;
							height: 10px;
							background-color: #FEB733;
							@include transformCss(scale(0));
							@include transitionCss(border-color .15s ease);
							@media (max-width: 767px) {
								top: -29px;
							}
						}
						&::before {
							content: '';
							display: block;
							position: absolute;
							left: 0;
							top: -10px;
							font-size: 23px;
							width: 16px;
							border-radius: 100%;
							height: 16px;
							border: 1px solid #828282;
							@include transitionCss(border-color .15s ease);
							@media (max-width: 767px) {
								top: -32px;
							}
						}
					}
					.form_label {
						margin-bottom: 0;
						font-size: 16px;
						font-weight: normal;
						margin-left: 25px;
						@media (max-width: 767px) {
							margin-left: 20px;
						}
						.i_agree_personal_data {
							color: #FEB733;
							text-decoration: underline;
							cursor: pointer;
						}
					}
					.error_notice {
						margin-bottom: 0;
						font-size: 16px;
						font-weight: normal;
						margin-left: 39px;
						@media (max-width: 767px) {
							margin-left: 33px;
						}
					}
				}
			}
		}
		.more_about_box {
			font-size: 13px;
			line-height: 1.6;
			margin-top: 20px;
			@media (max-width: 767px) {
				margin-top: 50px;
			}
			> p {
				font-size: 13px;
			}
			.more_about_security {
				color: #FEB733;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.submit_button_wrapper {
			margin-top: 30px;
			margin-bottom: 74px;
			@media (max-width: 767px) {
				margin-top: 62px;
				margin-bottom: 56px;
			}
			button {
				font-size: 14px;
				font-weight: 600;
				background-color: #FEB733;
				text-transform: uppercase;
				width: 100%;
				text-align: center;
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				outline: none;
				&::after {
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #000;
					padding-left: 6px;
					font-size: 13px;
					position: relative;
				}
				&:hover {
					@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
					background-color: #EDAD01;
				}
			}
		}
	}
	.success_wrapper {
		.success_subheader {
			margin-bottom: 15px;
		}
		.style_p {
			font-size: 16px;
			margin-bottom: 15px;
		}
		.btn {
			margin-top: 15px;
			margin-bottom: 15px;
			font-size: 14px;
			font-weight: 600;
			background-color: #FEB733;
			text-transform: uppercase;
			width: 100%;
			text-align: center;
			@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
			outline: none;
			&::after {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #000;
				padding-left: 6px;
				font-size: 13px;
				position: relative;
			}
			&:hover {
				@include transitionCss(background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				background-color: #EDAD01;
			}
		}
	}
}

