.show-rooms_container {
  margin: 0 10px;
  margin-bottom: 100px;
  margin-top: 40px;
}
.show-rooms_block {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
  flex-wrap: wrap;
}
.show-rooms_block-link {
  display: block;
  width: 20%;
}
.show-rooms_block a:hover{
	text-decoration: none;
}
.show-rooms_item {
    text-align: center;
    position: relative;
}
.show-rooms_item-part {
    display: block;
    height: 150px;
    border-left: 1px solid #e3e3e3;
    margin: 0 auto;
}
.show-rooms_icon {
	opacity: 1;
	height: 50px;
  margin: auto 0;
  line-height: 70px;
}
.show-rooms_icon:hover {
	opacity: 0.5;
}
.show-rooms_title {
    color: #000;
    font-size: 22px;
    margin: 15px 0;
    min-height: 44px;
    height: 100%;
}
.show-rooms_description {
    color: #ffba00;
    font-size: 16px;
}

@media (max-width: 1292px) {
.show-rooms_title {
    min-height: 66px;
    height: 100%;
  }
}

@media (max-width: 768px) {
.show-rooms_container {
    margin-bottom: 50px;
  }
.show-rooms_title {
    font-size: 18px;
    margin: 0px;
  }
.show-rooms_description {
    font-size: 14px;
}
.show-rooms_icon img{
    height: 80%;
}
}

@media (max-width: 650px) {
.show-rooms_container {
    margin-bottom: 40px;
    margin-top: 10px;
  }
.show-rooms_block {
    flex-wrap: wrap;
  }
.show-rooms_item-part {
    display: none;
}
.show-rooms_block-link {
  width: 40%;
}
.show-rooms_icon {
  margin-top: 10px;
}
}
