.faq, .faq-categories {
	background-color: #fff !important;
	font-family: "robotoregular", sans-serif;
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title{
		font-size: 35px;
		font-weight: 600;
		text-align: center;
		margin: 40px 0;
		line-height: 1.3;
		@media (max-width: 767px) {
			margin: 35px 0;
			font-size: 30px;
			text-align: left;

		}
	}
	.faq_search{
		margin-bottom: 70px;
		@media (max-width: 767px){
			margin-bottom: 45px;
		}
		.title-sub{
			font-size: 22px;
			padding-bottom: 11px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 44px;
			@media (max-width: 1023px){
				margin-bottom: 26px;
			}
		}
	}
	.input-wrapper{
		display: flex;
		align-items: center;
		@media (max-width: 767px){
			display: block;
		}
		input{
			min-height: 33px;
			min-width: 333px;
			padding: 0 13px;
			margin-right: 52px;
			@media (max-width: 1023px){
				min-width: 414px;
				margin-right: 32px;
				flex-basis: 70%;
			}
			@media (max-width: 767px){
				min-width: 100%;
				margin-right: 0;
				margin-bottom: 16px;
			}
		}
		.ik_select{
			top: 0;
			min-width: 356px;
			margin-right: 46px;
			@media (max-width: 1023px){
				min-width: 252px;
				flex-basis: 40%;
				margin-right: 0;
			}
			@media (max-width: 767px){
				min-width: 100%;
			}
			.ik_select_link{
				background: none !important;
				font-size: 16px;
				align-items: center;
				height: 33px;
				padding: 7px 13px 5px 13px;
				border-bottom: 1px solid #BEBEBE;
				border-radius: 0;
				z-index: 9;
				position: relative;
				&::before{
					content: "\f108";
					font-family: "Flaticon";
					display: block;
					font-size: 17px;
					color: #000;
					position: absolute;
					right: 15px;
					top: 6px;
					transition: .2s;
					transform: rotate(180deg);
				}
				&.ik_select_link_focus{
					box-shadow: none;
				}
				.ik_select_link_text{
					font-weight: normal;
				}
			}
		}
		.btn{
			border-radius: 2px;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			display: block;
			padding: 6px 0;
			min-width: 162px;
			min-height: 34px;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
			&.-lg-visible{
				@media (max-width: 1023px){
					display: none;
				}
			}

			&:hover {
				@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
				background-color: #edad01;
			}
			i{
				margin-left: 10px;
			}
		}
	}
	.wrapper-btn{
		@media (max-width: 1023px){
			display: flex;
			align-items: baseline;
		}
		@media (max-width: 767px){
			display: block;
		}
		.ask_question_btn{
			@media (max-width: 1023px){
				min-width: 414px;
				margin-right: 32px;
				flex-basis: 70%;
			}
			@media (max-width: 767px){
				min-width: 100%;
				margin-right: 0;
				margin-bottom: 21px;
				margin-top: 22px;
			}
		}
		.faq_search_button{
			border-radius: 2px;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			display: none;
			padding: 6px 0;
			min-width: 162px;
			min-height: 34px;
			width: fit-content;
			text-align: center;
			color: #000;
			background-color: #ffba00;
			@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
			@media (max-width: 1023px){
				min-width: 252px;
				flex-basis: 40%;
				display: block;
			}
			@media (max-width: 767px){
				min-width: 100%;
			}
			&:hover {
				@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
				background-color: #edad01;
			}
			i{
				margin-left: 10px;
			}
		}
	}
	.ask_question_btn{
		margin-top: 36px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		display: inline-block;
		padding: 6px 0;
		min-width: 333px;
		min-height: 34px;
		width: fit-content;
		text-align: center;
		color: #000;
		background-color: #ffba00;
		@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
		&:hover {
			@include transitionCss(all .2s cubic-bezier(.215, .61, .355, 1));
			background-color: #edad01;
		}
		i{
			margin-left: 10px;
		}
	}
	.questions-list {
		.card {
			margin-bottom: 120px;
			@media (max-width: 767px){
				margin-bottom: 80px;
			}
			> .header {
				font-size: 22px;
				margin-bottom: 13px;
				> .item {
					color: #000;
				}
			}
			.time {
				font-size: 12px;
				font-weight: 100;
				border-top: 2px solid #FEB733;
				width: fit-content;

			}
			.question {
				margin-top: 34px;
				margin-bottom: 50px;
				@media (max-width: 767px){
					margin-bottom: 44px;
					margin-top: 24px;
				}
				.description {
					font-size: 16px;
					@media (max-width: 767px){
						font-size: 12px;
					}
				}
			}
			.answer {
				background-color: #F9F9F9;
				position: relative;
				&::before{
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 30px 30px 30px;
					border-color: transparent transparent #F9F9F9 transparent;
					position: absolute;
					top: -30px;
					left: 30px;
				}
				.description {
					font-size: 16px;
					padding: 45px 55px 55px 55px;
					@media (max-width: 767px){
						font-size: 12px;
						padding: 25px 29px 30px 29px;
					}
				}
			}
		}
	}
	.another_questions_menu{
		margin-bottom: 37px;
		@media (max-width: 767px){
			margin-bottom: 15px;
		}
		.container-wrapper{
			@media (max-width: 1023px){
				width: 60%;
			}
		}
		.title{
			font-size: 22px;
			padding-bottom: 11px;
			border-bottom: 1px solid #ccc;
			margin-bottom: 50px;
			@media (max-width: 767px){
				margin-bottom: 40px;
			}
		}
		.list_menu{
			margin-bottom: 29px;
			.item{
				.link{
					font-size: 16px;
					color: #000;
					display: flex;
					align-items: center;
					white-space: nowrap;
					.tick{
						height: 20px;
						width: 20px;
						margin-right: 10px;
					}
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}
	.faq-bottom-wrapper{
		margin-bottom: 83px;
		@media (max-width: 1023px){
			margin-bottom: 77px;
		}
		@media (max-width: 767px){
			margin-bottom: 88px;
		}
		.title{
			font-size: 25px;
		}
		.faq_bottom_button{
			margin-top: 11px;
			@media (max-width: 767px){
				width: 100%;
				min-width: 100%;
			}
			i{
				margin-left: 10px;
			}
		}
	}
	.paginator-wrapper{
		.paginator{
			.link{
				background-color: #ECECEC;
				color: #000;
				font-size: 20px;
				font-weight: 600;
				margin-right: 12px;
				padding: 5px 13.4px;
				height: 38px;
				width: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include transitionCss(all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
				&:hover{
					@include transitionCss(all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1));
					background-color: #EDAD01;
				}
				@media (max-width: 767px){
					display: none;
					&.arrow{
						display: flex;
						width: 157px;
					}
				}
				&.active{
					background-color: #FECF77;
					color: #fff;
				}
				&.to_right{
					&::after{
						top: 45%;
					}
					@media (max-width: 767px){
						margin-right: 0;
					}
				}
				&.to_left{
					@media (max-width: 767px){
						&::before{
							content: "\f109";
							top: 45%;
						}
					}
					&::before{
						top: 45%;
					}
				}
			}
		}
	}
	.paginator{
		margin-bottom: 100px;
		margin-top: -55px;
		justify-content: left;
		@media (max-width: 1023px){
			margin-top: -70px;
		}
		@media (max-width: 767px){
			margin-bottom: 80px;
			margin-top: -45px;
		}
		.link{
			width: 157px;
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			&.prev_link{
				&::before{
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #FECF77;
					padding-left: 5px;
					font-size: 16px;
					position: relative;
					display: block;
					top: 30%;
					left: 1px;
					@include transformCss(scaleX(-1) translateY(-50%));
				}
			}
			&.next_link{
				@media (max-width: 767px){
					margin-right: 0;
				}
				&::after{
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #FECF77;
					padding-left: 5px;
					font-size: 16px;
					position: relative;
					top: 30%;
					left: -1px;
					@include transformCss(translateY(-50%));
				}
			}
		}
	}
	.no_search_result_text{
		margin-bottom: 60px;
		@media (max-width: 767px){
			margin-bottom: 45px;
		}
		h4{
			line-height: 1.4;
		}
		.text-link{
			margin-top: 0;
			border-radius: 0;
			min-width: auto;
			min-height: auto;
			display: inline-block;
			background-color: #fff;
			text-transform: none;
			font-size: 18px;
			font-weight: normal;
			color: #ffba00;
			padding: 0;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

