.ik_select{font:13px/19px "Lucida Grande","Lucida Sans","Lucida Sans Unicode",Lucida,sans-serif}
.ik_select_link {position:relative;height:19px;padding:0 22px 0 7px;border:1px solid #9c9c9c;-webkit-border-radius:4px;border-radius:4px;background:#fff;background:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyNSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI0OSUiIHN0b3AtY29sb3I9IiNmNWY1ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNlY2VjZWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3NSUiIHN0b3AtY29sb3I9IiNlY2VjZWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==');background:-webkit-linear-gradient(#fff 25%, #f5f5f5 49%, #ececec 50%, #ececec 75%, #eee 100%);background:-moz-linear-gradient(#fff 25%, #f5f5f5 49%, #ececec 50%, #ececec 75%, #eee 100%);background:-o-linear-gradient(#fff 25%, #f5f5f5 49%, #ececec 50%, #ececec 75%, #eee 100%);background:-ms-linear-gradient(#fff 25%, #f5f5f5 49%, #ececec 50%, #ececec 75%, #eee 100%);background:linear-gradient(#fff 25%, #f5f5f5 49%, #ececec 50%, #ececec 75%, #eee 100%);-webkit-box-shadow:0 1px 0 #f0f0f0,inset 0 0 0 1px rgba(255,255,255,0.22);box-shadow:0 1px 0 #f0f0f0,inset 0 0 0 1px rgba(255,255,255,0.22);color:#444;}
.ik_select:after{display:none}
 .ik_select_link_text:after, .ik_select_link_text:before{position:absolute;top:50%;right:4px;font-size:10px;line-height:10px;color:#434343}
 .ik_select_link_text:after{margin-top:-7px}
 .ik_select_link_text:before{margin-top:-1px}
.ik_select_link_focus{-webkit-box-shadow:0 0 5px #a59641;box-shadow:0 0 5px #a59641}
.ik_select{top:-2px;background:none;}
.ik_select_dropdown .ik_select_list{padding:4px 0;border:none;-webkit-border-radius:5px;border-radius:5px;background:#fff;-webkit-box-shadow:0 10px 15px rgba(0,0,0,0.32),0 0 0 1px rgba(0,0,0,0.08);box-shadow:0 10px 15px rgba(0,0,0,0.32),0 0 0 1px rgba(0,0,0,0.08);color:#444;}
.ik_select_dropdown .ik_select_list li{position:relative;padding:0 21px;}
.ik_select_dropdown .ik_select_list li.ik_select_hover{background:#6288f7;background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyODhmNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyMjYwZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);background:-webkit-linear-gradient(#6288f7, #2260f6);background:-moz-linear-gradient(#6288f7, #2260f6);background:-o-linear-gradient(#6288f7, #2260f6);background:-ms-linear-gradient(#6288f7, #2260f6);background:linear-gradient(#6288f7, #2260f6);-webkit-box-shadow:0 -1px 0 rgba(0,0,0,0.1) inset,0 1px 0 rgba(0,0,0,0.1) inset;box-shadow:0 -1px 0 rgba(0,0,0,0.1) inset,0 1px 0 rgba(0,0,0,0.1) inset;color:#fff}
.ik_select_dropdown .ik_select_list li.ik_select_active span:after{content:"\2713";position:absolute;top:0;left:5px}
.ik_select_list ul {list-style: none;margin: 0;padding: 0;}
.ik_select_link_text {display: block;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis; position: absolute;}