.soft_notice{
	font-family: "Roboto", sans-serif;
	padding: 22px 0;
	background-color: #E7E7E5;
	border: 1px solid #FF0000;
	margin-top: 48px;
	.soft_notice_text{
		font-size: 24px;
		font-weight: 600;
		padding: 0 13px;
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
			font-size: 16px;
		}
		.image{
			width: 48px;
			height: 48px;
			margin-right: 15px;
		}
	}
}