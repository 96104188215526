.action {
	.container {
		@media (max-width: 1023px) {
			width: 730px;
		}
		@media (max-width: 767px) {
			width: 320px;
		}
	}
	.main-title {
		font-size: 35px;
		font-weight: 600;
		margin: 40px 0;
		line-height: 1.3;
		@media (max-width: 767px) {
			font-size: 30px;
			margin: 35px 0;
		}
	}
	.action-information {
		margin-bottom: 47px;
		max-width: 950px;
		* {
			font-size: 15px;
			color: #000;
			margin-bottom: 15px;
		}
		img {
			width: 950px;
			@media (max-width: 1023px) {
				width: 100% !important;
				height: 100% !important;
			}
		}
		table {
			width: 100%;
			tr {
				border: 1px solid #484848;
				font-size: 12px;
				padding: 10px;
				&:first-child {
					font-weight: bold;
					text-align: center;
				}
				th {
					text-align: center;
				}
				td {
					font-size: 12px;
					border: 1px solid #484848;
					padding: 8px 8px 8px 10px;
					text-align: center;
					vertical-align: top;
				}
			}
		}
		.btn {
			background-color: #EDAD01;
		}
	}
	.action-list {
		margin: 0 -15px;
		.list {
			justify-content: inherit;
			.card {
				margin-bottom: 60px;
				@media (max-width: 767px) {
					margin-bottom: 45px;
				}
			}
		}
	}
	.contact-title {
		font-size: 35px;
		font-weight: 600;
		margin-bottom: 35px;
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
	.another-news {
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 150px;
		@media (max-width: 767px) {
			margin-bottom: 120px;
		}
		.point {
			color: #000;
			&::after {
				content: "\f109";
				font-family: "Flaticon";
				font-style: normal;
				font-weight: bold;
				font-variant: normal;
				line-height: 1;
				color: #FEB733;
				padding-left: 4px;
				font-size: 12px;
			}
		}
	}
	.more-actions-title {
		font-size: 34px;
		font-weight: 600;
		margin-bottom: 30px;
	}
	.contact {
		font-family: "robotoregular", sans-serif;
		display: flex;
		margin-bottom: 75px;
		@media (max-width: 767px) {
			display: block;
		}
		.card {
			display: flex;
			&:first-child {
				margin-right: 55px;
			}
			@media (max-width: 767px) {
				&:first-child {
					margin-bottom: 38px;
					margin-right: 0;
				}
			}
			.image {
				margin-right: 19px;
				margin-top: 10px;
				@media (max-width: 767px) {
					margin-right: 7px;
					margin-top: 2px;
				}
				.item {
					@media (max-width: 767px) {
						width: 21px;
						height: 28px;
					}
				}
			}
			.header {
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 5px;
				@media (max-width: 767px) {
					font-size: 19px;
				}
				.link {
					color: #000;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.description {
				font-size: 15px;
			}
			.point {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
				margin-top: 45px;
				color: #000;
				display: block;
				@media (max-width: 767px) {
					margin-top: 15px;
				}
				&::after {
					content: "\f109";
					font-family: "Flaticon";
					font-style: normal;
					font-weight: bold;
					font-variant: normal;
					line-height: 1;
					color: #FEB733;
					padding-left: 4px;
					font-size: 12px;
				}
			}
		}
	}
}
